import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { invSoftware } from 'src/app/model/invSoftware';
import localeEsMX from '@angular/common/locales/es-MX';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

registerLocaleData(localeEsMX, 'es-MX');

@Component({
  selector: 'app-reporte-valor-inventario-sw',
  templateUrl: './reporte-valor-inventario-sw.component.html',
  styleUrls: ['./reporte-valor-inventario-sw.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es-MX" }],
})

export class ReporteValorInventarioSwComponent implements OnInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  private url: string = environment.api;

  public formFechas: FormGroup;

  public invSoftware: invSoftware[] = [];
  public tiposw: any = [];
  public ubicacion: any = [];

  date = new Date().toISOString().slice(0, 10);
  public dateTable: Date = new Date();
  public data: any = [['Tipo', 'Software', 'Costo Unitario', 'Fecha Compra', 'Vencimiento', 'Cantidad', 'Ubicación'],];

  public rolesAzure = Roles;

  constructor(private _pdfService: PdfService, private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formFechas = this.formBuilder.group({
      fini: ['', [Validators.required]],
      ffin: ['', [Validators.required]],
      tiposw: ['', [Validators.required]],
      ubicacion: ['', [Validators.required]],
    });
  }
  ngOnInit() {
    this.muestraTipoSw()
    this.muestraUbicaciones()
    this.mostrarReporte()
  }

  mostrarReporte() {
    this.invSoftware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    let tipo
    if (this.formFechas.value['tiposw'] == '') {
      tipo = 0
    } else {
      tipo = this.formFechas.value['tiposw']
    }
    let ubi
    if (this.formFechas.value['ubicacion'] == '') {
      ubi = 0
    } else {
      ubi = this.formFechas.value['ubicacion']
    }

    let lotefac = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "tipoSw": tipo,
      "idUbicacion": ubi,
      "Page": this.page
    }


    this.inventarioService.getReporteValorInvsw(lotefac).subscribe((respuesta: any) => {

      if (respuesta['result'] == 1) {
        this.page = 1
        this.invSoftware = respuesta.data;
        this.totalRecords = respuesta.totalRecords;
        this.cargarLoader = false;
      } else {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.mostrarReporte();
            } else {
              this.formFechas.get("fini").setValue("");
              this.formFechas.get("ffin").setValue("");
              this.formFechas.get("tiposw").setValue("");
              this.formFechas.get("ubicacion").setValue("");
              this.page = 1;
              this.mostrarReporte();
            }
          })
        }
      }
    })
  }

  muestraTipoSw() {
    this.inventarioService.getTipoSoftware().subscribe((respHardware: any) => {
      this.tiposw = respHardware.data;
    })
  }

  muestraUbicaciones() {
    this.inventarioService.getUbicaciones().subscribe((respHardware: any) => {
      this.ubicacion = respHardware.data;
    })
  }

  exportExcel() {
    let tipo
    if (this.formFechas.value['tiposw'] == '') {
      tipo = 0
    } else {
      tipo = this.formFechas.value['tiposw']
    }
    let ubi
    if (this.formFechas.value['ubicacion'] == '') {
      ubi = 0
    } else {
      ubi = this.formFechas.value['ubicacion']
    }

    let lotefac = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "tipoSw": tipo,
      "idUbicacion": ubi,
      "Page": -1
    }


    this.inventarioService.getReporteValorInvsw(lotefac).subscribe((respuesta: any) => {

      if (respuesta['result'] == 1) {
            /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invSoftware);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, "ReporteValorInventarioSoftware-" + this.dateTable.toLocaleDateString() + ".xlsx");
    /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      } else {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.mostrarReporte();
            } else {
              this.formFechas.get("fini").setValue("");
              this.formFechas.get("ffin").setValue("");
              this.formFechas.get("tiposw").setValue("");
              this.formFechas.get("ubicacion").setValue("");
              this.page = 1;
              this.mostrarReporte();
            }
          })
        }
      }
    })
  }

  async generatePDF() {
    let tipo
    if (this.formFechas.value['tiposw'] == '') {
      tipo = 0
    } else {
      tipo = this.formFechas.value['tiposw']
    }
    let ubi
    if (this.formFechas.value['ubicacion'] == '') {
      ubi = 0
    } else {
      ubi = this.formFechas.value['ubicacion']
    }

    let lotefac = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "tipoSw": tipo,
      "idUbicacion": ubi,
      "Page": -1
    }


    this.inventarioService.getReporteValorInvsw(lotefac).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        const tituloEncabezado = 'Reporte de Valor de Inventario de Software';
        const FileName = 'ValorInventarioSoftware' + this.dateTable.toLocaleDateString();
        const embedHTML = document.getElementById('foo');
        const widths = '*';
        this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
      } else {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.mostrarReporte();
            } else {
              this.formFechas.get("fini").setValue("");
              this.formFechas.get("ffin").setValue("");
              this.formFechas.get("tiposw").setValue("");
              this.formFechas.get("ubicacion").setValue("");
              this.page = 1;
              this.mostrarReporte();
            }
          })
        }
      }
    })
  }


  loadPage(page: number) {
    this.page = page;
    this.mostrarReporte();
  }
}
