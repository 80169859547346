import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';
import { HttpClient                               } from '@angular/common/http';
import   Swal                                       from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { Ubicacion } from '../../../model/ubicacion';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Component({
  selector:     'app-catalogo-ubicaciones',
  templateUrl:  './catalogo-ubicaciones.component.html',
  styleUrls:   ['./catalogo-ubicaciones.component.scss']
})
export class CatalogoUbicacionesComponent implements AfterViewInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  public formUbicaciones : FormGroup;
  public formEditaUbicaciones : FormGroup;
  private url: string = environment.api;
  public ubicacion: Ubicacion[] = [];

  ubicacionEditar: any = {
    idUbicacion: null,
    nombreUbicacion: '',
}

public rolesAzure = Roles;


  @ViewChild('txtBuscaUbicaciones', {static: false}) txtBuscaUbicaciones!:ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService){
    this.formUbicaciones = this.formBuilder.group({
      nombreUbicacion: ['',[Validators.required]],
    })
    
    this.formEditaUbicaciones = this.formBuilder.group({
      idUbicacion: ['',[Validators.required]],
      nombreUbicacion: ['',[Validators.required]],
    })
  }
  ngAfterViewInit(): void {
    this.buscarUbicaciones()
  }

  buscarUbicaciones(){
    this.ubicacion = [];
    this.totalRecords = 0;
    this.cargarLoader = true;


    if (this.txtBuscaUbicaciones.nativeElement.value === '') {
      this.inventarioService.getUbicacionesLimit(this.page).subscribe((respUbicaciones:any) =>{
        if (respUbicaciones['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.buscarUbicaciones();
              } else {
                this.txtBuscaUbicaciones.nativeElement.value = "";
                this.page = 1;
                this.buscarUbicaciones();
              }
            })
          }
        }
         this.ubicacion = respUbicaciones.data;
         this.totalRecords = respUbicaciones.totalRecords;
          this.cargarLoader = false;
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    } else {
      let datoPostUbicacion={
          "nombreTabla": "ubicacion",
          "valorBusqueda": this.txtBuscaUbicaciones.nativeElement.value,
          "Page": this.page
        }
      this.inventarioService.getUbicacionesTablaCampo(datoPostUbicacion).subscribe((respTiposHw:any) =>{
        if (respTiposHw['result'] === 0) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning",
            // timer: 2000
          })
        } else {
          this.ubicacion = respTiposHw.data;
          this.totalRecords = respTiposHw.totalRecords;
          this.cargarLoader = false;
        }
        
      })
    }
  }
 
  abreEditarUbicaciones(idUbicacion){
    this.inventarioService.getUbicacionxId(idUbicacion).subscribe((respUbicaciones:any) =>{
       this.ubicacionEditar = respUbicaciones.data;
    })
  }
  
  editaUbicaciones(){
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el nombre de la ubicación, aunque ya exista conexión de este registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {
       
      }else{
        this.inventarioService.updateUbicacion(this.formEditaUbicaciones.value).subscribe(data => {
          if (data['result'] === 1) {
            this.formUbicaciones.reset();
            this.buscarUbicaciones();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }
        })
      }
    })
        
      

  }

  cambioBuscaUbicacionesInput(){
    let valor = this.txtBuscaUbicaciones.nativeElement.value;
    if (valor === '') {
      this.buscarUbicaciones();
    }
  }

  guardaUbicaciones(){
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se guardará como nuevo registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {
       
      }else{
        this.inventarioService.createUbicacion(this.formUbicaciones.value).subscribe(data => {
          if (data['result'] === 1) {
            this.formUbicaciones.reset();
            this.buscarUbicaciones();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }
        })   
      }
    })
      

  }

  noSpecialChar(char){
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.buscarUbicaciones();
  }

}
