import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { tipoSW } from 'src/app/model/tipoSW';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-catalogo-tipo-sw',
  templateUrl: './catalogo-tipo-sw.component.html',
  styleUrls: ['./catalogo-tipo-sw.component.scss']
})
export class CatalogoTipoSwComponent implements AfterViewInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  public formTipoSw: FormGroup;
  public formEditaTipoSw: FormGroup;
  private url: string = environment.api;
  public tiposSw: tipoSW[] = [];

  tipoSwEditar: any = {
    idTipoSw: null,
    nombre: '',
    tiempoNotificacion: null
  }

  public rolesAzure = Roles;

  @ViewChild('txtBuscaTipoSw', { static: false }) txtBuscaTipoSw!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formTipoSw = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      tiempoNotificacion: ['', [Validators.required]],
    })

    this.formEditaTipoSw = this.formBuilder.group({
      idTipoSw: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      tiempoNotificacion: ['', [Validators.required]],
    })
  }

  ngAfterViewInit(): void {
    this.buscarTipoSw();
  }

  buscarTipoSw() {
    this.tiposSw = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaTipoSw.nativeElement.value === '') {
      this.http.get(`${this.url}TipoSW/limit/${this.page}`).subscribe((respTiposSw: any) => {
        if (respTiposSw['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.buscarTipoSw();
              } else {
                this.txtBuscaTipoSw.nativeElement.value = "";
                this.page = 1;
                this.buscarTipoSw();
              }
            })
          }
        }
        this.tiposSw = respTiposSw.data;
        this.totalRecords = respTiposSw.totalRecords;
        this.cargarLoader = false;
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    } else {
      let datoPostTipoSw = {
        "nombreTabla": "tsw",
        "valorBusqueda": this.txtBuscaTipoSw.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getTipoSoftwareTablaCampo(datoPostTipoSw).subscribe((respTiposSw: any) => {
        if (respTiposSw['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.buscarTipoSw();
              } else {
                this.txtBuscaTipoSw.nativeElement.value = "";
                this.page = 1;
                this.buscarTipoSw();
              }
            })
          }
        } else {
          this.tiposSw = respTiposSw.data;
          this.totalRecords = respTiposSw.totalRecords;
          this.cargarLoader = false;
        }
      })
    }
  }

  guardaTipoSw(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se guardará como nuevo registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.createTipoSoftware(this.formTipoSw.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formTipoSw.reset();
            this.buscarTipoSw();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }
    })

  }


  abreEditarTipoSw(idtipoSw) {
    this.inventarioService.getTipoSoftwarexId(idtipoSw).subscribe((respuesta: any) => {
      this.tipoSwEditar = respuesta.data;
    })
  }

  editaTipoSw() {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el nombre del tipo de software, aunque ya exista conexión de este registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateTipoSoftware(this.formEditaTipoSw.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaTipoSw.reset();
            this.buscarTipoSw();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }
        })
      }
    })

  }

  noSpecialChar(char) {
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.buscarTipoSw();
  }
}
