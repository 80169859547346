<ul class="navbar-nav ml-auto">
  <li *ngIf="isLoggedIn">
    <i class="icon feather icon-user"></i><span class="small ml-2"> {{ name }}</span>
  </li>
  <li *ngIf="isLoggedIn">
    <div class="pro-head" (click)="logout()" style="cursor: pointer;">
      <span class="small mr-2">Cerrar sesión</span>
      <a href="javascript:" class="dud-logout" title="Logout" ><i class="feather icon-log-out"></i></a>
    </div>
  </li>
</ul>
