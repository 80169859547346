import { AfterViewInit, Component, ElementRef, Injectable, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators                              } from '@angular/forms';
import { HttpClient                                                      } from '@angular/common/http';
import   Swal                                                              from 'sweetalert2';
import { UploadService } from 'src/app/data/service/upload.service';
import { environment   } from 'src/environments/environment';
import { invHardware   } from 'src/app/model/invHArdware';
import { PdfService    } from 'src/app/data/service/pdf-excel/pdf.service';
import { Usuario       } from 'src/app/model/usuario';
import { MicrosoftAuthService } from '../../../data/service/auth/microsoft-auth.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Ubicacion } from '../../../model/ubicacion';
import { Roles } from 'src/app/model';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector:     'app-asignacion-hardware',
  templateUrl:  './asignacion-hardware.component.html',
  styleUrls:   ['./asignacion-hardware.component.scss'],
  providers:   [{ provide: LOCALE_ID, useValue: "es" }],
})
export class AsignacionHardwareComponent implements OnInit, AfterViewInit {
  
  public formBaja : FormGroup;
  
  private idResponsable = localStorage.getItem('employeeId');
  private url: string = environment.api;
  public formAsignacionHw : FormGroup;
  public formDocumento: FormGroup;
  public usuarios: Usuario[] = [];
  public invHardware: invHardware[] = [];
  
  public asignaciones: any[] = [];
  public ubicacion: Ubicacion[] = [];
  public hardwareElegido: any[] = []
  public tiposDocumentos: any  = [];
  public documentosAsignacion: any[] = []


  public folioAsignacion;

  public page : number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader:boolean = true;

  public idAsignacionDoc : 0;
  public idAsignacionBaja = 0

  public checks=[];

  public bandDocumentoVacio = false
  public hayInvHw = false
  public buscaInv = false
  public buscaUsu = false
  public btnAsignarHw = true

  public rolesAzure = Roles;

  public dataa: any = [ ['Equipo','# Serie', '# TAG','# Inventario','Caracteristicas'],];
  
  toFile;
  public date: Date = new Date();
  
  usuarioElegido: any = {
    idUsuario:              null,
    numeroEmpleado:           null,
    nombreEmpleado:         '',
    correo:                 '',
    alias:                  '',
    idUnidadAdministrativa: '',
    idPuesto:               '',
    idMunicipio:            '',
    estatus:                '',
  }
  
  @ViewChild('txtBuscaAsignacion', {static: false}) txtBuscaAsignacion!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaUsuario', {static: false}) txtBuscaUsuario!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaInventario', {static: false}) txtBuscaInventario!:ElementRef<HTMLInputElement>;

  constructor(private _pdfService:PdfService, private inventarioService: InventarioService, private http: HttpClient, private formBuilder: FormBuilder, private uploadService: UploadService, private MicrosoftAuthService: MicrosoftAuthService){
    this.formAsignacionHw = this.formBuilder.group({
      usuario: ['',[Validators.required]],
      idUsuario: ['',[Validators.required]],
      idUbicacion: ['',[Validators.required]],
      motivo: ['',[Validators.required]],
    })

    this.formDocumento = this.formBuilder.group({
      tipoDoc: ['',[Validators.required]],
      file: ['',[Validators.required]],
    })

    this.formBaja = this.formBuilder.group({
      idInventarioSw: ['',[Validators.required]],
      motivoBaja: ['',[Validators.required]],
    })
  }

  ngOnInit() { this.buscarUbicaciones() }

  ngAfterViewInit(): void { this.muestraAsignaciones(); }

  buscarUbicaciones(){
    this.inventarioService.getUbicaciones().subscribe((respUbicaciones:any) =>{
       this.ubicacion = respUbicaciones.data;
    })
  }

  muestraAsignaciones(){
    this.asignaciones = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaAsignacion.nativeElement.value === ''){
      let rep = {
        "limit": 0,
        "Page" : this.page
      }
      this.inventarioService.getAsignacionesHw(rep).subscribe((respAsignaciones:any) =>{
        if(respAsignaciones.data != null && respAsignaciones.data.length > 0){
          this.asignaciones = respAsignaciones.data;
          this.totalRecords = respAsignaciones.totalRecords
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error =>{
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    } else {
      let datoPost={
        "nombreTabla": "asighw",
        "valorBusqueda": this.txtBuscaAsignacion.nativeElement.value,
        "Page": this.page
      }
        
      this.inventarioService.asignacionHwTablaCampoHw(datoPost).subscribe((respuesta:any) =>{
        if (respuesta['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.asignaciones = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }
      }, error =>{
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    }
  }

  guardaAsignacionHw(){
    const FileName = 'AltaAsignacion'+this.date.toLocaleDateString();
    let datoPost = {
      "idUsuario":this.formAsignacionHw.value['idUsuario'],
      "idResponsable": this.idResponsable,
      "email": this.usuarioElegido['correo'],
      "detalleAsignaciones":this.hardwareElegido,
      "idUbicacion":this.formAsignacionHw.value['idUbicacion'],
      "motivo":this.formAsignacionHw.value['motivo'],
      "tipoDocumento":'A',
    }
    const embedHTML = document.getElementById('foo');
      // this.http.post(`${this.url}Asignaciones/hw`, datoPost).subscribe(data => {
      this.inventarioService.createAsignacionHw(datoPost).subscribe(data => {
        if (data['result'] === 1) {
          for (let index = 4; index < data['data']['equiposList'].length+4; index++) {
            let posicion = index - 4
            var arrayDeCadenas = data['data']['equiposList'][posicion]['equipo'].split('¶');
            let tipo = arrayDeCadenas[0]
            let marca = arrayDeCadenas[1]
            let mod = arrayDeCadenas[2]
            let nuinv = arrayDeCadenas[3]
            let nuse = arrayDeCadenas[4]
            let nutag = arrayDeCadenas[5]
            this.dataa.push([ 
                  tipo+' - '+marca+' - '+mod,
                  nuse,
                  nutag,
                  nuinv,
                  data['data']['equiposList'][posicion]['caracteristicas']
            ])
          }
          const widths = [120,80,120,70,80]; 
          this._pdfService.generatePDFAsignacion(this.dataa, embedHTML,'AltaAsignación'+data['data']['folioAsignacion']+'.pdf', data['data']['motivo'],data['data']['idUsuario'],data['data']['nombreUbicacion'],data['data']['folioAsignacion'],data['data']['usuario'],'Alta',data['data']['fechafechaHoraAsignacion'],widths);
          
          
          this.page = 1
          this.formAsignacionHw.reset();
          this.hardwareElegido = []
          this.usuarioElegido = []
          this.invHardware = []
          this.usuarios = []
          this.muestraAsignaciones();
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
          }).then(()=>{
            let element: HTMLElement = document.getElementsByClassName('btnNuevaFactura')[0] as HTMLElement;
            element.click()
          })
        }
    })   
  }

  buscaAsignacionInput(){ if (this.txtBuscaAsignacion.nativeElement.value !== '') { this.page = 1; this.muestraAsignaciones(); } }  
      
  cambioAsignacionInput(){ if (this.txtBuscaAsignacion.nativeElement.value === '') { this.page = 1; this.muestraAsignaciones(); } }

  buscaUsuario(){
    
      let valor = this.txtBuscaUsuario.nativeElement.value;
      if (valor === '') {
        this.usuarios.length = 0
        this.buscaUsu = true
      } else {
        let datoPostUsuario={
            "nombreTabla": "usr",
            "valorBusqueda": valor,
          }
        this.http.get(`${this.url}Usuarios/nombre/`+valor).subscribe((respUsuario:any) =>{
          if (respUsuario['result'] === 0) {
            this.usuarios.length = 0
            this.buscaUsu = true
           } else {
            this.buscaUsu = true
            this.usuarios = respUsuario.data;
           }
        }) 
      }
  }

  eligeUsuario(idUsuario){
    this.http.get(`${this.url}Usuarios/`+idUsuario).subscribe((respUsuario:any) =>{
      this.usuarioElegido = respUsuario.data;
   })
   this.validaBtnAsignarHw()
  }

  eliminarHardware(i){
    this.hardwareElegido.splice(i,1)
    this.validaBtnAsignarHw()
  }

  buscaInventario(){
    let valor = this.txtBuscaInventario.nativeElement.value;
    if (valor === '') {
      this.invHardware.length = 0
      this.buscaInv = true
    } else {
      let datoPostInventario={
          "nombreTabla": "invhwstock",
          "valorBusqueda": valor,
        }
        this.inventarioService.getInvHwTablaCampo(datoPostInventario).subscribe((respInventario:any) =>{
        if (respInventario['result'] === 0) {
          this.invHardware.length = 0
          this.buscaInv = true
         } else {
          this.buscaInv = true
          this.invHardware = respInventario.data;
         }
      }) 
    }
  }

  eligeInventario(idInventarioHw){
    let hayigual = false
    for (let index = 0; index < this.hardwareElegido.length; index++) {
     if (idInventarioHw == this.hardwareElegido[index].idInventarioHW) {
        hayigual = true
      }
    }

    if (hayigual == true) {
      Swal.fire({
        title: "Advertencia",
        text: "No puede agregar el mismo inventario 2 veces",
        type: "warning",
      }).then(()=>{
        let element: HTMLElement = document.getElementsByClassName('btnNuevaAsignacion')[0] as HTMLElement;
        element.click()
      })
    }else{
      this.inventarioService.getInvHwCadena(idInventarioHw).subscribe((respInventarioE:any) =>{
        let inve = respInventarioE.data[0]
        this.hardwareElegido.push(inve);
        this.hayInvHw = true
        let element: HTMLElement = document.getElementsByClassName('btnNuevaAsignacion')[0] as HTMLElement;
        element.click()
      })
      this.validaBtnAsignarHw()
    }

    
   
  }

  validaBtnAsignarHw(){
    if (this.hardwareElegido.length > 0 && this.usuarioElegido != '' && this.formAsignacionHw.valid) {
      this.btnAsignarHw = false
    }else{
      this.btnAsignarHw = true
    }
  }

  noSpecialChar(char){
  const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }
  
  bajaAsignacion(){
    if (this.formBaja.value['motivoBaja'] == '' || this.formBaja.value['motivoBaja'] == undefined) {
      Swal.fire({
        title: "ERROR",
        text: 'Escriba el motivo de la baja',
        type: "warning",
        // timer: 2000
      }).then(()=>{
        let element: HTMLElement = document.getElementsByClassName('btnBaja')[0] as HTMLElement;
        element.click()
      })
    } else {
      let asignacionBaja = {
        "idUsuario": 0,
        "idUbicacion": 0,
        "motivo": this.formBaja.value['motivoBaja'],
        "idResponsable": this.idResponsable,
        "detalleAsignaciones": [
          {
            "idAsignacion": this.checks ,
            "idInventarioHw": 0,
            "idInventarioSw": 0
          }
        ]
      }
        this.inventarioService.bajaAsignacionHw(asignacionBaja).subscribe(data => {
          if (data['result'] === 1) {
            this.muestraAsignaciones()
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
            })
          }
        }, error => {
        })   
    }
    
  } 

  cambioCheck(idAsignacion,check){
    if (check == true) {
      this.checks.push(idAsignacion)
    } else {
      const filteredLibraries = this.checks.filter((item) => item !== idAsignacion)
      this.checks = filteredLibraries
    }
  }

  abreModalDocumentos(folioAsignacion,idHistorialAsignacion){

    this.muestraTipoDocs()
    this.idAsignacionDoc = idHistorialAsignacion
    this.inventarioService.getListDocumentosAsignacion(folioAsignacion).subscribe((respuesta:any) =>{
      if (respuesta['result'] == 1) {
        this.bandDocumentoVacio = false
        this.documentosAsignacion = respuesta.data;
      } else {
        this.bandDocumentoVacio = true
        this.folioAsignacion = folioAsignacion
      }
      
    })
  }

  crearArchivoHandheld(){
    const embedHTML = document.getElementById('foo');
    this.inventarioService.crearDocumentoHandheld(this.folioAsignacion).subscribe((data:any) =>{
      for (let index = 4; index < data['data']['equiposList'].length+4; index++) {
        let posicion = index - 4
        var arrayDeCadenas = data['data']['equiposList'][posicion]['equipo'].split('¶');
        let tipo = arrayDeCadenas[0]
        let marca = arrayDeCadenas[1]
        let mod = arrayDeCadenas[2]
        let nuinv = arrayDeCadenas[3]
        let nuse = arrayDeCadenas[4]
        let nutag = arrayDeCadenas[5]
        this.dataa.push([ 
              tipo+' - '+marca+' - '+mod,
              nuse,
              nutag,
              nuinv,
              data['data']['equiposList'][posicion]['caracteristicas']
        ])
      }
      const widths = [120,80,120,70,80]; 
      this._pdfService.generatePDFAsignacion(this.dataa, embedHTML,'AltaAsignación'+data['data']['folioAsignacion']+'.pdf', data['data']['motivo'],data['data']['idUsuario'],data['data']['nombreUbicacion'],data['data']['folioAsignacion'],data['data']['usuario'],'Alta',data['data']['fechafechaHoraAsignacion'],widths);
      this.abreModalDocumentos(this.folioAsignacion,this.idAsignacionDoc)
    })
  }

  muestraTipoDocs(){
    this.inventarioService.getTipoDocumentos('a').subscribe((respuesta:any) =>{
       this.tiposDocumentos = respuesta.data;
    })
  }

  nuevoDoc(event){
    this.toFile = event.target.files;
  }

  async subirArchivo() {
    const file = this.toFile.item(0);
    
    this.uploadService.fileUpload(file);
    let idAsignacion = this.idAsignacionDoc
    let datoPost={
        "tipoDocumento": "A",
        "idFHA": idAsignacion,
        "listaDocumentos": [{
          "idTipoDocumento": this.formDocumento.value['tipoDoc'],
          "ruta": file.name
        }]
      }
      
    this.inventarioService.saveDocumento(datoPost).subscribe((respuesta:any) =>{
      if (respuesta['result'] === 1) {
        this.formDocumento.reset()
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
      }else{
        Swal.fire({
          title: "ERROR",
          text: respuesta['message'],
          type: "warning",
        })
      }
    }) 
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraAsignaciones();
  }

  funcionErrorPaginacion(){
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.muestraAsignaciones();
        } else {
          this.txtBuscaAsignacion.nativeElement.value = "";
          this.page = 1;
          this.muestraAsignaciones();
        }
      })
    }
  }
}
