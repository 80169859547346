import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger              } from '@angular/animations';
import { Caracteristicas, CaracteristicasResponse                } from 'src/app/model/caracteristicas';
import { FormBuilder, FormGroup, Validators                      } from '@angular/forms';
import { InventarioHW, InvHwResponse                             } from '../../../model/inventarioHw';
import { HttpClient, HttpEventType                               } from '@angular/common/http';
import { Roles, Ubicacion, Usuario                               } from 'src/app/model';
import { InventarioService                                       } from '../../../data/service/inventario.service';
import { facturaSoftware                                         } from 'src/app/model/facturaSoftware';
import { TipoHWResponse                                          } from 'src/app/model/tipoHW';
import { MarcasResponse                                          } from 'src/app/model/marcas';
import { environment                                             } from 'src/environments/environment';
import { PdfService                                              } from 'src/app/data/service/pdf-excel/pdf.service';
import { Situacion                                               } from 'src/app/model/situacion';
import { Hardware                                                } from '../../../model/Hardware';
import { IOption                                                 } from 'ng-select';
import { Utils                                                   } from '../../../utils/utils';
import { Reply                                                   } from 'src/app/model/reply';
import   Swal                                                      from 'sweetalert2';

export interface Task {
  completed: boolean;
  arrayInventaHw?: InventarioHW[];
}

@Component({
  selector: 'app-hardware',
  templateUrl: './hardware.component.html',
  styleUrls: ['./hardware.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('800ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('800ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('toggleAnimation', [
      state('mostrar', style({ height: '*', opacity: 1 })),
      state('ocultar', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
      transition('mostrar <=> ocultar', [animate('300ms ease-in-out')]),
    ]),
  ],
})

export class HardwareComponent implements OnInit, AfterViewInit {
  @ViewChild('txtBuscaInvHard',         {static: false}) txtBuscaInvHard!:          ElementRef<HTMLInputElement>;
  @ViewChild('opcBuscarTipoHW',         {static: false}) opcBuscarTipoHW!:          any;
  @ViewChild('opcBuscarTipoMarcas',     {static: false}) opcBuscarTipoMarcas!:      any;
  @ViewChild('opcBuscarAsignaNoAsigna', {static: false}) opcBuscarAsignaNoAsigna!:  any;
  @ViewChild('opcBuscarUbicación',      {static: false}) opcBuscarUbicación!:       any;
  @ViewChild('opcBuscarUsuarios',       {static: false}) opcBuscarUsuarios!:        any;

  @ViewChild('txtBuscaCars',            {static: false}) txtBuscaCars!:             ElementRef<HTMLInputElement>;
  @ViewChild('btnAgregarCar',           {static: false}) btnAgregarCar!:            ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaUsuario',         {static: false}) txtBuscaUsuario!:          ElementRef<HTMLInputElement>;
  @ViewChild('txtFindCarsInve',         {static: false}) txtFindCarsInve!:          ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaFacSoft',         {static: false}) txtBuscaFacSoft!:          ElementRef<HTMLInputElement>;
  @ViewChild('modalRelacionarFactura',  {static: false}) modalRelacionarFactura!:   ElementRef<HTMLInputElement>;

  images: string[] = [];
  inventarioSinImagen = true;
  currentIndex: number = 0;
  selectedImageIndex: number | null = null; // Índice de la imagen seleccionada
  idInvH : number;
  numeroInventario: number;
  idsInventario: number[]=[];
  facturas: facturaSoftware[];
  public facturasSoftware: facturaSoftware[] = [];
  public totalRecords: number = 0;
  public totalRecordsfactura: number = 0;
  private idResponsable = localStorage.getItem('employeeId');
  public formEditaHardware : FormGroup;
  public formBajaInvHard : FormGroup = this.formBuilder.group({
    motivoBaja: ['',[Validators.required]],
  });
  public formBaja: FormGroup;

  public allComplete: boolean = false;
  public checkSelect: Task = {
    completed: false,
    arrayInventaHw: []
  };
  public arraSelectionHW : number[] = [];

  public catHardware: Hardware[];
  public situaciones: Situacion[];

  public asignado = ''

  public flagRecuperaFiltroAnt      : boolean   = false;
  public pageAnterior               : number    = 1;
  public selectBuscarTipoHW         : string[]  = [];
  public selectBuscarTipoMarcas     : string[]  = [];
  public selectBuscarAsignaNoAsigna : string[]  = [];
  public selectBuscarUsuarios       : string[]  = [];
  public selectedUbicacion          : string[]  = [];
  public page                       : number    = 1;
  public pageFactura                : number    = 1;
  public pageSize                   : number    = 1;
  public pageSizefactura            : number    = 10;
  public currentPage                : number    = 0;
  public cargarLoader               : boolean   = true;

  public rolesAzure = Roles;

  public carsTipoHw: Caracteristicas[] = [];
  public findCarInvent: InventarioHW[] = [];
  public carsEditar: any = [{
    idCaracteristica: null,
    caracteristica:    '',
    descripcion:     null,
    idTipoHw:    null,
  }]

  invHwEditar: any = {
    idInventarioHW: null,
    numeroSerie:    '',
    idHardware:     null,
    idSituacion:    null,
    idTipoHw:       null,
    numeroInvAlm:   null
}

  
  public buscaUsu = false
  hayHardwareAsignadoEnSeleccion = false;
  hayHardwareSinFacturaEnSeleccion = false;
  hayHardwareDesasignadoEnSeleccion = false;
  public btnAsignarHw = true
  public formAsignacionHw         : FormGroup;
  public date: Date = new Date();
  public usuarios: Usuario[] = [];
  private url: string = environment.api;
  public ubicacion: Ubicacion[] = [];
  public opcTipoHW                  : Array<IOption> = [];
  public opcTipoMarcas              : Array<IOption> = [];
  public opcAsignaNoAsigna          : Array<IOption> = [
    {     value: '-',  label: 'Ambos'        }
    , {   value: '1', label: 'Asignado'     }
    , {   value: '0', label: 'No Asignado'  }
  ];
  public opcUbicacion               : Array<IOption> = [];
  public opcUsuarios                : Array<IOption> = [];
  public flagTipoHW                 : boolean = false;
  public flagTipoMarca              : boolean = false;
  public flagUbicacion              : boolean = false;
  public flagUsuarios               : boolean = false;
  public mostrarFiltros             : boolean = false;
  public dataa: any = [ ['Equipo','# Serie', '# TAG','# Inventario','Caracteristicas'],];
  usuarioElegido: any = {
    idUsuario:              null,
    numeroEmpleado:         null,
    nombreEmpleado:         '',
    correo:                 '',
    alias:                  '',
    idUnidadAdministrativa: '',
    idPuesto:               '',
    idMunicipio:            '',
    estatus:                '',
  }

  public animation: boolean = false;
  public multiple: boolean = false;
  showError: boolean = false;
  fechaActualFormateada = formatDate(new Date());
  selectedFiles: File[] = [];
  input: any;

  LimpiarStorageFiltro() : void {
    // Limpia Filtro en LocalStorage
    localStorage.setItem('txtBuscaInvHard',         "");
    localStorage.setItem('opcBuscarTipoHW',         "");
    localStorage.setItem('opcBuscarTipoMarcas',     "");
    localStorage.setItem('opcBuscarAsignaNoAsigna', "");
    localStorage.setItem('opcBuscarUsuarios',       "");
    localStorage.setItem('opcBuscarUbicación',      "");
    localStorage.setItem('dataAnterior',            "");
  }

  GuardarStorageFiltro() : void {
    // Guarda Filtro en LocalStorage
    localStorage.setItem('txtBuscaInvHard',         this.txtBuscaInvHard.nativeElement.value  ??  ""  );
    localStorage.setItem('opcBuscarTipoHW',         this.opcBuscarTipoHW?._value[0]           ??  ""  );
    localStorage.setItem('opcBuscarTipoMarcas',     this.opcBuscarTipoMarcas?._value[0]       ??  ""  );
    localStorage.setItem('opcBuscarAsignaNoAsigna', this.opcBuscarAsignaNoAsigna?._value[0]   ??  ""  );
    localStorage.setItem('opcBuscarUsuarios',       this.opcBuscarUsuarios?._value[0]         ??  ""  );
    localStorage.setItem('opcBuscarUbicación',      this.opcBuscarUbicación?._value           ??  ""  );
    localStorage.setItem('dataAnterior',            this.checkSelect.arrayInventaHw.length.toString() );
    this.flagRecuperaFiltroAnt = false;
  }

  RecuperarValoresStorage() : void {
    // Valida si hay datos guardados en el LocalStorage
    if (  localStorage.getItem('txtBuscaInvHard')         != ""                               )
      this.txtBuscaInvHard.nativeElement.value  =   localStorage.getItem('txtBuscaInvHard'          );

    if (  localStorage.getItem('opcBuscarTipoHW')         != ""                               ) {
      this.selectBuscarTipoHW                   = [ localStorage.getItem('opcBuscarTipoHW')         ];
      this.opcBuscarTipoHW?._value.push(            localStorage.getItem('opcBuscarTipoHW')         );
    }
    if (  localStorage.getItem('opcBuscarTipoMarcas')     != ""                               ) {
      this.selectBuscarTipoMarcas               = [ localStorage.getItem('opcBuscarTipoMarcas')     ];
      this.opcBuscarTipoMarcas?._value.push(        localStorage.getItem('opcBuscarTipoMarcas')     );
    }
    if (  localStorage.getItem('opcBuscarAsignaNoAsigna') != ""                               ) {
      this.selectBuscarAsignaNoAsigna           = [ localStorage.getItem('opcBuscarAsignaNoAsigna') ];
      this.opcBuscarAsignaNoAsigna?._value.push(    localStorage.getItem('opcBuscarAsignaNoAsigna') );
    }
    if (  localStorage.getItem('opcBuscarUsuarios') != ""                               ) {
      this.selectBuscarUsuarios                 = [ localStorage.getItem('opcBuscarUsuarios')       ];
      this.opcBuscarUsuarios?._value.push(          localStorage.getItem('opcBuscarUsuarios')       );
    }
    if (  localStorage.getItem('opcBuscarUbicación') != ""                               ) {
      const result                              = localStorage.getItem(  'opcBuscarUbicación').split(",");
      this.selectedUbicacion                    = result;
      result.forEach(valor => {
        this.opcBuscarUbicación?._value.push(valor);
      });
    }

    if ( this.flagRecuperaFiltroAnt ) this.page = this.pageAnterior;
  }

  ValidaStorageVacioFiltro() : boolean {
    if (  localStorage.getItem('txtBuscaInvHard')         != "") return false;
    if (  localStorage.getItem('opcBuscarTipoHW')         != "") return false;
    if (  localStorage.getItem('opcBuscarTipoMarcas')     != "") return false;
    if (  localStorage.getItem('opcBuscarAsignaNoAsigna') != "") return false;
    if (  localStorage.getItem('opcBuscarUsuarios')       != "") return false;
    if (  localStorage.getItem('opcBuscarUbicación')      != "") return false;

    return true;
  }

  public formBusqueda: FormGroup;

  constructor(private formBuilder      : FormBuilder,
              private inventarioService: InventarioService,
              private _pdfService      : PdfService,
              private http             : HttpClient) {
    this.formEditaHardware = this.formBuilder.group({
      idInventarioHw: ['',[Validators.required]],
      modelo: ['',[Validators.required]],
      numeroSerie: ['',[Validators.required]],
      numeroInvAlm: ['',[Validators.required]],
      idMarca: ['',[Validators.required]],
      idSituacion: ['',[Validators.required]],
      idCaracteristica: ['',[Validators.required]],
    });

    this.formAsignacionHw = this.formBuilder.group({
      usuario: ['',[Validators.required]],
      idUsuario: ['',[Validators.required]],
      idUbicacion: ['',[Validators.required]],
      motivo: [''],
    })

    this.formBaja = this.formBuilder.group({
      idInventarioSw: ['', [Validators.required]],
      motivoBaja: ['', [Validators.required]],
    })

    this.formBusqueda = this.formBuilder.group({
      buscarFactura: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.buscarUbicaciones();
    this.buscarTipoHW();
    this.buscarTipoMarcas();
    this.buscaUsuariosFilter();
    if (this.images.length == 0) {
      this.resetImagenes();
      this.inventarioSinImagen = true;
      this.images.push("/assets/images/no-image.jpg");
    }
  }

  onFilesSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.input = input;
    if (input?.files) {
      const files = Array.from(input.files);
      const validFiles = files.filter((file) => {
        // Validate file type and size
        return file.type.startsWith('image/jpeg') && file.size < 1.5 * 1024 * 1024;
      });
      if (validFiles.length !== files.length) {
        this.showError = true;
      // Hide error message after 3 seconds
        setTimeout(() => {
          this.showError = false;
        }, 3000);
      }
      this.selectedFiles = validFiles; // Solo guarda las imágenes válidas
      if (!this.selectedFiles.length) {
        input.value = ''; // Limpia el contenido del input
        this.selectedFiles = []; // Resetea la lista de archivos seleccionados
      }
    }
  }

  subirArchivos(): void { //TODO Subir Archivos.
    if (this.selectedFiles.length === 0) {
      alert('Por favor, selecciona archivos para subir.');
      return;
    }

    this.cargarLoader = true;
    let i = 0;
    // Agrega los archivos al FormData
    this.selectedFiles.forEach((file, index) => {
      let nombreDocumento = file.name.split('.');
      const fileNameWithTimestamp = `${nombreDocumento[0]}_${this.fechaActualFormateada}.${nombreDocumento[1]}`;
      const dirNumeroSerie = this.numeroInventario;
      let data = {
        bucket_name: "inventario-rfid",
        file_path: `imagenes/${dirNumeroSerie}/${fileNameWithTimestamp}`,
        content_type: "image/jpeg"
      }
      // formData.append(`file${index}`, file, file.name);
      this.inventarioService.getUrlSignedToUploadFiles(data).subscribe(
        response => {
          const uploadUrl = response.body;
          this.inventarioService.uploadFileToS3(uploadUrl, file).subscribe(
            event => {
              if (event.type === HttpEventType.UploadProgress) {
                // const percentDone = Math.round(100 * event.loaded / event.total);
                // console.log(`File is ${percentDone}% uploaded.`);
              } else if (event.type === HttpEventType.Response) {
                // console.log('File uploaded successfully!', event.body);
                i++;
                if (this.selectedFiles.length == i) {
                  Swal.fire({
                    title: "Éxito",
                    text: `Se completó la carga de forma correcta.`,
                    type: "success"
                  })
                  this.input.value = "";
                  this.obtieneUnHardware(this.idInvH);
                  this.resetImagenes()
                  this.cargarLoader = false;
                }
              }
            },
            error => {
              Swal.fire({
                confirmButtonText: 'Aceptar',
                title            : 'Atención:',
                text             : 'Ocurrió un error al cargar la fotografía.',
              });
            }
          );
        },
        error => {
          Swal.fire({
            confirmButtonText: 'Aceptar',
            title            : 'Atención:',
            text             : 'Ocurrió un error al cargar la fotografía.',
          });
        }
      );
    });
  }

  prev(): void {
    this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.images.length - 1;
  }

  next(): void {
    this.currentIndex = (this.currentIndex < this.images.length - 1) ? this.currentIndex + 1 : 0;
  }

  removeImage(index: number, event: Event): void {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se eliminará esta imágen",
      showCancelButton: true,
      confirmButtonColor: '#ed3a3a',
      cancelButtonColor: '#DFDFDF',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss) {

      }else{
        this.cargarLoader = true;
        var splitted = this.images[index].split("?", 1);
        var splitted = splitted[0].split("/");
        let nombreImagen = splitted[splitted.length-1];
        event.stopPropagation(); // Evita que el evento se propague al contenedor padre
        if (index >= 0 && index < this.images.length) {
          this.inventarioService.DeleteFileFromS3(this.numeroInventario.toString(), nombreImagen).subscribe({
            next: ({ result, data, message }: Reply) => {
              if (result === 1) {
                this.obtieneUnHardware(this.idInvH);
              } else {
                Swal.fire({
                  confirmButtonText: 'Aceptar',
                  title            : 'Atención:',
                  text             : message,
                });
              }
              this.cargarLoader = false;
            },
            error: (e) => {
              this.handleError(e);
            }
          });
          this.images.splice(index, 1);
          this.selectedImageIndex = null; // Reinicia selección
          // Ajusta el índice actual si la imagen eliminada era la última
          if (this.currentIndex >= this.images.length) {
            this.currentIndex = this.images.length - 1;
          }
          if (this.images.length == 0) {
            this.resetImagenes();
            this.inventarioSinImagen = true;
            this.images.push("/assets/images/no-image.jpg");
          }
        }
      }
    })
  }

  resetImagenes(){
    this.images = [];
    this.currentIndex = 0;
    this.selectedFiles = []; // Resetea la lista de archivos seleccionados
  }

  ngAfterViewInit(): void {
    // Limpia Filtro en LocalStorage
    this.LimpiarStorageFiltro();
    this.muestraInvHardware();
  }

  muestraInvHardware(){
    this.checkSelect.arrayInventaHw        = [];
    this.allComplete                       = false;
    this.currentPage                       = 0;
    this.cargarLoader                      = true;
    this.hayHardwareAsignadoEnSeleccion    = false;
    this.hayHardwareSinFacturaEnSeleccion  = false;
    this.hayHardwareDesasignadoEnSeleccion = false;

     // Valida si hay datos guardados en el LocalStorage
     if ( this.flagRecuperaFiltroAnt ) this.RecuperarValoresStorage();

    if (this.txtBuscaInvHard.nativeElement.value  === ''
      && this.opcBuscarTipoHW?._value[0]          === undefined
      && this.opcBuscarTipoMarcas?._value[0]      === undefined
      && this.opcBuscarAsignaNoAsigna?._value[0]  === undefined
      && this.opcBuscarUbicación?._value[0]       === undefined
      && this.opcBuscarUsuarios?._value[0]        === undefined
    ){
      this.inventarioService.getInventarioHwPagination(this.page).subscribe((respInvHardware:any) =>{
        if(respInvHardware.data != null && respInvHardware.data.length > 0){
          this.checkSelect.arrayInventaHw = respInvHardware.data;
          this.checkSelect.arrayInventaHw.forEach(resp =>{
            resp.completed = false;
          });
          this.currentPage = respInvHardware.pages;
          this.pageAnterior = this.page;
          this.cargarLoader = false;

          // Guarda Filtro en LocalStorage
          this.GuardarStorageFiltro();
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    } else {
      let datoPostInvHard = {
        "nombreTabla"       : "invhw",
        "valorBusqueda"     : this.txtBuscaInvHard.nativeElement.value,
        "idTipoHW"          : this.opcBuscarTipoHW?._value[0]         != undefined ? parseInt(this.opcBuscarTipoHW?._value[0])          : null,
        "idTipoMarca"       : this.opcBuscarTipoMarcas?._value[0]     != undefined ? parseInt(this.opcBuscarTipoMarcas?._value[0])      : null,
        "idAsignaNoAsigna"  : this.opcBuscarAsignaNoAsigna?._value[0] != undefined ? parseInt(this.opcBuscarAsignaNoAsigna?._value[0])  : null,
        "idsUbicacion"      : this.opcBuscarUbicación?._value[0]      != undefined ? this.opcBuscarUbicación?._value.toString()         : null,
        "idUsuario"         : this.opcBuscarUsuarios?._value[0]       != undefined ? this.opcBuscarUsuarios?._value.toString()          : null,
        "Page"              : this.page
      }
      this.inventarioService.getInvHwTablaCampo(datoPostInvHard).subscribe((respInvSoft:any) =>{
        if (respInvSoft['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.checkSelect.arrayInventaHw = respInvSoft.data;
          this.checkSelect.arrayInventaHw.forEach(resp =>{
            resp.completed = false;
          });
          this.currentPage = respInvSoft.pages;
          this.pageAnterior = this.page;
          this.cargarLoader = false;

          // Guarda Filtro en LocalStorage
          this.GuardarStorageFiltro();
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  muestraTipoHw(){
    this.inventarioService.getHardware().subscribe((respHardware:any) =>{
      this.catHardware = respHardware.data;
    })
  }

  muestraSituaciones(){
    this.inventarioService.getSituaciones().subscribe((respSituaciones:any) =>{
      this.situaciones = respSituaciones.data;
    })
  }

  abreEditaHardware(idInv){
    this.idInvH = idInv;
    this.muestraTipoHw()
    this.muestraSituaciones()
    this.obtieneUnHardware(idInv);
  }

  obtieneUnHardware(idInv:number){
    this.inventarioService.getInvHwXId(idInv).subscribe((resped:any) =>{
      this.invHwEditar=resped.data
      this.numeroInventario = this.invHwEditar.numeroInvAlm;
      if (resped.data !=null) {
        this.inventarioService.getUrlsFirmadas(this.invHwEditar.numeroInvAlm).subscribe((urlsFirmadas: any) =>{
          let urls: string[] = urlsFirmadas.url;
          if (urls.length > 0) {
            this.images = [];
            urlsFirmadas.url.forEach(urlFoto => {
              this.images.push(urlFoto);
            });
            this.inventarioSinImagen = false;
          }else{
            this.images.push("/assets/images/no-image.jpg");
            this.inventarioSinImagen = true;
          }
        });
      }
    })
  }

  /// Metodos de Caracteristicas
  //#region 
  ObtenerIdsInvHw() {
    this.arraSelectionHW = [];
    this.carsEditar = [];
    this.carsTipoHw = [];
    this.txtBuscaCars.nativeElement.value = "";

    this.arraSelectionHW = this.checkSelect.arrayInventaHw?.filter(inv => inv.completed == true).map(inv => inv.idInventarioHW);
  }
  
  buscaCaracteristicas() {
    this.txtBuscaCars.nativeElement.value = this.txtBuscaCars.nativeElement.value.trim();
    if (this.txtBuscaCars.nativeElement.value != '') {
      this.carsTipoHw = [];
      let datoPostFacSoft={
        "nombreTabla": "caracteristica",
        "valorBusqueda": this.txtBuscaCars.nativeElement.value,
      }
      this.inventarioService.getCaracteristicasTablaCampo(datoPostFacSoft).subscribe( (respInvSoft:CaracteristicasResponse) => {
        if (respInvSoft.result === 1) this.carsTipoHw = respInvSoft.data;
      })
    }
  }

  LimpiaraClonacion() {
    this.txtFindCarsInve.nativeElement.value  = '';
    this.txtBuscaCars.nativeElement.value     = '';
    this.findCarInvent                        = [];
    this.carsTipoHw                           = [];
  }

  buscarCaractInventario() {
    this.txtFindCarsInve.nativeElement.value = this.txtFindCarsInve.nativeElement.value.trim();
    if (this.txtFindCarsInve.nativeElement.value != '' && this.txtFindCarsInve.nativeElement.value.length >= 3) {
      this.findCarInvent  = [];
      let datoPostFacSoft = {
        "nombreTabla"       : "filterClonar",
        "valorBusqueda"     : this.txtFindCarsInve.nativeElement.value,
        "idTipoHW"          : null,
        "idTipoMarca"       : null,
        "idAsignaNoAsigna"  : null,
        "Page"              : null
      }
      this.inventarioService.getInvHwTablaCampo(datoPostFacSoft).subscribe((respInvSoft:InvHwResponse) =>{
        if (respInvSoft['result'] != 0) this.findCarInvent = respInvSoft.data;
      });
    }
  }

  EligeCaracteristicas ( caracteristicas:Caracteristicas[] ) {
    const nuevasCaracteristicas = caracteristicas.filter((caracteristica: Caracteristicas) =>
      !this.carsEditar.some(car => car.idCaracteristica === caracteristica.idCaracteristica)
    );
    if (nuevasCaracteristicas.length > 0) this.carsEditar.push(...nuevasCaracteristicas);
  }

  eligeCar(i: number) {
    let arrEncontrado: Caracteristicas[] = this.carsEditar.filter((caracteristica: Caracteristicas) =>
      caracteristica.idCaracteristica === this.carsTipoHw[i].idCaracteristica
    );
    if (arrEncontrado.length === 0) this.carsEditar.push(this.carsTipoHw[i])
  }

  eliminaCaracteristica( i:number ){ this.carsEditar.splice(i,1); }

  guardaAsignacionCategoria() {
    let datoPost = {
      "idInventarioHw": this.arraSelectionHW,
      "caracteristicas": this.carsEditar,
    }
    this.inventarioService.saveCaracteristicasOnInvHw(datoPost).subscribe(data => {
      if (data['result'] === 1) {
        this.muestraInvHardware();
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        });
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
        }).then(() => {
          let element: HTMLElement = document.getElementsByClassName('modalNuevasCaracteristicas')[0] as HTMLElement;
          element.click()
        })
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: error.message,
        type: "warning",
      }).then(() => {
        let element: HTMLElement = document.getElementsByClassName('modalNuevasCaracteristicas')[0] as HTMLElement;
        element.click()
      })
    });
  }
  //#endregion
  /// FIN de los Metodos de Caracteristicas

  bajaInvHardware(){
    if( !this.formBajaInvHard.invalid ) {
      let InvHwBaja = {
        idInventarioHw: this.checkSelect.arrayInventaHw?.filter(inv => inv.completed == true).map(inv => inv.idInventarioHW),
        motivoBaja: this.formBajaInvHard.controls['motivoBaja'].value,
        idResponsable: this.idResponsable
      }
      this.inventarioService.bajaInvHw(InvHwBaja).subscribe(data => {
        if (data['result'] === 1) {
          this.formBajaInvHard.reset();
          this.muestraInvHardware()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
          })
        } else {
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
          })
        }
      }, error => {});
    }
  }

  toggleFiltros() { this.mostrarFiltros = !this.mostrarFiltros; }

  buscaInvHardInput(){
    this.page = 1; this.muestraInvHardware(); 
  }

  isBusquedaLimpia(): boolean {
    return this.txtBuscaInvHard?.nativeElement.value  === ''
        && this.opcBuscarTipoHW?._value[0]            === undefined
        && this.opcBuscarTipoMarcas?._value[0]        === undefined
        && this.opcBuscarAsignaNoAsigna?._value[0]    === undefined
        && this.opcBuscarUbicación?._value[0]         === undefined
        && this.opcBuscarUsuarios?._value[0]          === undefined;
  }

  limpiarBusqueda( noLimpiarStorage?: boolean ) {
    this.txtBuscaInvHard.nativeElement.value  = "";
    this.opcBuscarTipoHW.clear();
    this.opcBuscarTipoMarcas.clear();
    this.opcBuscarAsignaNoAsigna.clear();
    this.opcBuscarUbicación.clear();
    this.opcBuscarUsuarios.clear();
    this.page                                 = 1;
    this.selectBuscarTipoHW                   = [];
    this.selectBuscarTipoMarcas               = [];
    this.selectBuscarAsignaNoAsigna           = [];
    this.selectBuscarUsuarios                 = [];
    this.selectedUbicacion                    = [];
    if(!noLimpiarStorage) {
      this.LimpiarStorageFiltro();
      this.flagRecuperaFiltroAnt              = false;
    }
    this.muestraInvHardware();
  }

  editaInvHard(){
      this.inventarioService.editInvHw(this.formEditaHardware.value ).subscribe(data => {
        if (data['result'] === 1) {
          this.formEditaHardware.reset();
          this.muestraInvHardware()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success"
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning"
          })
        }
    })
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraInvHardware();
  }

  funcionErrorPaginacion(){
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        this.flagRecuperaFiltroAnt = true;
        if ( this.page != 1 ) this.loadPage(this.pageAnterior);
        else{
          if( !this.ValidaStorageVacioFiltro() || parseInt( localStorage.getItem('dataAnterior') ) > 0 ) this.limpiarBusqueda(true);
        }
      })
    }
  }

  caracteristicasItem: InventarioHW = null;
  asignadoItem: InventarioHW = null;
  numInvItem: InventarioHW = null;
  tooltipPosition = { top: '0px', left: '0px' };

  showTooltipCaracteristicas(event: MouseEvent, item: any) {
    this.caracteristicasItem = null;
    this.caracteristicasItem = item;

    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();  // Obtener las coordenadas del elemento
    const offsetX = 10; // Ajuste de posición en el eje X
    const offsetY = 0; // Ajuste de posición en el eje Y

    this.tooltipPosition = {
      top: `${(rect.top + window.scrollY) - (2 * rect.height)}px`,  // Sumar desplazamiento si hay scroll
      left: `${rect.left + offsetX + window.scrollX}px` // Sumar desplazamiento si hay scroll
    };
  }

  hideTooltipCaracteristicas() { this.caracteristicasItem = null; }
  ////////////////////////////
  showTooltipAsignado(event: MouseEvent, item: any) {
    this.asignadoItem = null;
    this.asignadoItem = item;

    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();  // Obtener las coordenadas del elemento
    const offsetX = 10; // Ajuste de posición en el eje X
    const offsetY = 0; // Ajuste de posición en el eje Y

    this.tooltipPosition = {
      top: `${(rect.top + window.scrollY) - (2 * rect.height)}px`,  // Sumar desplazamiento si hay scroll
      left: `${rect.left + offsetX + window.scrollX}px` // Sumar desplazamiento si hay scroll
    };
  }

  hideTooltipAsignado() { this.asignadoItem = null; }
  ////////////////////////////
  showTooltipNumInv(event: MouseEvent, item: any) {
    this.numInvItem = null;
    this.numInvItem = item;

    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();  // Obtener las coordenadas del elemento
    const offsetX = 10; // Ajuste de posición en el eje X
    const offsetY = 0; // Ajuste de posición en el eje Y

    this.tooltipPosition = {
      top: `${(rect.top + window.scrollY) - (2 * rect.height)}px`,  // Sumar desplazamiento si hay scroll
      left: `${rect.left + offsetX + window.scrollX}px` // Sumar desplazamiento si hay scroll
    };
  }

  hideTooltipNumInv() { this.numInvItem = null; }

  updateAllComplete() {
    this.allComplete = this.checkSelect.arrayInventaHw != null && this.checkSelect.arrayInventaHw.every(t => t.completed);
    this.hayHardwareAsignadoEnSeleccion    = false;
    this.hayHardwareSinFacturaEnSeleccion  = false;
    this.hayHardwareDesasignadoEnSeleccion = false;
    this.checkSelect.arrayInventaHw.forEach(hardware => {
      if (hardware.asignado == 1 && hardware.completed == true) {
        this.hayHardwareAsignadoEnSeleccion = true;
      }
      if (hardware.asignado == 0 && hardware.completed == true) {
        this.hayHardwareDesasignadoEnSeleccion = true;
      }
      if (hardware.numeroFactura != null && hardware.completed == true) {
        this.hayHardwareSinFacturaEnSeleccion = true;
      }
    });
  }

  someComplete(): boolean {
    if (this.checkSelect.arrayInventaHw == null) return false;
    return this.checkSelect.arrayInventaHw.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setListaIdsInventario(){
    this.checkSelect.arrayInventaHw?.filter(inv => inv.completed == true).forEach(check => {
      this.idsInventario.push(check.idInventarioHW);
      this.muestraFacturas();
    });
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.checkSelect.arrayInventaHw == null) return;
    this.hayHardwareAsignadoEnSeleccion = false;
    this.hayHardwareSinFacturaEnSeleccion = false;
    this.hayHardwareDesasignadoEnSeleccion = false;
    this.checkSelect.arrayInventaHw.forEach(hardware => {
      hardware.completed = completed;
      if (hardware.asignado == 1) {
        this.hayHardwareAsignadoEnSeleccion = true;
      }
      if (hardware.asignado == 0) {
        this.hayHardwareDesasignadoEnSeleccion = true;
      }
      if (hardware.numeroFactura != null) {
        this.hayHardwareSinFacturaEnSeleccion = true;
      }
      if (completed == false) {
        this.hayHardwareAsignadoEnSeleccion    = false;
        this.hayHardwareDesasignadoEnSeleccion = false;
        this.hayHardwareSinFacturaEnSeleccion  = false;
      }
    });
  }

  guardaAsignacionHw() {
    const FileName = 'AltaAsignacion' + this.date.toLocaleDateString();
    let datoPost = {
      "idUsuario": this.formAsignacionHw.value['idUsuario'],
      "idResponsable": this.idResponsable,
      "email": this.usuarioElegido['correo'],
      "detalleAsignaciones": this.checkSelect.arrayInventaHw?.filter(inv => inv.completed == true),
      "idUbicacion": this.formAsignacionHw.value['idUbicacion'],
      "motivo": this.formAsignacionHw.value['motivo'],
      "tipoDocumento": 'A',
    }
    const embedHTML = document.getElementById('foo');
    this.dataa = [['Equipo','# Serie', '# TAG','# Inventario','Caracteristicas'],];
    this.inventarioService.createAsignacionHw(datoPost).subscribe(data => {
      if (data['result'] === 1) {
        for (let index = 4; index < data['data']['equiposList'].length + 4; index++) {
          let posicion = index - 4
          var arrayDeCadenas = data['data']['equiposList'][posicion]['equipo'].split('¶');
          let tipo = arrayDeCadenas[0]
          let marca = arrayDeCadenas[1]
          let mod = arrayDeCadenas[2]
          let nuinv = arrayDeCadenas[3]
          let nuse = arrayDeCadenas[4]
          let nutag = arrayDeCadenas[5]
          this.dataa.push([
            tipo + ' - ' + marca + ' - ' + mod,
            nuse,
            nutag,
            nuinv,
            data['data']['equiposList'][posicion]['caracteristicas']
          ])
        }
        const widths = [120, 80, 120, 70, 80];
        this._pdfService.generatePDFAsignacion(this.dataa, embedHTML, 'AltaAsignación' + data['data']['folioAsignacion'] + '.pdf', data['data']['motivo'], data['data']['idUsuario'], data['data']['nombreUbicacion'], data['data']['folioAsignacion'], data['data']['usuario'], 'Alta', data['data']['fechafechaHoraAsignacion'], widths);
        this.page = 1
        this.formAsignacionHw.reset();
        this.setAll(this.allComplete);
        // this.usuarioElegido = []
        this.usuarios = []
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
        this.muestraInvHardware();
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
        }).then(() => {
          let element: HTMLElement = document.getElementsByClassName('btnNuevaFactura')[0] as HTMLElement;
          element.click()
        })
      }
    })
  }

  buscaUsuario() {
    let valor = this.txtBuscaUsuario.nativeElement.value;
    if (valor === '') {
      this.usuarios.length = 0
      this.buscaUsu = true
    } else {
      this.http.get(`${this.url}Usuarios/nombre/` + valor).subscribe((respUsuario: any) => {
        if (respUsuario['result'] === 0) {
          this.usuarios.length = 0
          this.buscaUsu = true
        } else {
          this.buscaUsu = true
          this.usuarios = respUsuario.data;
        }
      })
    }
  }

  buscaUsuariosFilter(){
    this.http.get(`${this.url}Usuarios`).subscribe((response:any) =>{
      response.data.forEach(user => {
        this.opcUsuarios.push({ value: user.idUsuario.toString(), label: user.nombreEmpleado })
      });
      this.flagUsuarios = true;
    })
  }

  buscarUbicaciones(){
    this.inventarioService.getUbicaciones().subscribe((respUbicaciones:any) =>{
      this.ubicacion = respUbicaciones.data;

      respUbicaciones.data.forEach(tipo => {
        this.opcUbicacion.push({ value: tipo.idUbicacion.toString(), label: tipo.nombreUbicacion });
      });
      this.flagUbicacion = true;
    })
  }

  buscarTipoHW() {
    this.inventarioService.getTipoHardware().subscribe((response:TipoHWResponse) =>{
      response.data.forEach(tipo => {
        this.opcTipoHW.push({ value: tipo.idTipoHw.toString(), label: tipo.nombre })
      });
      this.flagTipoHW = true;
    });
  }

  buscarTipoMarcas(){
    this.inventarioService.getMarcas().subscribe((response: MarcasResponse) => {
      response.data.forEach(tipo => {
        this.opcTipoMarcas.push({ value: tipo.idMarca.toString(), label: tipo.nombreMarca });
      });
      this.flagTipoMarca = true;
    });
  }

  validaBtnAsignarHw() {
    if (this.checkSelect.arrayInventaHw.length > 0 && this.usuarioElegido != '' && this.formAsignacionHw.valid) {
      this.btnAsignarHw = false
    } else {
      this.btnAsignarHw = true
    }
  }

  noSpecialChar(char) {
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  eligeUsuario(idUsuario) {
    this.http.get(`${this.url}Usuarios/` + idUsuario).subscribe((respUsuario: any) => {
      this.usuarioElegido = respUsuario.data;
    })
    this.validaBtnAsignarHw()
  }

  campoValido(campo: string) {
    return Utils.campoValido(campo, this.formEditaHardware);
  }

  private handleError(error: any) {
    this.cargarLoader = false;
    let errorMessage = 'No se pudo establecer conexión con el servidor, verifique su conexión.';
    if (error.status === 0) {
      errorMessage = 'Vuelva a intentar por favor';
    } else if (error.status === 400) {
      errorMessage = 'Se produjo uno o más errores de validación.';
    } else if (error.status === 403) {
      errorMessage = 'No cuenta con permisos de acceso para realizar la consulta.';
    }
    Swal.fire({
      title: 'Atención:',
      text: errorMessage,
      confirmButtonText: 'Aceptar',
    });
  }

  bajaAsignacion() {
    this.dataa = [['Equipo','# Serie', '# TAG','# Inventario','Caracteristicas'],];
    const embedHTML = document.getElementById('foo');
    const FileName = 'BajaAsignacion' + this.date.toLocaleDateString();
    this.cargarLoader = true;
    let checks = [];
    this.checkSelect.arrayInventaHw?.filter(inv => inv.completed == true).forEach(check => {
      checks.push(check.idInventarioHW);
    });
    let asignacionBaja = {
      "idUsuario": 0,
      "idUbicacion": 0,
      "motivo": this.formBaja.value['motivoBaja'],
      "idResponsable": this.idResponsable,
      "detalleAsignaciones": [
        {
          "idAsignaciones": checks,
        }
      ],
      "tipoDocumento": 'A',
      "ruta": 'https://inventario-rfid.s3.amazonaws.com/' + FileName + '.pdf'
    }
    this.inventarioService.bajaAsignacionHw(asignacionBaja).subscribe(data => {
      if (data['result'] === 1) {
        for (let index = 4; index < data['data']['equiposList'].length + 4; index++) {
          let posicion = index - 4
          var arrayDeCadenas = data['data']['equiposList'][posicion]['equipo'].split('¶');
          let tipo = arrayDeCadenas[0]
          let marca = arrayDeCadenas[1]
          let mod = arrayDeCadenas[2]
          let nuinv = arrayDeCadenas[3]
          let nuse = arrayDeCadenas[4]
          let nutag = arrayDeCadenas[5]
          this.dataa.push([
            tipo + ' - ' + marca + ' - ' + mod,
            nuse,
            nutag,
            nuinv,
            data['data']['equiposList'][posicion]['caracteristicas']
          ])
        }
        const widths = [120, 80, 120, 70, 80];
        this._pdfService.generatePDFAsignacion(this.dataa, embedHTML, 'BajaAsignación' + data['data']['folioAsignacion'] + '.pdf', data['data']['motivo'], data['data']['idUsuario'], data['data']['nombreUbicacion'], data['data']['folioAsignacion'], data['data']['usuario'], 'Baja', data['data']['fechafechaHoraAsignacion'], widths);
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
        this.muestraInvHardware();
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
        }).then(() => {
          this.muestraInvHardware();
        });
      }
    }, error => {
    })
  }

  muestraFacturas(){
    this.facturas = [];
    this.totalRecordsfactura = 0;
    this.cargarLoader = true;
    if (this.formBusqueda.controls['buscarFactura'].value === ''){
      this.inventarioService.getFacturas().subscribe((respFacturas:any) =>{
        if(respFacturas.data != null && respFacturas.data.length > 0){
          this.facturas = respFacturas.data;
          this.totalRecordsfactura = respFacturas.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
    else{
      let datoPostFacSoft = {
        "nombreTabla": "fsw",
        "valorBusqueda": this.formBusqueda.controls['buscarFactura'].value === '',
        "Page": this.pageFactura
      }
      this.inventarioService.getFacturaTablaCampo(datoPostFacSoft).subscribe((respInvSoft: any) => {
        if (respInvSoft['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.facturas = respInvSoft.data;
          this.totalRecordsfactura = respInvSoft.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  buscaFacSoftInput(){
    let valor = this.formBusqueda.controls['buscarFactura'].value;
    if (valor === '') {
      this.muestraFacturas();
    } else {
      //
      let datoPostFacSoft={
          "nombreTabla": "fsw",
          "valorBusqueda": valor,
          "Page": this.pageFactura
        }
      this.inventarioService.getFacturaTablaCampo(datoPostFacSoft).subscribe((respInvSoft:any) =>{
        if (respInvSoft['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.facturas = respInvSoft.data;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  relacionarFacturas(idFactura:number, ){
    let datosAsignaFactura = {
      idFactura,
      idsInventario: this.idsInventario
    }
    this.inventarioService.asignaInventariosAFactura(datosAsignaFactura).subscribe({
      next: ({ result, message }: Reply) => {
        if (result === 1) {
          Swal.fire({
            confirmButtonText: 'Aceptar',
            title            : 'Atención:',
            text             : message,
          }).then(() => {
            this.muestraInvHardware();
            let element: HTMLElement = document.getElementsByClassName('modalRelacionarFactura')[0] as HTMLElement;
            element.click()
          })
        } else {
          Swal.fire({
            confirmButtonText: 'Aceptar',
            title            : 'Atención:',
            text             : message,
          });
        }
        this.cargarLoader = false;
      },
      error: (e) => {
        this.handleError(e);
      }
    });
  }
}

function formatDate(date: Date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    'T' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join('-')
  );
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}