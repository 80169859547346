import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from "./material/material.module";
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import {NgxPaginationModule} from 'ngx-pagination';

import { DxGanttModule,  DxTemplateModule, DxPieChartModule } from 'devextreme-angular';

// Plantilla de administrador
import { AdminComponent } from './view/layout/admin/admin.component';
import { AuthComponent } from './view/layout/auth/auth.component';
import { NavigationComponent } from './view/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './view/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './view/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './view/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './view/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './view/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './view/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './view/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './view/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './view/layout/admin/configuration/configuration.component';
import { ToggleFullScreenDirective } from './view/shared/full-screen/toggle-full-screen';

// Guardian
import { AuthGuard } from './data/guard/auth.guard';
// Componentes para compartir
import { SharedModule } from './view/shared/shared.module';
// Navegacion de Items
import { NavigationItem } from './view/layout/admin/navigation/navigation';

import { JwtInterceptor, ErrorInterceptor } from './data/helpers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HardwareComponent } from './view/pages/hardware/hardware.component';
import { SoftwareComponent } from './view/pages/software/software.component';
import { AsignacionHardwareComponent } from './view/pages/asignacion-hardware/asignacion-hardware.component';
import { AsignacionSoftwareComponent } from './view/pages/asignacion-software/asignacion-software.component';
import { ReportesComponent } from './view/pages/reportes/reportes.component';
import { CatalogoHardwareComponent } from './view/pages/catalogo-hardware/catalogo-hardware.component';
import { CatalogoSoftwareComponent } from './view/pages/catalogo-software/catalogo-software.component';
import { ControlUsuariosComponent } from './view/pages/control-usuarios/control-usuarios.component';
import { FacturasSoftwareComponent } from './view/pages/facturas-software/facturas-software.component';
import { ControlLicenciasComponent } from './view/pages/control-licencias/control-licencias.component';
import { CatalogoPermisosComponent } from './view/pages/catalogo-permisos/catalogo-permisos.component';
import { PeticionesModule } from './service/peticiones/peticiones.module';
import { CatalogoDocumentosComponent } from './view/pages/catalogo-documentos/catalogo-documentos.component';
import { CatalogoCaracteristicasComponent } from './view/pages/catalogo-caracteristicas/catalogo-caracteristicas.component';
import { CatalogoMarcasComponent } from './view/pages/catalogo-marcas/catalogo-marcas.component';
import { CatalogoTipoHwComponent } from './view/pages/catalogo-tipo-hw/catalogo-tipo-hw.component';
import { CatalogoTipoSwComponent } from './view/pages/catalogo-tipo-sw/catalogo-tipo-sw.component';
import { CatalogoAntenasComponent } from './view/pages/catalogo-antenas/catalogo-antenas.component';
import { CatalogoUbicacionesComponent } from './view/pages/catalogo-ubicaciones/catalogo-ubicaciones.component';
import { CatalogoSituacionesComponent } from './view/pages/catalogo-situaciones/catalogo-situaciones.component';
import { GrupoPermisoComponent } from './view/pages/grupo-permiso/grupo-permiso.component';
import { ReAsignacionSoftwareComponent } from './view/pages/re-asignacion-software/re-asignacion-software.component';
import { RepAsignacionUbicacionSwComponent } from './view/pages/rep-asignacion-ubicacion-sw/rep-asignacion-ubicacion-sw.component';
import { RepAsignacionUbicacionHwComponent } from './view/pages/rep-asignacion-ubicacion-hw/rep-asignacion-ubicacion-hw.component';
import { DisponibleAsignarHwComponent } from './view/pages/disponible-asignar-hw/disponible-asignar-hw.component';
import { DisponibleAsignarSwComponent } from './view/pages/disponible-asignar-sw/disponible-asignar-sw.component';
import { HistorialAsignacionSwComponent } from './view/pages/historial-asignacion-sw/historial-asignacion-sw.component';
import { HistorialAsignacionHwComponent } from './view/pages/historial-asignacion-hw/historial-asignacion-hw.component';
import { ReporteInventarioHwComponent } from './view/pages/reporte-inventario-hw/reporte-inventario-hw.component';
import { ReporteBajaHwComponent } from './view/pages/reporte-baja-hw/reporte-baja-hw.component';
import { ReporteVencimientoLicenciasComponent } from './view/pages/reporte-vencimiento-licencias/reporte-vencimiento-licencias.component';
import { ReporteValorInventarioHwComponent } from './view/pages/reporte-valor-inventario-hw/reporte-valor-inventario-hw.component';
import { ReporteValorInventarioSwComponent } from './view/pages/reporte-valor-inventario-sw/reporte-valor-inventario-sw.component';
import { ReporteInventarioSwComponent } from './view/pages/reporte-inventario-sw/reporte-inventario-sw.component';
import { PdfAltaAsignacionComponent } from './view/pages/pdf-alta-asignacion/pdf-alta-asignacion.component';
import { ReporteCaracteristicasComponent } from './view/pages/reporte-caracteristicas/reporte-caracteristicas.component';
import { DesasignacionHwComponent } from './view/pages/desasignacion-hw/desasignacion-hw.component';
import { DesasignacionSwComponent } from './view/pages/desasignacion-sw/desasignacion-sw.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '@iplab/ngx-file-upload';

//Azure
import { MsalModule, MsalRedirectComponent, MSAL_INSTANCE, MsalService, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MicrosoftAuthService } from './data/service/auth/microsoft-auth.service';
import { environment } from 'src/environments/environment';
import { CatalogoDesinoNotificacionesComponent } from './view/pages/catalogo-desino-notificaciones/catalogo-desino-notificaciones.component';
import { HistorialAntenasComponent } from './view/pages/historial-antenas/historial-antenas.component';
import { SelectModule } from 'ng-select';
import { ReporteCantInventHWComponent } from './view/pages/reporteCantInventHW/reporteCantInventHW.component';

const _url = environment.api;
const _redirectUri = environment.redirectUri;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId:    '14412a15-d084-4385-baec-7d9396b5c788',
      authority:   'https://login.microsoftonline.com/6d88d07c-1672-4d16-ab8c-3af2ef736fe7', //https://login.microsoftonline.com/ más el tenantId
      redirectUri: _redirectUri,
    },
  })
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(_url, ['api://898ee150-b50d-4459-ba0f-285138924257/Auth.AD']);
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#00ACC1',
  bgsOpacity: 0.4,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'square-jelly-box',
  blur: 8,
  fgsColor: '#e12362',
  fgsPosition: 'center-center',
  fgsSize: 80,
  fgsType: 'square-jelly-box',
  gap: 55,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#e12362',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: false,
  text: 'Cargando...',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  // threshold: 500
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    HardwareComponent,
    SoftwareComponent,
    AsignacionHardwareComponent,
    AsignacionSoftwareComponent,
    ReportesComponent,
    CatalogoHardwareComponent,
    CatalogoSoftwareComponent,
    ControlUsuariosComponent,
    FacturasSoftwareComponent,
    ControlLicenciasComponent,
    CatalogoPermisosComponent,
    CatalogoDocumentosComponent,
    CatalogoCaracteristicasComponent,
    CatalogoMarcasComponent,
    CatalogoTipoHwComponent,
    CatalogoTipoSwComponent,
    CatalogoAntenasComponent,
    CatalogoUbicacionesComponent,
    CatalogoSituacionesComponent,
    GrupoPermisoComponent,
    ReAsignacionSoftwareComponent,
    RepAsignacionUbicacionSwComponent,
    RepAsignacionUbicacionHwComponent,
    DisponibleAsignarHwComponent,
    DisponibleAsignarSwComponent,
    HistorialAsignacionSwComponent,
    HistorialAsignacionHwComponent,
    ReporteInventarioHwComponent,
    ReporteBajaHwComponent,
    ReporteVencimientoLicenciasComponent,
    ReporteValorInventarioHwComponent,
    ReporteValorInventarioSwComponent,
    ReporteInventarioSwComponent,
    PdfAltaAsignacionComponent,
    ReporteCaracteristicasComponent,
    DesasignacionHwComponent,
    DesasignacionSwComponent,
    CatalogoDesinoNotificacionesComponent,
    HistorialAntenasComponent,
    ReporteCantInventHWComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FileUploadModule,
    SharedModule,
    NgbTabsetModule,
    HttpClientModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    DxGanttModule,DxTemplateModule, DxPieChartModule,
    MatNativeDateModule, MaterialModule, MatCheckboxModule,
    PeticionesModule,
    NgxPaginationModule,
    MsalModule,
    NgbModule,
    SelectModule
  ],
  providers: [
    NavigationItem,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    }, {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MicrosoftAuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }