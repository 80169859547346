import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { invHardware } from 'src/app/model/invHArdware';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import Swal from 'sweetalert2';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-reporte-inventario-hw',
  templateUrl: './reporte-inventario-hw.component.html',
  styleUrls: ['./reporte-inventario-hw.component.scss']
})
export class ReporteInventarioHwComponent implements OnInit {
  public invHardware: invHardware[] = [];
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  public date: Date = new Date();

  public rolesAzure = Roles;

  constructor(private _pdfService: PdfService, private http: HttpClient, private inventarioService: InventarioService) { }

  ngOnInit() { this.muestraInventario() }

  muestraInventario() {
    this.invHardware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    this.inventarioService.getReporteInvHw(this.page).subscribe((respuesta: any) => {
      if (respuesta.data != null && respuesta.data.length > 0) {
        this.invHardware = respuesta.data;
        this.totalRecords = respuesta.totalRecords;
        this.cargarLoader = false;
      }
      else this.funcionErrorPaginacion();
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
    })
  }

  exportExcel() {
    this.inventarioService.getReporteInvHw(-1).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invHardware);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, "ReporteInventarioHardware-" + this.date.toLocaleDateString() + ".xlsx");
        /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        })
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
    })
  }

  async generatePDF() {
    this.inventarioService.getReporteInvHw(-1).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        const tituloEncabezado = 'Reporte de Inventario de Hardware';
        const FileName = 'InventarioHardware' + this.date.toLocaleDateString();
        const embedHTML = document.getElementById('foo');
        const widths = '*';
        this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        })
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
    })
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraInventario();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.muestraInventario();
        } else {
          this.page = 1;
          this.muestraInventario();
        }
      })
    }
  }
}