<div style="text-align: center;">
  <span>
    <h3>Historial de asignación de hardware</h3>
  </span>
</div>
<div class="row">
  <div class="col-2"></div>
  <div class="col-8" style="display: flex; justify-content: center; width: 100%; margin-top: 20px;">
    <form [formGroup]="formFechas">
      <div class="row">
        <div class="form-group" style="margin-right: 25px;">
          <label for="divIdUsuario" class="control-label">Usuario</label>
          <div class="input-group mb-3" id="divIdUsuario">
            <input type="text" class="form-control" id="idUsuario" placeholder="Usuario" formControlName="usuario"
              [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" (click)="quitarUsuario()"><i
                  class="feather icon-x"></i></button>
              <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario"
                data-bs-toggle="modal"><i class="feather icon-search"></i></button>
            </div>
            <input type="hidden" formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">
          </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
          <label for="divIdUsuario" class="control-label">Inventario</label>
          <div class="input-group mb-3" id="divIdInventario">
            <input type="text" class="form-control" id="idHardware" placeholder="Tag Inventario"
              formControlName="hardware" [(ngModel)]="hardwareElegido.modelo" disabled>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" (click)="quitarInventario()"><i
                  class="feather icon-x"></i></button>
              <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeInventario"
                data-bs-toggle="modal"><i class="feather icon-search"></i></button>
            </div>
            <input type="hidden" formControlName="idInventarioHw" [(ngModel)]="hardwareElegido.idInventarioHW">
          </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
          <div class="form-group">
            <label class="control-label is-required" for="fechaInicio">Fecha Inicio</label>
            <input type="date" class="form-control" id="fechaInicio" placeholder="Fecha Inicio" formControlName="fini">
          </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
          <div class="form-group">
            <label class="control-label is-required" for="vencimiento">Fecha Fin</label>
            <input type="date" class="form-control" id="vencimiento" placeholder="Vencimiento" formControlName="ffin">
          </div>
        </div>
        <!-- Contenedor del botón centrado -->
        <div style="display: flex; justify-content: center; margin-top: 20px;">
          <div class="form-group" style="display: inline-block;">
            <button class="btn btn-md btn-primary" (click)="mostrarReporte()">Mostrar Reporte</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-2" *ngIf="!cargarLoader" class="shadow p-3 mb-5 bg-body rounded">
    <div class="row">
      <div class="col-6">
        <div style="display: flex; justify-content: center; margin-top: 20px;">
          <div style="display: inline-block;" *appHasAnyRole="[
            rolesAzure.SUPERADMIN, 
            rolesAzure.SUPERVISOR, 
            rolesAzure.ING_SOPORTE
            ]">
            <img src="assets/images/icons/pdf.svg" alt="Exportar PDF" width="32" (click)="generatePDF()"
              style="cursor: pointer;">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div style="display: flex; justify-content: center; margin-top: 20px;">
          <div style="display: inline-block;" *appHasAnyRole="[
            rolesAzure.SUPERADMIN, 
            rolesAzure.SUPERVISOR, 
            rolesAzure.ING_SOPORTE
            ]">
            <img src="assets/images/icons/excel.svg" alt="Exportar Excel" width="32" (click)="exportExcel()"
              style="cursor: pointer;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12" style="overflow: auto;" *ngIf="!cargarLoader">
    <div class="table-container-scroll">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="Padding-Table" scope="col">Folio Asignación</th>
            <th class="Padding-Table" scope="col">Fecha Asignación</th>
            <th class="Padding-Table" scope="col">Asignado</th>
            <th class="Padding-Table" scope="col">Tipo Hardware</th>
            <th class="Padding-Table" scope="col">Marca</th>
            <th class="Padding-Table" scope="col">Modelo</th>
            <th class="Padding-Table" scope="col"># Inventario</th>
            <th class="Padding-Table" scope="col"># TAG</th>
            <th class="Padding-Table" scope="col"># Serie</th>
            <th class="Padding-Table" scope="col">Responsable</th>
            <th class="Padding-Table" scope="col">Movimiento</th>
            <th class="Padding-Table" scope="col">Documentos</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let per of asignacion">
            <td class="Padding-Table">
              {{per.folioAsignacion}}
            </td>
            <td class="Padding-Table">
              {{per.fechaHoraAsignacion | date:'longDate'}}
            </td>
            <td class="Padding-Table">
              {{per.usuario}}
            </td>
            <td class="Padding-Table">
              {{per.nombre}}
            </td>
            <td class="Padding-Table">
              {{per.nombreMarca}}
            </td>
            <td class="Padding-Table">
              {{per.modelo}}
            </td>
            <td class="Padding-Table">
              {{per.numeroInvAlm}}
            </td>
            <td class="Padding-Table">
              {{per.numeroTag}}
            </td>
            <td class="Padding-Table">
              {{per.numeroSerie}}
            </td>
            <td class="Padding-Table">
              {{per.responsable}}
            </td>
            <td class="Padding-Table">
              {{per.tipoMovimiento}}
            </td>
            <td class="Padding-Table" style="text-align: center;">
              <button class="btn btn-secondary" (click)="abreModalDocumentos(per.folioAsignacion)"
                data-bs-toggle="modal" data-bs-target="#modalDocumentos">Documentos <i
                  class="feather icon-upload iconos-acciones"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-start align-items-center p-2">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
        (pageChange)="loadPage($event)" [disabled]="asignacion == null || asignacion.length == 0">
        <ng-template ngbPaginationPrevious>Anterior</ng-template>
        <ng-template ngbPaginationNext>Siguiente</ng-template>
      </ngb-pagination>
      <div class="d-flex align-items-center ml-3">
        <label for="pageInput" class="mr-2">Buscar página específica:</label>
        <input id="pageInput" type="number" class="form-control w-auto" #pageInput (change)="loadPage(pageInput.value)"
          (keyup.enter)="loadPage(pageInput.value)" onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
          placeholder="" aria-label="Buscar página específica" />
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalEligeInventario" tabindex="-1" role="dialog" aria-labelledby="modalEligeInventario"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Elegir Inventario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaInventario placeholder="Buscar Inventario"
                aria-label="Buscar Inventario" aria-describedby="basic-addon2" (keyup.enter)="buscaInventario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaInventario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="col-md-12" *ngIf="buscaInv">
          <table class="table table-bordered" *ngIf="invHardware.length > 0">
            <thead>
              <tr>
                <th scope="col">Hardware</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col"># Serie</th>
                <th scope="col">TAG</th>
                <th scope="col">Elegir</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let det of invHardware">
                <td>
                  {{det.tipoHW}}
                </td>
                <td>
                  {{det.marca}}
                </td>
                <td>
                  {{det.modelo}}
                </td>
                <td>
                  {{det.numeroSerie}}
                </td>
                <td>
                  {{det.numeroTag}}
                </td>
                <td style="text-align: center;">
                  <button class="btn btn-warning" (click)="eligeInventario(det.idInventarioHW)"
                    data-bs-dismiss="modal">Elegir <i class="feather icon-edit iconos-acciones"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered" *ngIf="invHardware.length == 0">
            <thead>
              <tr>
                <th scope="col">Hardware</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col"># Serie</th>
                <th scope="col">TAG</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center" colspan="5">
                  No hay resultados
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario"
                aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="buscaUsu">
        <table class="table table-bordered" *ngIf="usuarios.length > 0">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Número</th>
              <th scope="col">Correo</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of usuarios">
              <td>
                {{per.nombreEmpleado}}
              </td>
              <td>
                {{per.numeroEmpleado}}
              </td>
              <td>
                {{per.correo}}
              </td>
              <td style="text-align: center;">
                <button class="btn btn-warning" data-bs-dismiss="modal"
                  (click)="eligeUsuario(per.numeroEmpleado)">Elegir <i
                    class="feather icon-edit iconos-acciones"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered" *ngIf="usuarios.length == 0">
          <thead>
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center" colspan="2">
                No hay resultados
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalDocumentos" tabindex="-1" role="dialog" aria-labelledby="modalDocumentos"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Documentos</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="container">
          <form [formGroup]="formDocumento" autocomplete="off">
            <div id="subirDoc" style="text-align: center;">
              <h5>Subir Nuevo Documento</h5>
            </div>
            <div id="subirDoc" class="row well">
              <div class="form-group col-5">
                <label class="control-label is-required" for="motivo">Documento</label>
                <input type="file" (change)="nuevoDoc($event)" formControlName="file">
              </div>
              <div class="form-group col-5">
                <label for="state_id" class="control-label is-required">Tipo Documento</label>
                <select class="form-control" id="tipoDoc" formControlName="tipoDoc">
                  <option value="" disabled selected>Elige el tipo de documento</option>
                  <option [value]=doc.idTipoDocumento *ngFor="let doc of tiposDocumentos">{{doc.nombreDocumento}}
                  </option>
                </select>
              </div>
              <div class="col-2" style="text-align: center;">
                <button type="submit" class="btn btn-primary" style="margin-top: 28px;" (click)="subirArchivo()"
                  data-bs-dismiss="modal" [disabled]="formDocumento.invalid">Subir</button>
              </div>
              <div class="modal-footer">
              </div>
            </div>
            <hr>
            <div id="subirDoc" style="text-align: center;">
              <h5>Documentos Existentes</h5>
            </div>
            <div class="row">
              <div class="col-md-12" style="overflow: auto;">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Documento</th>
                      <th scope="col">Descargar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let doc of documentosAsignacion">
                      <td>
                        {{doc.rutaAsignacion}}
                      </td>
                      <td style="text-align: center;">
                        <a href="https://d2kjhhjsuvwfd6.cloudfront.net/{{doc.rutaAsignacion}}" class="btn btn-primary"
                          target="_blank">Descargar<i class="feather icon-download iconos-acciones"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>