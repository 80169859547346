<div class=" shadow p-3 mb-5 bg-body rounded">
    <div style="text-align: center;">
        <span><h3>Catálogo Destinatarios de Notificaciones</h3></span>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-6">
            <!-- <div class="input-group mb-3">
                <input type="text" class="form-control" (change)="cambioBuscaDestinoInput()" #txtBuscaCaracteristica placeholder="Buscar Característica" aria-label="Buscar Característica" aria-describedby="basic-addon2" (keyup.enter)="buscaDestinoInput()">
                <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaDestinoInput()"><i class="feather icon-search"></i></button>
                </div>
            </div> -->
        </div>
        <div class="col-md-5"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
            <div class="div-btn-nuevo-hardware">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNuevoDestino">
                    Agregar Nuevo Destinatario
                  </button>
            </div>
        </div>
        <!-- <div class="col-md-1"></div> -->
    </div>
    
    <table class="table table-bordered" *ngIf="!cargarLoader">
        <thead>
          <tr>
            <th scope="col"># Empleado</th>
            <th scope="col">Nombre Empleado</th>
            <th scope="col">Correo</th>
            <th scope="col"
            *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">Acciones</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let des of destinos">
                <td>
                        {{des.numeroEmpleado}}
                </td>
                <td>
                        {{des.nombreEmpleado}}
                </td>
                <td>
                        {{des.correo}}
                </td>
                <td style="text-align: center;"
                *appHasAnyRole="[
                rolesAzure.SUPERADMIN, 
                rolesAzure.SUPERVISOR, 
                rolesAzure.ING_SOPORTE
                ]">
                    <button class="btn btn-warning" (click)="abreEditaDestino(des.idEmpleado)" data-bs-toggle="modal" data-bs-target="#modalEditaDestino">Editar  <i class="feather icon-edit iconos-acciones"></i></button>
                    <button class="btn btn-danger" (click)="eliminarDestino(des.idEmpleado)"  style="margin-left: 5px;">Baja  <i class="feather icon-trash-2 iconos-acciones"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="destinos == null || destinos.length == 0">
          <ng-template ngbPaginationPrevious>Anterior</ng-template>
          <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>
      
        <div class="d-flex align-items-center ml-3">
          <label for="pageInput" class="mr-2">Buscar página específica:</label>
          <input id="pageInput" type="number" class="form-control w-auto"
                 #pageInput
                 (change)="loadPage(pageInput.value)"
                 (keyup.enter)="loadPage(pageInput.value)"
                 onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                 placeholder="" aria-label="Buscar página específica" />
        </div>
      </div>
</div>


<div class="modal fade" id="modalNuevoDestino" tabindex="-1" aria-labelledby="modalNuevaCaracteristica" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formDestino" (submit)="guardaDestino()">

                <div class="form-group"> 
                    <label class="control-label is-required" for="numeroEmpleado">Número Empleado</label>
                    <input type="text" class="form-control" id="numeroEmpleado"  maxlength="200" placeholder="Número Empleado" formControlName="numeroEmpleado">
                </div>                 

                <div class="form-group"> 
                    <label class="control-label is-required" for="nombreEmpleado">Nombre Empleado</label>
                    <input type="text" class="form-control" id="nombreEmpleado" maxlength="200"  placeholder="Nombre Empleado" formControlName="nombreEmpleado">
                </div>  

                <div class="form-group"> 
                    <label class="control-label is-required" for="correo">Correo</label>
                    <input type="email" class="form-control" id="correo" maxlength="200"  placeholder="Correo" formControlName="correo" ngModel [email]="true">
                </div>                
                           
                <div class="form-group"> 
                </div>     
                
            </form>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="formDestino.invalid" data-bs-dismiss="modal" class="btn btn-primary" (click)="guardaDestino()">Guardar</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modalEditaDestino" tabindex="-1" aria-labelledby="modalNuevaCaracteristica" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formEditaDestino" (submit)="editaDestino()">
                <input hidden="true" [(ngModel)]="destinoEditar.idEmpleado"  formControlName="idEmpleado">
                <div class="form-group"> 
                    <label class="control-label is-required" for="numeroEmpleado">Número Empleado</label>
                    <input type="text" class="form-control" id="numeroEmpleado" maxlength="200" placeholder="Número Empleado" formControlName="numeroEmpleado" [(ngModel)]="destinoEditar.numeroEmpleado">
                </div>                 

                <div class="form-group"> 
                    <label class="control-label is-required" for="nombreEmpleado">Nombre Empleado</label>
                    <input type="text" class="form-control" id="nombreEmpleado" maxlength="200" placeholder="Nombre Empleado" formControlName="nombreEmpleado" [(ngModel)]="destinoEditar.nombreEmpleado">
                </div>  

                <div class="form-group"> 
                    <label class="control-label is-required" for="correo">Correo</label>
                    <input type="text" class="form-control" id="correo" maxlength="200" placeholder="Correo" formControlName="correo"  [(ngModel)]="destinoEditar.correo">
                </div>                
                           
                <div class="form-group"> 
                </div>     
                <div style="text-align: center;">
                    <button type="submit" [disabled]="formEditaDestino.invalid" class="btn btn-primary"  data-bs-dismiss="modal" >Guardar</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <!-- <button type="submit" class="btn btn-primary"  data-bs-dismiss="modal" >Guardar</button> -->
        </div>
      </div>
    </div>
  </div>

  

  