import { Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators                  } from '@angular/forms';
import { HttpClient                                          } from '@angular/common/http';
import   * as XLSX                                             from 'xlsx';
import   Swal                                                  from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { PdfService  } from 'src/app/data/service/pdf-excel/pdf.service';
import { Usuario     } from 'src/app/model/usuario';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { downloadPDF } from '../../shared/functions/downloadPDF';
import { Roles } from 'src/app/model';

@Component({
  selector:     'app-rep-asignacion-ubicacion-sw',
  templateUrl:  './rep-asignacion-ubicacion-sw.component.html',
  styleUrls:   ['./rep-asignacion-ubicacion-sw.component.scss'],
  providers:   [{ provide: LOCALE_ID, useValue: "es" }],
})
export class RepAsignacionUbicacionSwComponent implements OnInit {
   //#region PAGINATION
   public page : number = 1;
   public pageSize: number = 10;
   public totalRecords: number = 0;
   public cargarLoader:boolean = true;
   //#endregion

  private url: string = environment.api;
  
  public formFechas: FormGroup;
  
  public usuarios: Usuario[] = [];
  public invSoftware: any[] = [];
  public ubicacion: any = [];
  public usuarioElegido: any = []
  public usuariosCompartidos: any[] = [];

  public buscaUsu = false
  public date: Date = new Date();
  public data: any = [ ['Folio Asignación','Fecha Asignación', 'Usuario Asignado','Hardware','# Inventario','# Serie','# TAG','Ubicación'],];
  @ViewChild('txtBuscaUsuario', {static: false}) txtBuscaUsuario!:ElementRef<HTMLInputElement>;

  public rolesAzure = Roles;

  constructor(private _pdfService:PdfService, private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) { 
    this.formFechas = this.formBuilder.group({
      fini: ['',[Validators.required]],
      ffin: ['',[Validators.required]],
      usuario: ['',[Validators.required]],
      idUsuario: ['',[Validators.required]],
      ubicacion: ['',[Validators.required]],
    });
  }
  ngOnInit() {
    this.muestraUbicaciones()
    this.mostrarReporte();
  }

  mostrarReporte(){

    this.invSoftware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') ||  (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    }else{
      let ubi
      if (this.formFechas.value['ubicacion'] == '') {
        ubi = 0
      }else{
        ubi = this.formFechas.value['ubicacion']
      }
      let usu
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) {
        usu = 0
      }else{
        usu = this.formFechas.value['idUsuario']
      }
      
      let reporte={
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idUsuario": usu,
        "idUbicacion": ubi,
        "limit": 0,
        "Page": this.page
      }
      
      this.inventarioService.getAsignacionUbicacionSw(reporte).subscribe((respuesta:any) =>{        
        if (respuesta['result'] == 1) {
          this.data= [ ['Folio Asignación','Fecha Asignación', 'Usuario Asignado','Software','Dato Auxiliar','Vencimiento','Temporalidad','Ubicación'],];
          this.invSoftware = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }else{
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.mostrarReporte();
              } else {
                this.page = 1;
                this.formFechas.get("fini").setValue("");
                this.formFechas.get("ffin").setValue("");
                this.formFechas.get("usuario").setValue("");
                this.formFechas.get("idUsuario").setValue("");
                this.formFechas.get("ubicacion").setValue("");
                this.mostrarReporte();
              }
            })
          }
        }
      })
    }
    
  }




  muestraUbicaciones(){
    this.inventarioService.getUbicaciones().subscribe((respHardware:any) =>{
       this.ubicacion = respHardware.data;
    })
  }

  buscaUsuario(){
      let valor = this.txtBuscaUsuario.nativeElement.value;
      if (valor === '') {
        this.usuarios.length = 0
        this.buscaUsu = true
      } else {
        let datoPostUsuario={
            "nombreTabla": "usr",
            "valorBusqueda": valor,
          }
          this.http.get(`${this.url}Usuarios/nombre/`+valor).subscribe((respUsuario:any) =>{
            if (respUsuario['result'] === 0) {
              this.usuarios.length = 0
              this.buscaUsu = true
             } else {
              this.buscaUsu = true
              this.usuarios = respUsuario.data;
             }
        }) 
      }
  }

  eligeUsuario(idUsuario){
    this.http.get(`${this.url}Usuarios/`+idUsuario).subscribe((respUsuario:any) =>{
      this.usuarioElegido = respUsuario.data;
   })
  }

  clicAgregarAsignacion(idAsignacionSw){
    this.inventarioService.getSwCompartido(idAsignacionSw).subscribe((respuesta:any) =>{
      this.usuariosCompartidos = respuesta.data
    })
  }

  quitarUsuario(){
    this.usuarioElegido = []
  }

  exportExcel() {
    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') || (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    } else {
      let ubi
      if (this.formFechas.value['ubicacion'] == '') {
        ubi = 0
      } else {
        ubi = this.formFechas.value['ubicacion']
      }
      let usu
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) {
        usu = 0
      } else {
        usu = this.formFechas.value['idUsuario']
      }

      let reporte = {
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idUsuario": usu,
        "idUbicacion": ubi,
        "limit": 0,
        "Page": -1
      }

      this.inventarioService.getAsignacionUbicacionSw(reporte).subscribe((respuesta: any) => {
        if (respuesta['result'] == 1) {
          /* DESDE AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */
          // var wb = XLSX.utils.table_to_book(document.getElementById('sjs-table'));
          // XLSX.writeFile(wb, "export.xlsx");
          /* HASTA AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */

          /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
          // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invSoftware);
          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, "AsignacionUbicacionSoftware-" + this.date.toLocaleDateString() + ".xlsx");
          /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */

        } else {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.mostrarReporte();
              } else {
                this.page = 1;
                this.mostrarReporte();
              }
            })
          }
        }
      })
    }
  }

  async generatePDF() {

    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') || (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    } else {
      let ubi
      if (this.formFechas.value['ubicacion'] == '') {
        ubi = 0
      } else {
        ubi = this.formFechas.value['ubicacion']
      }
      let usu
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) {
        usu = 0
      } else {
        usu = this.formFechas.value['idUsuario']
      }

      let reporte = {
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idUsuario": usu,
        "idUbicacion": ubi,
        "limit": 0,
        "Page": -1
      }

      this.inventarioService.getAsignacionUbicacionSw(reporte).subscribe((respuesta: any) => {
        if (respuesta['result'] == 1) {
          const tituloEncabezado = 'Reporte de Asignación y Ubicación de Software';
          const FileName = 'AsignacionUbicacionSoftware'+this.date.toLocaleDateString();
          const embedHTML = document.getElementById('foo');
          const widths = 'auto';
          
          this._pdfService.generatePDF(respuesta.data, embedHTML,FileName, widths,tituloEncabezado);
        } else {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.mostrarReporte();
              } else {
                this.page = 1;
                this.mostrarReporte();
              }
            })
          }
        }
      })
    }
  }

  loadPage(page: number) {
    debugger
    this.page = page;
    this.mostrarReporte();
  }
}
