<ng-container *ngIf="!item.hidden">
  <li class="nav-item pcoded-menu-caption"><label>{{item.title}}</label></li>
  <ng-container *ngFor="let item of item.children">
    <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
    <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
    <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
    <!-- <app-nav-item *ngIf="item.type=='item' && item.autorizado.indexOf(this.rol) > -1" [item]="item"></app-nav-item> -->
    <!-- <app-nav-item *ngIf="item.type=='item' && (item.permisoSIRI.indexOf(this.rolSIRI) > -1 || item.permisoSISTEMA.indexOf(this.rolSistema) > -1)" [item]="item"></app-nav-item> -->
  </ng-container>
</ng-container>
