<div style="text-align: center;">
        <span><h3>Reporte de Ubicación y Asignación de Hardware</h3></span>
    </div>
        <form [formGroup]="formFechas">
    <div class="row">
        <div class="form-group" style="margin-right: 25px;">
                <label for="divIdUsuario" class="control-label">Usuario</label>
                <div class="input-group mb-3" id="divIdUsuario"> 
                        <input type="text" class="form-control" id="idUsuario" placeholder="Usuario"  formControlName="usuario" [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>                
                        <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" (click)="quitarUsuario()"><i class="feather icon-x"></i></button>
                                <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario" data-bs-toggle="modal"><i class="feather icon-search"></i></button>
                        </div>
                        <input type="hidden"  formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">                 
                </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
            <div class="form-group"> 
                <label for="ubicacion" class="control-label">Ubicación</label>
                <select class="form-control" id="ubicacion" formControlName="ubicacion">
                    <option value="" disabled selected>Elige la ubicación</option>
                    <option value="0">Ninguno</option>
                    <option [value]=cat.idUbicacion *ngFor="let cat of ubicacion">{{cat.nombreUbicacion}}</option>
                </select>                    
            </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
            <div class="form-group"> 
                <label class="control-label" for="fechaInicio">Fecha Inicio</label>
                <input type="date" class="form-control" id="fechaInicio" placeholder="Fecha Inicio" formControlName="fini">
            </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
            <div class="form-group"> 
                <label class="control-label" for="vencimiento">Fecha Fin</label>
                <input type="date" class="form-control" id="vencimiento" placeholder="Vencimiento" formControlName="ffin">
            </div>
        </div>
    </div>
    </form>
    <div class="row">
        <div class="col-4"></div>
        <div class="col-4" style="text-align: center; margin-bottom: 20px;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
            <button class="btn btn-lg btn-primary" (click)="mostrarReporte()">Mostrar Reporte</button>
        </div>
        <div class="col-4"></div>
    </div>
    <div style="overflow: auto;" *ngIf="!cargarLoader">
      <div class="row">
        <div class="col-8"></div>
        <div class="col-2" style="text-align:right ;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
          <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
        </div>
        <div class="col-2" style="text-align:right ;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
          <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
        </div>
      </div>
      
      <table class="table table-bordered" id="sjs-table">
          <thead>
            <tr>
              <th scope="col">Folio Asignación</th>
              <th scope="col">Fecha Asignación</th>
              <th scope="col">Usuario Asignado</th>
              <th scope="col">Tipo</th>
              <th scope="col">Marca</th>
              <th scope="col">Modelo</th>
              <th scope="col"># inventario</th>
              <th scope="col"># serie</th>
              <th scope="col"># tag</th>
              <th scope="col">Ubicación</th>
              <th scope="col"
              *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">Documentos</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let inv of invHardware">
              <td>
                      {{inv.folioAsignacion}}
              </td>
              <td>
                      {{inv.fechaHoraAsignacion | date:'longDate'}}
              </td>
              <td>
                      {{inv.usuario}}
              </td>
              <td>
                      {{inv.tipohw}}
              </td>
              <td>
                      {{inv.marca}}
              </td>
              <td>
                      {{inv.modelo}}
              </td>
              <td>
                      {{inv.numeroInvAlm}}
              </td>
              <td>
                      {{inv.numeroSerie}}
              </td>
              <td>
                      {{inv.numeroTag}}
              </td>
              <td>
                      {{inv.ubicacion}}
              </td>
              <td style="text-align: center;"
              *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
                  <button class="btn btn-secondary" (click)="abreModalDocumentos(inv.folioAsignacion)" data-bs-toggle="modal" data-bs-target="#modalDocumentos">Documentos   <i class="feather icon-upload iconos-acciones"></i></button>
              </td>
          </tr>
          </tbody>
        </table>
        <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior"S nextLabel="Siguiente"></pagination-controls> -->
        <div class="d-flex justify-content-start align-items-center p-2">
          <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="invHardware == null || invHardware.length == 0">
            <ng-template ngbPaginationPrevious>Anterior</ng-template>
            <ng-template ngbPaginationNext>Siguiente</ng-template>
          </ngb-pagination>
        
          <div class="d-flex align-items-center ml-3">
            <label for="pageInput" class="mr-2">Buscar página específica:</label>
            <input id="pageInput" type="number" class="form-control w-auto"
                   #pageInput
                   (change)="loadPage(pageInput.value)"
                   (keyup.enter)="loadPage(pageInput.value)"
                   onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                   placeholder="" aria-label="Buscar página específica" />
          </div>
        </div>
        
    </div>

  <!-- <div class="row"> -->
    <!-- <div class="col-sm-12">
        <app-card [hidHeader]="true" cardClass="">
            <div class="row text-center">
                <div class="col-sm-12"> 
                    <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
                    <embed src="" id="foo" class="col-12" height="1000">

                    <div id="captura" style="width: 500px; height: 1000px;">
                        <div class="col-12">
                        </div>
                        

                        
                    </div>
                </div>
            </div>
        </app-card>
    </div> -->
<!-- Modal -->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true" >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">  
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario" aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
                            <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i class="feather icon-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="buscaUsu">
                <table class="table table-bordered" *ngIf="usuarios.length > 0">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Número</th>
                      <th scope="col">Correo</th>
                      <th scope="col">Elegir</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let per of usuarios">
                          <td>
                                  {{per.nombreEmpleado}}
                          </td>
                          <td>
                                  {{per.numeroEmpleado}}
                          </td>
                          <td>
                                  {{per.correo}}
                          </td>
                          <td style="text-align: center;">
                              <button class="btn btn-warning" data-bs-dismiss="modal" (click)="eligeUsuario(per.numeroEmpleado)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                          </td>
                      </tr>
                  </tbody>
                </table>
                <table class="table table-bordered" *ngIf="usuarios.length == 0">
                    <thead>
                      <tr>
                        <th scope="col">Usuario</th>
                        <th scope="col">Elegir</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td align="center" colspan="2">
                                 No hay resultados
                          </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
<div class="modal fade" id="modalDocumentos" tabindex="-1" role="dialog" aria-labelledby="modalDocumentos" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Documentos</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
          <div class="container">
              <!-- <form [formGroup]="formDocumento" autocomplete="off"> -->
                  <!-- <div id="subirDoc" style="text-align: center;">
                      <h5>Subir Nuevo Documento</h5>
                  </div> -->
                  <!-- <div id="subirDoc" class="row well">
                      <div class="form-group col-5"> 
                          <label class="control-label is-required" for="motivo">Documento</label>
                          <input type="file" (change)="nuevoDoc($event)" formControlName="file">
                      </div>  
                      <div class="form-group col-5"> 
                          <label for="state_id" class="control-label is-required">Tipo Documento</label>
                          <select class="form-control" id="tipoDoc" formControlName="tipoDoc">
                              <option value="" disabled selected>Elige el tipo de documento</option>
                              <option [value]=doc.idTipoDocumento *ngFor="let doc of tiposDocumentos">{{doc.nombreDocumento}}</option>
                          </select>                    
                      </div> 
                      <div class="col-2" style="text-align: center;">
                          <button type="submit" class="btn btn-primary" style="margin-top: 28px;"(click)="subirArchivo()"  data-bs-dismiss="modal" [disabled]="formDocumento.invalid">Subir</button>
                      </div>
                      <div class="modal-footer">
                      </div>
                  
                  </div> -->
                  <!-- <hr> -->
                  <div id="subirDoc" style="text-align: center;">
                    <h5>Documentos Existentes</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-12" style="overflow: auto;">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <!-- <th scope="col">✓</th> -->
                            <th scope="col">Tipo Documento</th>
                            <th scope="col">Ruta</th>
                            <th scope="col">Descargar</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let doc of documentosAsignacion">
                                <td>
                                        {{doc.nombreDocumento}}
                                </td>
                                <td>
                                        {{doc.rutaAsignacion}}
                                </td>
                                <td style="text-align: center;">
                                   <!-- <button class="btn btn-primary">Descargar<a href={{doc.rutaAsignacion}} download="blank"><i class="feather icon-download iconos-acciones"></i></a></button> -->
                                   <a href="https://d2kjhhjsuvwfd6.cloudfront.net/{{doc.rutaAsignacion}}" class="btn btn-primary"  target="_blank">Descargar<i class="feather icon-download iconos-acciones"></i></a>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              <!-- </form> -->
          </div>
      </div>
    </div>
  </div>
</div>
      