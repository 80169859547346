<div style="text-align: center;">
  <span>
    <h3>DesAsignacion de Hardware</h3>
  </span>
</div>
<form [formGroup]="formUsu">
  <div class="row">
    <div class="col-3"></div>
    <div class="col-6">
      <div class="form-group" style="margin-right: 25px;">
        <!-- <label for="divIdUsuario" class="control-label">Usuario</label> -->
        <div class="input-group mb-3" id="divIdUsuario">
          <input type="text" class="form-control" id="idUsuario" placeholder="Usuario" formControlName="usuario"
            [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario"
              data-bs-toggle="modal"><i class="feather icon-search"></i></button>
          </div>
          <input type="hidden" formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">
        </div>
      </div>
    </div>
    <div class="col-3"></div>
  </div>
  <!-- <div class="row">
        <div class="col-3"></div> 
        <div class="col-6" style="text-align: center;">
            <button class="btn btn-primary" (click)="verInventario()">Ver Asignaciones</button>
        </div>
        <div class="col-3"></div> 
    </div> -->
</form>

<div class="row" *appHasAnyRole="[
    rolesAzure.SUPERADMIN, 
    rolesAzure.SUPERVISOR, 
    ]">
  <div class="col-8"></div>
  <div class="col-4" style="text-align: right;">
    <button class="btn btn-danger btnBaja" *ngIf="this.checks.length > 0" data-bs-toggle="modal"
      data-bs-target="#modalBaja">Desasignar Seleccionados</button>
  </div>
</div>
<div class="row">
  <!-- <div class="col-md-2"></div> -->
  <div class="col-md-12" style="overflow: auto;" *ngIf="!cargarLoader">
    <div class="table-container-scroll">
      <table class="table">
        <thead>
          <tr>
            <th scope="col"  class="Padding-Table" *appHasAnyRole="[
          rolesAzure.SUPERADMIN, 
          rolesAzure.SUPERVISOR, 
          ]">✓</th>
            <th scope="col" class="Padding-Table">Folio Asignación</th>
            <th scope="col" class="Padding-Table">Fecha Asignación</th>
            <th scope="col" class="Padding-Table">Tipo</th>
            <th scope="col" class="Padding-Table">Marca</th>
            <th scope="col" class="Padding-Table">Modelo</th>
            <th scope="col" class="Padding-Table"># Serie</th>
            <th scope="col" class="Padding-Table">TAG</th>
            <th scope="col" class="Padding-Table">Usuario</th>
            <th scope="col" class="Padding-Table">Ubicación</th>
            <!-- <th scope="col">Documentos</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let per of asignaciones">
            <td  class="Padding-Table" *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR,]">
              <input type="checkbox" name="list_name" (change)="cambioCheck(per.idAsignacionHw, $event.target.checked)"
                value="{{per.idAsignacionHw}}">
            </td>
            <td class="Padding-Table">
              {{per.folioAsignacion}}
            </td>
            <td class="Padding-Table">
              {{per.fechaHoraAsignacion | date:'longDate'}}
            </td>
            <td class="Padding-Table">
              {{per.tipohw}}
            </td>
            <td class="Padding-Table">
              {{per.marca}}
            </td>
            <td class="Padding-Table">
              {{per.modelo}}
            </td>
            <td class="Padding-Table">
              {{per.numeroSerie}}
            </td>
            <td class="Padding-Table">
              {{per.numeroTag}}
            </td>
            <td class="Padding-Table">
              {{per.usuario}}
            </td>
            <td class="Padding-Table">
              {{per.ubicacion}}
            </td>
            <!-- <td style="text-align: center;">
                    <button class="btn btn-secondary" (click)="abreModalDocumentos(per.idAsignacionHw)" data-bs-toggle="modal" data-bs-target="#modalDocumentos">Documentos   <i class="feather icon-upload iconos-acciones"></i></button>
                </td> -->
            <!-- <td style="text-align: center;">
                  <button class="btn btn-danger btnBaja"  (click)="abreBajaAsignacion(per.idAsignacionHw)" data-bs-toggle="modal" data-bs-target="#modalBaja" style="margin-left: 5px;">Desasignar<i class="feather icon-trash-2 iconos-acciones"></i></button>
              </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-start align-items-center p-2">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
        (pageChange)="loadPage($event)" [disabled]="asignaciones == null || asignaciones.length == 0">
        <ng-template ngbPaginationPrevious>Anterior</ng-template>
        <ng-template ngbPaginationNext>Siguiente</ng-template>
      </ngb-pagination>

      <div class="d-flex align-items-center ml-3">
        <label for="pageInput" class="mr-2">Buscar página específica:</label>
        <input id="pageInput" type="number" class="form-control w-auto" #pageInput (change)="loadPage(pageInput.value)"
          (keyup.enter)="loadPage(pageInput.value)" onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
          placeholder="" aria-label="Buscar página específica" />
      </div>
    </div>

  </div>
  <!-- <div class="col-md-2"></div> -->
</div>

<!-- Modal -->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal"
          data-bs-target="#modalNuevaAsignacion" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario"
                aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="buscaUsu" style="overflow: auto;">
        <table class="table table-bordered" *ngIf="usuarios.length > 0">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Número</th>
              <th scope="col">Correo</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of usuarios">
              <td>
                {{per.nombreEmpleado}}
              </td>
              <td>
                {{per.numeroEmpleado}}
              </td>
              <td>
                {{per.correo}}
              </td>
              <td style="text-align: center;">
                <button class="btn btn-warning" data-bs-dismiss="modal"
                  (click)="eligeUsuario(per.numeroEmpleado)">Elegir <i
                    class="feather icon-edit iconos-acciones"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered" *ngIf="usuarios.length == 0">
          <thead>
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center" colspan="2">
                No hay resultados
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalBaja" tabindex="-1" role="dialog" aria-labelledby="modalBajaFactura"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Desasignacion de hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div id="divFactura">
            <form [formGroup]="formBaja">
              <!-- <input hidden="true" [(ngModel)]="asignacionBaja"  formControlName="idInventarioSw"> -->
              <div class="form-group">
                <label class="control-label is-required" for="motivo">Motivo</label>
                <textarea type="text" min="1" class="form-control" id="motivoBaja" placeholder="Motivo de la baja"
                  formControlName="motivoBaja" maxlength="200"></textarea>
              </div>
              <div style="text-align: center;">
                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" (click)="bajaAsignacion()">Quitar
                  Asignación</button>
              </div>
              <!-- <hr> -->
              <div class="modal-footer">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>