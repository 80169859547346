import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

import { invSoftware } from 'src/app/model/invSoftware';
import { environment } from 'src/environments/environment';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-reporte-vencimiento-licencias',
  templateUrl: './reporte-vencimiento-licencias.component.html',
  styleUrls: ['./reporte-vencimiento-licencias.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})

export class ReporteVencimientoLicenciasComponent implements OnInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  public formFechas: FormGroup;

  public invSoftware: invSoftware[] = [];
  public tiposw: any = [];
  date = new Date().toISOString().slice(0, 10);
  public dateTable: Date = new Date();
  public data: any = [['Tipo', 'Software', 'Auxiliar', 'Vencimiento', 'Stock', 'Lote'],];

  public rolesAzure = Roles;

  constructor(private _pdfService: PdfService, private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formFechas = this.formBuilder.group({
      fini: ['', [Validators.required]],
      ffin: ['', [Validators.required]],
      tiposw: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.muestraTipoSw();
    this.mostrarReporte();
  }

  mostrarReporte() {
    this.invSoftware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') || (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    } else {
      let tip
      if (this.formFechas.value['tiposw'] == '') {
        tip = 0
      } else {
        tip = this.formFechas.value['tiposw']
      }


      let reporte = {
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "tipoSw": tip,
        "Page": this.page
      }
      this.inventarioService.getReporteVenciminetoLicencias(reporte).subscribe((respuesta: any) => {
        if (respuesta['result'] == 1) {
          this.page = 1
          this.invSoftware = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        } else {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.mostrarReporte();
              } else {
                this.formFechas.get("fini").setValue("")
                this.formFechas.get("ffin").setValue("")
                this.formFechas.get("tiposw").setValue("")
                this.page = 1;
                this.mostrarReporte();
              }
            })
          }
        }
      })
    }
  }

  muestraTipoSw() {
    this.inventarioService.getTipoSoftware().subscribe((respuesta: any) => {
      this.tiposw = respuesta.data;
    })
  }

  disableDate() {
    return false;
  }

  exportExcel() {
    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') || (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    } else {
      let tip
      if (this.formFechas.value['tiposw'] == '') {
        tip = 0
      } else {
        tip = this.formFechas.value['tiposw']
      }


      let reporte = {
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "tipoSw": tip,
        "Page": -1
      }
      this.inventarioService.getReporteVenciminetoLicencias(reporte).subscribe((respuesta: any) => {
        if (respuesta['result'] == 1) {
          /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
          // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invSoftware);
          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, "ReporteVencimientoLicencias-" + this.dateTable.toLocaleDateString() + ".xlsx");
          /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
        } else {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.mostrarReporte();
              } else {
                this.formFechas.get("fini").setValue("")
                this.formFechas.get("ffin").setValue("")
                this.formFechas.get("tiposw").setValue("")
                this.page = 1;
                this.mostrarReporte();
              }
            })
          }
        }
      })
    }
  }

  async generatePDF() {
    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') || (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    } else {
      let tip
      if (this.formFechas.value['tiposw'] == '') {
        tip = 0
      } else {
        tip = this.formFechas.value['tiposw']
      }


      let reporte = {
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "tipoSw": tip,
        "Page": -1
      }
      this.inventarioService.getReporteVenciminetoLicencias(reporte).subscribe((respuesta: any) => {
        if (respuesta['result'] == 1) {
          const tituloEncabezado = 'Reporte de Vencimiento de Licencias';
          const FileName = 'VencimientoLicencias' + this.dateTable.toLocaleDateString();
          const embedHTML = document.getElementById('foo');
          const widths = '*';
          this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
        } else {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.mostrarReporte();
              } else {
                this.formFechas.get("fini").setValue("")
                this.formFechas.get("ffin").setValue("")
                this.formFechas.get("tiposw").setValue("")
                this.page = 1;
                this.mostrarReporte();
              }
            })
          }
        }
      })
    }
  }

  loadPage(page: number) {
    this.page = page;
    this.mostrarReporte();
  }
}
