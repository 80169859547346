import { AfterViewInit, Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Caracteristicas } from 'src/app/model/caracteristicas';
import { environment } from 'src/environments/environment';
import { Hardware } from 'src/app/model/Hardware';
import { tipoHW } from 'src/app/model/tipoHW';
import { Marcas } from 'src/app/model/marcas';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-catalogo-hardware',
  templateUrl: './catalogo-hardware.component.html',
  styleUrls: ['./catalogo-hardware.component.scss']
})
export class CatalogoHardwareComponent implements OnInit, AfterViewInit {

  private url: string = environment.api;
  public hardware: Hardware[] = [];
  public tipohw: tipoHW[] = [];
  public marcas: Marcas[] = [];
  public caracteristicas: Caracteristicas[] = [];
  public formCatHardware: FormGroup;
  public formEditaCatHardware: FormGroup;

  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  hardwareEditar: any = {
    idHardware: null,
    modelo: '',
    idTipoHw: null,
    idMarca: null,
  }

  public rolesAzure = Roles;


  @ViewChild('txtBuscaHardware', { static: false }) txtBuscaHardware!: ElementRef<HTMLInputElement>;
  @ViewChild('txtModelo', { static: false }) txtModelo!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formCatHardware = this.formBuilder.group({
      modelo: ['', [Validators.required]],
      idTipoHw: ['', [Validators.required]],
      idMarca: ['', [Validators.required]],
    });

    this.formEditaCatHardware = this.formBuilder.group({
      idHardware: ['', [Validators.required]],
      modelo: ['', [Validators.required]],
      idTipoHW: ['', [Validators.required]],
      idMarca: ['', [Validators.required]],
    });
  }
  
  ngOnInit() {
    this.muestraTipoHw()
    this.muestraMarcas()
  }
  
  ngAfterViewInit(): void { this.muestraHardware(); }

  muestraHardware() {
    this.hardware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaHardware.nativeElement.value === '') {
      this.inventarioService.getHardwareLimit(this.page).subscribe((respHardware: any) => {
        if (respHardware.data != null && respHardware.data.length > 0) {
          this.hardware = respHardware.data;
          this.totalRecords = respHardware.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
    else {
      let datoPost = {
        "nombreTabla": "hw",
        "valorBusqueda": this.txtBuscaHardware.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getHardwareTablaCampo(datoPost).subscribe((respHardware: any) => {
        if (respHardware['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.hardware = respHardware.data;
          this.totalRecords = respHardware.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  muestraTipoHw() {
    this.inventarioService.getTipoHardware().subscribe((respHardware: any) => {
      this.tipohw = respHardware.data;
    })
  }

  muestraMarcas() {
    this.inventarioService.getMarcas().subscribe((respMarcas: any) => {
      this.marcas = respMarcas.data;
    })
  }

  buscaHardwareInput() {
    let valor = this.txtBuscaHardware.nativeElement.value;
    if (valor === '') {
      this.muestraHardware();
    } else {
      let datoPost = {
        "nombreTabla": "hw",
        "valorBusqueda": valor,
        "Page": this.page
      }
      this.inventarioService.getHardwareTablaCampo(datoPost).subscribe((respHardware: any) => {
        if (respHardware['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.hardware = respHardware.data;
          this.totalRecords = respHardware.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  };


  cambioBuscaHardwareInput() {
    let valor = this.txtBuscaHardware.nativeElement.value;
    if (valor === '') {
      this.muestraHardware();
    }
  }

  guardaCatHardware(): any {
    this.inventarioService.createHardware(this.formCatHardware.value).subscribe(data => {
      if (data['result'] === 1) {
        this.formCatHardware.reset();
        this.muestraHardware();
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
        })
      }

    })
  }

  editaCatHardware(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el catálogo de hardware, aunque ya exista conexión con algún inventario",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateHardware(this.formEditaCatHardware.value).subscribe(data => {
          if (data['result'] === 1) {
            this.formEditaCatHardware.reset();
            this.muestraHardware();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
            })
          }
        })
      }
    })

  }

  abreEditaCatHardware(idHardware) {
    this.inventarioService.getHardwareXIdHw(idHardware).subscribe((respHardware: any) => {
      this.hardwareEditar = respHardware.data;
    })
  }

  noSpecialChar(char) {
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:*+?^${}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraHardware();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.muestraHardware();
        } else {
          this.page = 1;
          this.txtBuscaHardware.nativeElement.value = "";
          this.muestraHardware();
        }
      })
    }
  }
}
