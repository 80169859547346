<div class="row mb-2">
    <div class="col-xl-1 col-lg-1 col-md-0 col-sm-0 padd-perso-filter"></div>

    <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 mb-2 padd-perso-filter">
        <div class="input-group mb-2">
            <ng-select class="form-control" name="opcBuscarTipoHW" id="opcBuscarTipoHW" #opcBuscarTipoHW
                *ngIf="flagTipoHW" [options]="opcTipoHW" placeholder="Seleccione tipo de Hardware" [(ngModel)]="selectBuscarTipoHW"
            >
            </ng-select>
            <ng-select class="form-control ml-2" name="opcBuscarTipoMarcas" id="opcBuscarTipoMarcas"
                #opcBuscarTipoMarcas *ngIf="flagTipoMarca" [options]="opcTipoMarcas"
                placeholder="Seleccione tipo de Marca" [(ngModel)]="selectBuscarTipoMarcas"
            >
            </ng-select>
            <ng-select class="form-control ml-2" name="opcBuscarTipoModelos" id="opcBuscarTipoModelos"
                #opcBuscarTipoModelos *ngIf="flagTipoModelo" [options]="opcTipoModelos"
                placeholder="Seleccione tipo de Modelo" [(ngModel)]="selectBuscarTipoModelos"
            >
            </ng-select>
            <button class="btn btn-outline-secondary ml-2" (click)="buscaReporte()" type="button" *ngIf="flagTipoHW && flagTipoMarca && flagTipoModelo">
                <i class="feather icon-search"></i>
            </button>
            <button class="btn btn-outline-secondary ml-2" (click)="limpiarBusqueda()" type="button" *ngIf="!isBusquedaLimpia()">✖</button>
        </div>
    </div>

    <div class="col-xl-1 col-lg-1 col-md-0 col-sm-0 padd-perso-filter"></div>

</div>

<div class="row">
    <div class="col-8"></div>
    <div class="col-2" style="text-align:right;" *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, rolesAzure.ING_SOPORTE]">
        <button class="btn btn-primary mb-3" (click)="generatePDF()" [disabled]="cargarLoader">Generar PDF</button>
    </div>
    <div class="col-2" style="text-align:right;" *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, rolesAzure.ING_SOPORTE]">
        <button class="btn btn-primary mb-3" (click)="exportExcel()" [disabled]="cargarLoader">Generar Excel</button>
    </div>
</div>

<div style="overflow: auto;">
    <div class="table table-styling table-hover table-secundary">
        <div class="table-container-scroll">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" class="Padding-Table">Cantidad</th>
                        <th scope="col" class="Padding-Table">Tipo</th>
                        <th scope="col" class="Padding-Table">Marca</th>
                        <th scope="col" class="Padding-Table">Modelo</th>
                        <th scope="col" class="Padding-Table">Características</th>
                    </tr>
                </thead>
                <tbody *ngIf="!cargarLoader">
                    <tr *ngFor="let inv of InventaHw">
                        <td class="Padding-Table">{{inv.cantidad}}</td>
                        <td class="Padding-Table">{{inv.tipoHW}}</td>
                        <td class="Padding-Table">{{inv.marca}}</td>
                        <td class="Padding-Table">{{inv.modelo}}</td>
                        <td class="Padding-Table">{{inv.caracteristicas}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="cargarLoader">
                    <tr>
                        <td colspan="5" class="Padding-Table" style="text-align: center;">Sin Datos de Busqueda</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="d-flex justify-content-start align-items-center p-2">
    <ngb-pagination [collectionSize]="currentPage" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
        (pageChange)="loadPage($event)"
        [disabled]="InventaHw == null || InventaHw.length == 0"
    >
        <ng-template ngbPaginationPrevious>Anterior</ng-template>
        <ng-template ngbPaginationNext>Siguiente</ng-template>
    </ngb-pagination>
    <div class="d-flex align-items-center ml-3">
        <label for="pageInput" class="mr-2">Buscar página específica:</label>
        <input id="pageInput" type="number" class="form-control w-auto" #pageInput (change)="page = pageInput.value"
            (keyup.enter)="page = pageInput.value" onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
            placeholder="" aria-label="Buscar página específica" [disabled]="InventaHw == null || InventaHw.length == 0"
        />
    </div>
</div>