<div class=" shadow p-3 mb-5 bg-body rounded">
    <div style="text-align: center;">
        <span>
            <h3>Catálogo Características</h3>
        </span>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-8">
            <div class="input-group mb-3">
                <input type="text" class="form-control" (change)="cambioBuscaCaracteristicasInput()"
                    #txtBuscaCaracteristica placeholder="Buscar Característica" aria-label="Buscar Característica"
                    aria-describedby="basic-addon2" (keyup.enter)="buscaCaracteristicasInput()">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="buscaCaracteristicasInput()"><i
                            class="feather icon-search"></i></button>
                </div>
            </div>
        </div>
        <div class="col-md-2" *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">
            <div class="div-btn-nuevo-hardware">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#modalNuevaCaracteristica">
                    Agregar
                </button>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <div *ngIf="!cargarLoader">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Característica</th>
                    <th scope="col">Descripcion</th>
                    <th scope="col">Tipo Hw</th>
                    <th scope="col" *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let caracteristic of caracteristicas">
                    <td>
                        {{caracteristic.caracteristica}}
                    </td>
                    <td>
                        {{caracteristic.descripcion}}
                    </td>
                    <td>
                        {{caracteristic.tipoHW}}
                    </td>
                    <td style="text-align: center;" *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">
                        <button class="btn btn-warning"
                            (click)="abreEditaCaracteristicas(caracteristic.idCaracteristica)" data-bs-toggle="modal"
                            data-bs-target="#modalEditaCaracteristica">Editar<i
                                class="feather icon-edit iconos-acciones"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" S nextLabel="Siguiente"></pagination-controls> -->
        <div class="d-flex justify-content-start align-items-center p-2">
            <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                (pageChange)="loadPage($event)" [disabled]="caracteristicas == null || caracteristicas.length == 0">
                <ng-template ngbPaginationPrevious>Anterior</ng-template>
                <ng-template ngbPaginationNext>Siguiente</ng-template>
            </ngb-pagination>

            <div class="d-flex align-items-center ml-3">
                <label for="pageInput" class="mr-2">Buscar página específica:</label>
                <input id="pageInput" type="number" class="form-control w-auto" #pageInput
                    (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
                    onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
                    aria-label="Buscar página específica" />
            </div>
        </div>

    </div>
</div>


<div class="modal fade" id="modalNuevaCaracteristica" tabindex="-1" aria-labelledby="modalNuevaCaracteristica"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formCaracteristica" (submit)="guardaCaracteristica()">

                    <div class="form-group">
                        <label class="control-label is-required" for="nombreCar">Característica</label>
                        <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)"
                            maxlength="200" placeholder="Característica" formControlName="caracteristica">
                    </div>

                    <div class="form-group">
                        <label class="control-label is-required" for="descCar">Descripción</label>
                        <input type="text" class="form-control" id="descCar" maxlength="200"
                            (keypress)="noSpecialChar($event)" placeholder="Descripción" formControlName="descripcion">
                    </div>

                    <div class="form-group">
                        <label for="state_id" class="control-label is-required.">Tipo de Hardware</label>
                        <select class="form-control" name="tipoHwCar" id="tipoHwCar" formControlName="idTipoHw">
                            <option value="" disabled selected>Elige tipo de Hardware</option>
                            <option [value]=tipohw.idTipoHw *ngFor="let tipohw of tiposHw">{{tipohw.nombre}}</option>
                        </select>
                    </div>


                    <div class="form-group">
                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <button type="submit" [disabled]="formCaracteristica.invalid" data-bs-dismiss="modal"
                    class="btn btn-primary" (click)="guardaCaracteristica()">Guardar</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalEditaCaracteristica" tabindex="-1" aria-labelledby="modalEditaCaracteristica"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEditaCaracteristica">Editar</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditaCaracteristica" (submit)="editaCaracteristica()">
                    <input hidden="true" [(ngModel)]="caracteristicaEditar.idCaracteristica"
                        formControlName="idCaracteristica">
                    <div class="form-group">
                        <label class="control-label is-required" for="nombreCar">Característica</label>
                        <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)"
                            maxlength="200" placeholder="Característica" formControlName="caracteristica"
                            [(ngModel)]="caracteristicaEditar.caracteristica">
                    </div>

                    <div class="form-group">
                        <label class="control-label is-required" for="descCar">Descripción</label>
                        <input type="text" class="form-control" id="descCar" maxlength="200"
                            (keypress)="noSpecialChar($event)" placeholder="Descripción" formControlName="descripcion"
                            [(ngModel)]="caracteristicaEditar.descripcion">
                    </div>

                    <div class="form-group">
                        <label for="state_id" class="control-label is-required.">Tipo de Hardwares</label>
                        <select class="form-control" name="tipoHwCar" id="tipoHwCar" formControlName="idTipoHw"
                            [(ngModel)]="caracteristicaEditar.idTipoHw">
                            <option value="" disabled selected>Elige tipo de Hardware</option>
                            <option [value]=tipohw.idTipoHw *ngFor="let tipohw of tiposHw"
                                [selected]="tipohw.nombre == caracteristicaEditar.tipoHW">{{tipohw.nombre}}</option>
                        </select>
                    </div>


                    <div style="text-align: center;">
                        <button type="submit" [disabled]="formEditaCaracteristica.invalid" data-bs-dismiss="modal"
                            class="btn btn-primary">Guardar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>