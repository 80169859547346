import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit      } from '@angular/core';
import { HttpClient             } from '@angular/common/http';
import   Swal                     from 'sweetalert2';

import { Service, Task, Dependency, Resource, ResourceAssignment, ProgressItem } from 'src/app/service/app.service';
import { environment                                                           } from 'src/environments/environment';
import { Licencia                                                              } from 'src/app/model/licencia';
import { InventarioService } from 'src/app/data/service/inventario.service';

@Component({
  selector:             'app-control-licencias',
  templateUrl:          './control-licencias.component.html',
  styleUrls:           ['./control-licencias.component.scss'],
  providers:           [Service],
  preserveWhitespaces: true
})

export class ControlLicenciasComponent implements OnInit{
  isLoading = true;
  haveLicense = false;
  private url: string = environment.api;
  licencias: Licencia[] = [];
  tiposw: any[] = [];
  idTipoSWActual = 0;
  public formCatSoftware : FormGroup;
  today = Date.now();
  dateDifference = 0;
  days = 0;
  tasks: Task[] = [];
  dependencies: Dependency[];
  resources: Resource[];
  resourceAssignments: ResourceAssignment[];
  selectedRowKey = 1;
  currentTask = new Task();
  currentTaskResources: Resource[];
  currentProgress: ProgressItem[];
  
  OnSelectionChanged(e) {
    if (!this.isLoading) {
      this.processSelection();
    }
  }
  constructor(private http: HttpClient,
              service: Service, 
              private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formCatSoftware = this.formBuilder.group({
      idTipoSw: ['',],
    });

    //this.obtieneLicencias(1);
    this.dependencies = service.getDependencies();
    this.resources = service.getResources();
    this.resourceAssignments = service.getResourceAssignments();
    
  }
  ngOnInit() {
    this.muestraTipoSw();
  }

  muestraTipoSw(){
    this.inventarioService.getTipoSoftware().subscribe((respSoftware:any) =>{
       this.tiposw = respSoftware.data;
       this.isLoading = false;
    })
  }

  obtieneLicencias(idTipoSw) {
    this.haveLicense = false;
    this.isLoading = false;
    this.tasks = [];
    this.http.get(`${this.url}SoftwareDashboard/` + idTipoSw).subscribe((respLicencias: any) => {
      this.licencias = respLicencias.data;
      if (respLicencias.data) {
        this.haveLicense = true;
        this.idTipoSWActual = this.formCatSoftware.value.idTipoSw;
        this.cargaLicencias();
      } else {
        Swal.fire({
          title: "Atención",
          text: 'No se encontraron licencias del tipo de software seleccionado.',
          type: "warning",
          // timer: 2000
        })
      }
    });
  }

  cargaLicencias(){
    this.selectedRowKey = this.licencias[0].idInventarioSw;

    
    for (let index = 0; index < this.licencias.length; index++) {
      let progress:number = 0;
      if ( new Date(this.licencias[index].fechaHoraMovimiento).getTime() >= Date.now() ) {
        progress = 0;
      }else if ( Date.now() >= new Date(this.licencias[index].vencimiento).getTime() ){
        progress = 100;
      }else{
        progress = ( 100*((this.today-new Date(this.licencias[index].fechaHoraMovimiento).getTime())/(1000*60*60*24)))/((new Date(this.licencias[index].vencimiento).getTime()-new Date(this.licencias[index].fechaHoraMovimiento).getTime())/(1000*60*60*24) )
      }
      
      let lic: Task = {
        'id': this.licencias[index].idInventarioSw,
        'parentId': 0,
        'title': this.licencias[index].nombreSw,
        'start': new Date(this.licencias[index].fechaHoraMovimiento),
        'end': new Date(this.licencias[index].vencimiento),
        'progress': progress,
        'lote': this.licencias[index].lote,
        'proveedor': this.licencias[index].proveedor,
        'precioUnitario': this.licencias[index].precioUnitario,
        'dateDif':this.licencias[index].dateDif
      }
      this.tasks.push(lic);
    }
    this.processSelection();
  }

  processSelection() {
      this.currentTask = this.tasks.find(p => p.id == this.selectedRowKey);
      this.currentProgress = [
        { 'text': "completed", 'val': this.currentTask.progress },
        { 'text': "not completed", 'val': 100 - this.currentTask.progress }
      ]; //need at least two items to render a chart
      this.currentTaskResources = [];
      let taskResourceAssignments = this.resourceAssignments.filter(p => p.taskId == this.currentTask.id);
      for (var i = 0; i < taskResourceAssignments.length; i++) {
        let resource = this.resources.find(p => p.id == taskResourceAssignments[i].resourceId);
        this.currentTaskResources.push(resource);
      }

      this.currentTask.end != undefined ? this.dateDifference = this.currentTask.end.getTime() - Date.now() : null;
      this.days = this.dateDifference/(1000*60*60*24)
      this.isLoading = false;
  }

  tipoSwChange(){
    this.isLoading = true;
    this.obtieneLicencias(this.formCatSoftware.value.idTipoSw);
  }

}
