<div style="text-align: center;">
  <span>
    <h3>Reporte de Inventario de Software</h3>
  </span>
</div>
<div class="row">
  <div class="col-md-12" style="overflow: auto;">
    <div class="row">
      <div class="col-8"></div>
      <div class="col-2" style="text-align:right ;" *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
        <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
      </div>
      <div class="col-2" style="text-align:right ;" *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
        <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
      </div>
    </div>
    <div style="overflow: auto;" *ngIf="!cargarLoader">
      <div class="table-container-scroll">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="Padding-Table" scope="col">Tipo</th>
              <th class="Padding-Table" scope="col">Marca</th>
              <th class="Padding-Table" scope="col">Software</th>
              <th class="Padding-Table" scope="col">Nombre</th>
              <th class="Padding-Table" scope="col">Auxiliar</th>
              <th class="Padding-Table" scope="col">Stock</th>
              <th class="Padding-Table" scope="col">Lote</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of invSoftware">
              <td class="Padding-Table">
                {{per.tipoSW}}
              </td>
              <td class="Padding-Table">
                {{per.marca}}
              </td>
              <td class="Padding-Table">
                {{per.software}}
              </td>
              <td class="Padding-Table">
                {{per.nombreSw}}
              </td>
              <td class="Padding-Table">
                {{per.auxiliar}}
              </td>
              <td class="Padding-Table">
                {{per.stock}} de {{per.cantidad}}
              </td>
              <td class="Padding-Table">
                {{per.lote}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
          (pageChange)="loadPage($event)" [disabled]="invSoftware == null || invSoftware.length == 0">
          <ng-template ngbPaginationPrevious>Anterior</ng-template>
          <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>

        <div class="d-flex align-items-center ml-3">
          <label for="pageInput" class="mr-2">Buscar página específica:</label>
          <input id="pageInput" type="number" class="form-control w-auto" #pageInput
            (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
            onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
            aria-label="Buscar página específica" />
        </div>
      </div>
    </div>