import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit                  } from '@angular/core';
import { HttpClient                         } from '@angular/common/http';
import   Swal                                 from 'sweetalert2';

import { NuevosUsuarios, Usuario } from 'src/app/model/usuario';
import { grupoPermiso            } from 'src/app/model/grupoPermiso';
import { environment             } from 'src/environments/environment';
import { permiso                 } from 'src/app/model/permiso';

@Component({
  selector:     'app-control-usuarios',
  templateUrl:  './control-usuarios.component.html',
  styleUrls:   ['./control-usuarios.component.scss']
})
export class ControlUsuariosComponent implements OnInit {
    
  private url: string = environment.api;
  public formGrupoUsuario: FormGroup;
  public grupoPermisos: grupoPermiso[] = [];
  public UsuarioGrupoPermisos: any[] = [];
  public usuarios: Usuario[] = [];
  public page : 1;
  public permisosLista: permiso[] = [];

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
    this.formGrupoUsuario = this.formBuilder.group({
      idUsuario: ['',[Validators.required]],
      idGrupoPermiso: ['',[Validators.required]],
    });
   }

  ngOnInit() {
    this.buscaGrupoPermisos()
    this.buscaUsuarios()
    this.buscaAsignaciones()
  }

  buscaUsuarios(){
    this.http.get(`${this.url}Usuarios`).subscribe((respUsuarios:any) =>{
      this.usuarios = respUsuarios.data;
    })
  }

  buscaAsignaciones(){
    this.http.get(`${this.url}Usuarios/UsuarioGrupoPermiso`).subscribe((respUsuGrupoPermisos:any) =>{
      this.UsuarioGrupoPermisos = respUsuGrupoPermisos.data;
   })
  }

  guardaGrupoUsuario(){
    if (this.formGrupoUsuario.value['idGrupoPermiso'] == '' || this.formGrupoUsuario.value['idGrupoPermiso'] == null) {
      Swal.fire({
        title: "ERROR",
        text: 'Debe elegir el Grupo de Permisos',
        type: "warning",
        // timer: 2000
      })
    }else if (this.formGrupoUsuario.value['idUsuario'] == '' || this.formGrupoUsuario.value['idUsuario'] == null) {
      Swal.fire({
        title: "ERROR",
        text: 'Debe elegir el Usuario',
        type: "warning",
        // timer: 2000
      })
    }else{
        this.http.put(`${this.url}Usuarios/Permisos`,this.formGrupoUsuario.value).subscribe((respGrupoPermisos:any) =>{
        if (respGrupoPermisos['result'] === 1) {
          this.formGrupoUsuario.reset();
          this.buscaAsignaciones()
          // this.buscaUsuarios()
          // this.buscaGrupoPermisos()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
            // timer: 3000
          })
        }else{
          // alert('no jalo')
          Swal.fire({
            title: "ERROR",
            text: respGrupoPermisos['message'],
            type: "warning",
            // timer: 2000
          })
        }
    })
    }
    
    
  }

  buscaGrupoPermisos(){
    this.http.get(`${this.url}Usuarios/GruposPermisos`).subscribe((respGrupoPermisos:any) =>{
      this.grupoPermisos = respGrupoPermisos.data;
   })
  }

  verPermisos(perms){
    this.http.get(`${this.url}Usuarios/Permisos/list?id=`+perms).subscribe((respPerm:any) =>{
      this.permisosLista = respPerm.data;
    })
  }

}
