<div style="text-align: center;">
  <span>
    <h3>Historial de asignación de software</h3>
  </span>
</div>
<div class="row">
  <div class="col-2"></div>
  <div class="col-8" style="display: flex; justify-content: center; width: 100%; margin-top: 20px;">
    <form [formGroup]="formFechas">
      <div class="row">
        <div class="form-group" style="margin-right: 25px;">
          <label for="divIdUsuario" class="control-label">Usuario</label>
          <div class="input-group mb-3" id="divIdUsuario">
            <input type="text" class="form-control" id="idUsuario" placeholder="Usuario" formControlName="usuario"
              [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" (click)="quitarUsuario()"><i
                  class="feather icon-x"></i></button>
              <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario"
                data-bs-toggle="modal"><i class="feather icon-search"></i></button>
            </div>
            <input type="hidden" formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">
          </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
          <label for="divIdUsuario" class="control-label">Inventario</label>
          <div class="input-group mb-3" id="divIdInventario">
            <input type="text" class="form-control" id="idHardware" placeholder="Software" formControlName="hardware"
              [(ngModel)]="softwareElegido.nombreSw" disabled>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" (click)="quitarInventario()"><i
                  class="feather icon-x"></i></button>
              <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeInventario"
                data-bs-toggle="modal"><i class="feather icon-search"></i></button>
            </div>
            <input type="hidden" formControlName="idInventarioSw" [(ngModel)]="softwareElegido.idInventarioSw">
          </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
          <div class="form-group">
            <label class="control-label is-required" for="fechaInicio">Fecha Inicio</label>
            <input type="date" class="form-control" id="fechaInicio" placeholder="Fecha Inicio" formControlName="fini">
          </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
          <div class="form-group">
            <label class="control-label is-required" for="vencimiento">Fecha Fin</label>
            <input type="date" class="form-control" id="vencimiento" placeholder="Vencimiento" formControlName="ffin">
          </div>
        </div>
        <!-- Contenedor del botón centrado -->
        <div style="display: flex; justify-content: center; margin-top: 20px;">
          <div class="form-group" style="display: inline-block;">
            <button class="btn btn-md btn-primary" (click)="mostrarReporte()">Mostrar Reporte</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-2" *ngIf="!cargarLoader" class="shadow p-3 mb-5 bg-body rounded">
    <div class="row">
      <div class="col-6">
        <div style="display: flex; justify-content: center; margin-top: 20px;">
          <div style="display: inline-block;" *appHasAnyRole="[
          rolesAzure.SUPERADMIN, 
          rolesAzure.SUPERVISOR, 
          rolesAzure.ING_SOPORTE
          ]">
            <img src="assets/images/icons/pdf.svg" alt="Exportar PDF" width="32" (click)="generatePDF()"
              style="cursor: pointer;">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div style="display: flex; justify-content: center; margin-top: 20px;">
          <div style="display: inline-block;" *appHasAnyRole="[
          rolesAzure.SUPERADMIN, 
          rolesAzure.SUPERVISOR, 
          rolesAzure.ING_SOPORTE
          ]">
            <img src="assets/images/icons/excel.svg" alt="Exportar Excel" width="32" (click)="exportExcel()"
              style="cursor: pointer;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12" style="overflow: auto;" *ngIf="asignacion.length > 0">
    <div style="overflow: auto;" *ngIf="!cargarLoader">
      <div class="table-container-scroll">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="Padding-Table" scope="col">Folio Asignación</th>
              <th class="Padding-Table" scope="col">Fecha Asignación</th>
              <th class="Padding-Table" scope="col">Usuario Asignado</th>
              <th class="Padding-Table" scope="col">Marca</th>
              <th class="Padding-Table" scope="col">Tipo Software</th>
              <th class="Padding-Table" scope="col">Nombre</th>
              <th class="Padding-Table" scope="col">Auxiliar</th>
              <th class="Padding-Table" scope="col">Responsable</th>
              <th class="Padding-Table" scope="col">Movimiento</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of asignacion">
              <td class="Padding-Table">
                {{per.folioAsignacion}}
              </td>
              <td class="Padding-Table">
                {{per.fechaHoraAsignacion}}
              </td>
              <td class="Padding-Table">
                {{per.usuario}}
              </td>
              <td class="Padding-Table">
                {{per.nombreMarca}}
              </td>
              <td class="Padding-Table">
                {{per.nombre}}
              </td>
              <td class="Padding-Table">
                {{per.nombreSw}}
              </td>
              <td class="Padding-Table">
                {{per.auxiliar}}
              </td>
              <td class="Padding-Table">
                {{per.responsable}}
              </td>
              <td class="Padding-Table">
                {{per.tipoMovimiento}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
          (pageChange)="loadPage($event)" [disabled]="asignacion == null || asignacion.length == 0">
          <ng-template ngbPaginationPrevious>Anterior</ng-template>
          <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>
        <div class="d-flex align-items-center ml-3">
          <label for="pageInput" class="mr-2">Buscar página específica:</label>
          <input id="pageInput" type="number" class="form-control w-auto" #pageInput
            (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
            onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
            aria-label="Buscar página específica" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalEligeInventario" tabindex="-1" role="dialog" aria-labelledby="modalEligeInventario"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Elegir Inventario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaInventario placeholder="Buscar Inventario"
                aria-label="Buscar Inventario" aria-describedby="basic-addon2" (keyup.enter)="buscaInventario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaInventario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="col-md-12" *ngIf="buscaInv">
          <table class="table table-bordered" *ngIf="invSoftware.length > 0">
            <thead>
              <tr>
                <th scope="col">Tipo Software</th>
                <th scope="col">Nombre</th>
                <th scope="col">Auxiliar</th>
                <th scope="col">Vencimiento</th>
                <th scope="col">Temporalidad</th>
                <th scope="col">Elegir</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let det of invSoftware">
                <td>
                  {{det.software}}
                </td>
                <td>
                  {{det.nombreSw}}
                </td>
                <td>
                  {{det.auxiliar}}
                </td>
                <td>
                  {{det.vencimiento}}
                </td>
                <td>
                  {{det.temporalidad}}
                </td>
                <td style="text-align: center;">
                  <button class="btn btn-warning" (click)="eligeInventario(det.idInventarioSw)"
                    data-bs-dismiss="modal">Elegir <i class="feather icon-edit iconos-acciones"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered" *ngIf="invSoftware.length == 0">
            <thead>
              <tr>
                <th scope="col">Hardware</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col"># Serie</th>
                <th scope="col">TAG</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center" colspan="5">
                  No hay resultados
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario"
                aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="buscaUsu">
        <table class="table table-bordered" *ngIf="usuarios.length > 0">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Número</th>
              <th scope="col">Correo</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of usuarios">
              <td>
                {{per.nombreEmpleado}}
              </td>
              <td>
                {{per.numeroEmpleado}}
              </td>
              <td>
                {{per.correo}}
              </td>
              <td style="text-align: center;">
                <button class="btn btn-warning" data-bs-dismiss="modal"
                  (click)="eligeUsuario(per.numeroEmpleado)">Elegir <i
                    class="feather icon-edit iconos-acciones"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered" *ngIf="usuarios.length == 0">
          <thead>
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center" colspan="2">
                No hay resultados
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>