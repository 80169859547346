import { Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators                  } from '@angular/forms';
import { HttpClient                                          } from '@angular/common/http';
import   * as XLSX                                             from 'xlsx';
import   Swal                                                  from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { invSoftware } from 'src/app/model/invSoftware';
import { PdfService  } from 'src/app/data/service/pdf-excel/pdf.service';
import { Usuario     } from 'src/app/model/usuario';
import { Asignacion } from '../../../model/asignacion';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Component({
  selector:     'app-historial-asignacion-sw',
  templateUrl:  './historial-asignacion-sw.component.html',
  styleUrls:   ['./historial-asignacion-sw.component.scss'],
  providers:   [{ provide: LOCALE_ID, useValue: "es" }],
})
export class HistorialAsignacionSwComponent implements OnInit {

    //#region PAGINATION
    public page : number = 1;
    public pageSize: number = 10;
    public totalRecords: number = 0;
    public cargarLoader:boolean = true;
    //#endregion

  private url: string = environment.api;

  public formFechas: FormGroup;
  
  public invSoftware: invSoftware[] = [];
  public softwareElegido: any = [];
  public asignacion: Asignacion[] = [];
  public usuarios: Usuario[] = [];
  public usuarioElegido: any = []

  public buscaInv = false
  public tablaAsignaciones 
  public buscaUsu = false

  public date: Date = new Date();
  public data: any = [ ['Folio Asignación','Fecha Asignación', 'Usuario Asignado','Software','Auxiliar','Responsable','Movimiento'],];

  public rolesAzure = Roles;
  
  @ViewChild('txtBuscaInventario', {static: false}) txtBuscaInventario!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaUsuario', {static: false}) txtBuscaUsuario!:ElementRef<HTMLInputElement>;

  constructor(private _pdfService:PdfService,private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) { 
    this.formFechas = this.formBuilder.group({
      fini: ['',[Validators.required]],
      ffin: ['',[Validators.required]],
      usuario: ['',[Validators.required]],
      idUsuario: ['',[Validators.required]],
      idInventarioSw: ['',[Validators.required]],
      hardware: ['',[Validators.required]],
    });
  }
  ngOnInit() {
    this.mostrarReporte();
  }

  buscaInventario(){
    let valor = this.txtBuscaInventario.nativeElement.value;
    if (valor === '') {
      this.invSoftware.length = 0
      this.buscaInv = true
    } else {
      let datoPostInventario={
          "nombreTabla": "invsw",
          "valorBusqueda": valor,
        }
      this.inventarioService.getInvSwTablaCampo(datoPostInventario).subscribe((respInventario:any) =>{
        if (respInventario['result'] === 0) {
          this.invSoftware.length = 0
          this.buscaInv = true
        } else {
          this.buscaInv = true
          this.invSoftware = respInventario.data;
          
        }
      }) 
    }
    
  }
  
  mostrarReporte(){
    this.asignacion = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') ||  (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    }else{

      let idInv 
      if (this.formFechas.value['idInventarioSw'] == '' || this.formFechas.value['idInventarioSw'] == undefined || this.formFechas.value['idInventarioSw'] == null) {
        idInv = 0
      }else{
        idInv = this.formFechas.value['idInventarioSw']
      }

      let ubi
      if (this.formFechas.value['ubicacion'] == '' || this.formFechas.value['ubicacion'] == undefined || this.formFechas.value['ubicacion'] == null) {
        ubi = 0
      }else{
        ubi = this.formFechas.value['ubicacion']
      }

      let usu
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null || this.formFechas.value['idUsuario'] == undefined) {
        usu = 0
      }else{
        usu = this.formFechas.value['idUsuario']
      }
      
      let reporte={
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idInventarioSw": idInv,
        "idUsuario": usu,
        "idUbicacion": ubi,
        "Page": this.page
      }

      this.inventarioService.getHistorialAsignSw(reporte).subscribe((respuesta:any) =>{
        if (respuesta['result'] == 1) {
          this.asignacion = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }else{
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.mostrarReporte();
              } else {
                this.formFechas.get("fini").setValue("");
                this.formFechas.get("ffin").setValue("");
                this.formFechas.get("usuario").setValue("");
                this.formFechas.get("idUsuario").setValue("");
                this.formFechas.get("idInventarioSw").setValue("");
                this.formFechas.get("hardware").setValue("");
                this.page = 1;
                this.mostrarReporte();
              }
            })
          }
        }
      })
    }
  }
  
  buscaUsuario(){
      let valor = this.txtBuscaUsuario.nativeElement.value;
      if (valor === '') {
        this.usuarios.length = 0
        this.buscaUsu = true
      } else {
        let datoPostUsuario={
            "nombreTabla": "usr",
            "valorBusqueda": valor,
          }
          this.http.get(`${this.url}Usuarios/nombre/`+valor).subscribe((respUsuario:any) =>{
            if (respUsuario['result'] === 0) {
              this.usuarios.length = 0
              this.buscaUsu = true
             } else {
              this.buscaUsu = true
              this.usuarios = respUsuario.data;
             }
        }) 
      }
  }

  eligeInventario(idInventarioSw){
    this.softwareElegido.length = 0
    this.inventarioService.getInvSwXId(idInventarioSw).subscribe((respInventarioE:any) =>{
      this.softwareElegido = respInventarioE.data[0]
    })
  }

  eligeUsuario(idUsuario){
    this.http.get(`${this.url}Usuarios/`+idUsuario).subscribe((respUsuario:any) =>{
      this.usuarioElegido = respUsuario.data;
   })
  }

  quitarUsuario(){
    this.usuarioElegido = []
  }

  quitarInventario(){
    this.softwareElegido = []
  }

  exportExcel(){

    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') ||  (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    }else{

      let idInv
      if (this.formFechas.value['idInventarioSw'] == '' || this.formFechas.value['idInventarioSw'] == undefined || this.formFechas.value['idInventarioSw'] == null) {
        idInv = 0
      }else{
        idInv = this.formFechas.value['idInventarioSw']
      }

      let ubi
      if (this.formFechas.value['ubicacion'] == '' || this.formFechas.value['ubicacion'] == undefined || this.formFechas.value['ubicacion'] == null) {
        ubi = 0
      }else{
        ubi = this.formFechas.value['ubicacion']
      }

      let usu
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null || this.formFechas.value['idUsuario'] == undefined) {
        usu = 0
      }else{
        usu = this.formFechas.value['idUsuario']
      }
      
      let reporte={
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idInventarioSw": idInv,
        "idUsuario": usu,
        "idUbicacion": ubi,
        "Page": -1
      }
      // console.log(reporte)
      this.inventarioService.getHistorialAsignSw(reporte).subscribe((respuesta:any) =>{
        if (respuesta['result'] == 1) {
              /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(respuesta.data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, "HistorialAsignacionSoftware-"+this.date.toLocaleDateString()+".xlsx");
    /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
        }else{
          this.page = 1;
          Swal.fire({
            title: "Advertencia",
            text: respuesta['message'],
            type: "warning",
            // timer: 2000
          })
        }
      })
    }
  }

  async generatePDF() {
    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') ||  (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    }else{

      let idInv
      if (this.formFechas.value['idInventarioSw'] == '' || this.formFechas.value['idInventarioSw'] == undefined || this.formFechas.value['idInventarioSw'] == null) {
        idInv = 0
      }else{
        idInv = this.formFechas.value['idInventarioSw']
      }

      let ubi
      if (this.formFechas.value['ubicacion'] == '' || this.formFechas.value['ubicacion'] == undefined || this.formFechas.value['ubicacion'] == null) {
        ubi = 0
      }else{
        ubi = this.formFechas.value['ubicacion']
      }

      let usu
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null || this.formFechas.value['idUsuario'] == undefined) {
        usu = 0
      }else{
        usu = this.formFechas.value['idUsuario']
      }
      
      let reporte={
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idInventarioSw": idInv,
        "idUsuario": usu,
        "idUbicacion": ubi,
        "Page": -1
      }
      // console.log(reporte)
      this.inventarioService.getHistorialAsignSw(reporte).subscribe((respuesta:any) =>{
        if (respuesta['result'] == 1) {
          const tituloEncabezado = 'Reporte de Historial De Asignación de Software';
          const FileName = 'HistorialAsignacionSoftware'+this.date.toLocaleDateString();
          const embedHTML = document.getElementById('foo');
          const widths = '*';
          this._pdfService.generatePDF(respuesta.data, embedHTML,FileName, widths,tituloEncabezado);
        }else{
          this.page = 1;
          Swal.fire({
            title: "Advertencia",
            text: respuesta['message'],
            type: "warning",
            // timer: 2000
          })
        }
      })
    }
  }

  loadPage(page: number) {
    this.page = page;
    this.mostrarReporte();
  }
}

