<div style="text-align: center;">
    <span>
        <h3>Catálogo Tipo Hw</h3>
    </span>
</div>
<div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-5">
        <div class="input-group mb-3">
            <input type="text" class="form-control" (change)="cambioBuscaTipoHwInput()" maxlength="255"
                placeholder="Buscar Tipo Hw" #txtBuscaTipoHw aria-label="Buscar Tipo Hw" aria-describedby="basic-addon2"
                (keyup.enter)="buscaTipoHwInput()">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaTipoHwInput()"><i
                        class="feather icon-search"></i></button>
            </div>
        </div>
    </div>
    <div class="col-md-4"></div>
</div>
<div class="row" *appHasAnyRole="[ rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, ]">
    <div class="col-md-4"></div>
    <div class="col-md-5">
        <form [formGroup]="formTipoHw" (submit)="guardaTipoHw()">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Ingresar Tipo Hw *"
                    (keypress)="noSpecialChar($event)" aria-label="Ingresar Tipo Hw*" aria-describedby="basic-addon2"
                    formControlName="nombre">
                <div class="input-group-append">
                    <button class="btn btn-primary" [disabled]="formTipoHw.invalid">Agregar</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-4"></div>
</div>

<div class="row" *ngIf="!cargarLoader">
    <div class="col-md-2"></div>
    <div class="col-md-9">
        <div class="table-container-scroll">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th class="Padding-Table" scope="col">Tipo HW</th>
                    <th class="Padding-Table" scope="col" *appHasAnyRole="[
                        rolesAzure.SUPERADMIN, 
                        rolesAzure.SUPERVISOR, 
                        ]">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tipohw of tiposHw">
                    <td class="Padding-Table">
                        {{tipohw.nombre}}
                    </td>
                    <td class="Padding-Table" style="text-align: center;" *appHasAnyRole="[
                        rolesAzure.SUPERADMIN, 
                        rolesAzure.SUPERVISOR, 
                        ]">
                        <button class="btn btn-warning" (click)="abreEditaTipoHw(tipohw.idTipoHw)"
                            data-bs-toggle="modal" data-bs-target="#modalEditaTipo">Editar<i
                                class="feather icon-edit iconos-acciones"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-start align-items-center p-2">
            <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                (pageChange)="loadPage($event)" [disabled]="tiposHw == null || tiposHw.length == 0">
                <ng-template ngbPaginationPrevious>Anterior</ng-template>
                <ng-template ngbPaginationNext>Siguiente</ng-template>
            </ngb-pagination>
            <div class="d-flex align-items-center ml-3">
                <label for="pageInput" class="mr-2">Buscar página específica:</label>
                <input id="pageInput" type="number" class="form-control w-auto" #pageInput
                    (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
                    onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
                    aria-label="Buscar página específica" />
            </div>
        </div>
    </div>
    <div class="col-md-2"></div>
</div>

<div class="modal fade" id="modalEditaTipo" tabindex="-1" aria-labelledby="modalEditaTipo" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEditaCaracteristica">Editar</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditaTipoHw" (submit)="editaTipoHw()">
                    <input hidden="true" [(ngModel)]="tipoEditar.idTipoHw" formControlName="idTipoHw">
                    <div class="form-group">
                        <label class="control-label is-required" for="nombreCar">Tipo Hardware</label>
                        <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)"
                            maxlength="200" placeholder="Tipo Hardware" formControlName="nombre"
                            [(ngModel)]="tipoEditar.nombre">
                    </div>
                    <div style="text-align: center;">
                        <button type="submit" [disabled]="formEditaTipoHw.invalid" data-bs-dismiss="modal"
                            class="btn btn-primary">Guardar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>