import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';
import { HttpClient                               } from '@angular/common/http';
import { Injectable                               } from '@angular/core';
import   Swal                                       from 'sweetalert2';
import { InventarioService } from '../../../data/service/inventario.service';
import { Antena            } from "../../../model/antena";
import { Roles } from 'src/app/model';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-catalogo-antenas',
  templateUrl: './catalogo-antenas.component.html',
  styleUrls: ['./catalogo-antenas.component.scss']
})

export class CatalogoAntenasComponent implements OnInit {
  public formAntena : FormGroup;
  public formEditaAntena : FormGroup;
  public formNvoReceptor : FormGroup;
  public antenas: Antena[] = [];
  public page: 1 ;

  
  antenaEditar: any = {
    idAntena: null,
    ubicacion: '',
}

public rolesAzure = Roles;


  @ViewChild('txtBuscaAntena', {static: false}) txtBuscaAntena!:ElementRef<HTMLInputElement>;

  constructor( private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService){
    this.formAntena = this.formBuilder.group({
      ubicacion: ['',[Validators.required]],
      ipReceptor: ['', [Validators.required]],
      antena: ['', [Validators.required]],
    })
    
    this.formEditaAntena = this.formBuilder.group({
      idAntena: ['',[Validators.required]],
      ubicacion: ['',[Validators.required]],
      ipReceptor: ['', [Validators.required]],
      puerto: ['', [Validators.required]],
    })

    this.formNvoReceptor = this.formBuilder.group({
      ubicacionNvo: ['',[Validators.required]],
      ipReceptorNvo: ['', [Validators.required]],
      antenaNvo: ['', [Validators.required]],
    })
  }

  ngOnInit() {
    this.buscarAntenas()
  }

  buscarAntenas(){
    this.inventarioService.getAntenas(100).subscribe(respAntenas =>{
      this.antenas = respAntenas.data;      
      
    });
  }

  buscaAntenaInput(){
    let valor = this.txtBuscaAntena.nativeElement.value;
    if (valor === '') {
      this.buscarAntenas();
    } else {
      let datoPostAntena={
        "ubicacion": valor
      }
      this.inventarioService.getAntena(datoPostAntena).subscribe((respAntena: any) => {
        if (respAntena['result'] === 0) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning",
          })
         } else {
           this.page = 1
          this.antenas = respAntena.data;
         }
      });
    }
  };

  cambioBuscaAntenaInput(){
    let valor = this.txtBuscaAntena.nativeElement.value;
    if (valor === '') {
      this.page = 1;
      this.buscarAntenas();
    }
  }

  abreEditarAntena(idAntena){
    
    this.inventarioService.getAntenaxId(idAntena).subscribe(respAntenas =>{
      this.antenaEditar = respAntenas.data;
      
    });
    
    
  }
 
  nuevoReceptor(){
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {
       
      }else{
        let receptorNvo = {
          "ubicacion": this.formNvoReceptor.value['ubicacionNvo'],
          "ipReceptor": this.formNvoReceptor.value['ipReceptorNvo'],
          "puerto": this.formNvoReceptor.value['antenaNvo'],

        }
        this.inventarioService.createAntena(receptorNvo).subscribe(data => {
          
          if (data['result'] === 1) {
            this.page = 1
            this.formNvoReceptor.reset();
            this.buscarAntenas();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
            })
          }
        })
      }
    })
  }


  editaAntena(){    
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el nombre de la antena, aunque ya exista conexión de este registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {
       
      }else{
        this.inventarioService.updateAntenas(this.formEditaAntena.value).subscribe(data => {
          console.log(data);
          
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaAntena.reset();
            this.buscarAntenas();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
            })
          }
        })
      }
    })

        
  }
 
  guardaAntena(){
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se guardará como nuevo registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {
       
      }else{
        this.inventarioService.createReceptor(this.formAntena.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formAntena.reset();
            this.buscarAntenas();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
            })
          }
        });
      }
    })
  }

  noSpecialChar(char){
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }
}
