import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import pdfFonts from "../../../../assets/fonts/Arial/custom-arial"; // fonts provided for pdfmake

// Import pdfmake-wrapper and the fonts to use
import { PdfMakeWrapper, Txt, Table, Img, Columns, Line, Rect, Stack, Canvas, ITable, IText, IImg } from 'pdfmake-wrapper';

//import logo from "../../../../assets/images/logoColor.png";

//Import html2canvas para capturas de HTML
import html2canvas from 'html2canvas';
import { url } from 'inspector';
import { UploadService } from '../upload.service';

//Orientación
const orientation = 'landscape'; // 'portrait', 'landscape'
/*Se asigna el valor de la variable lineWidth que servirá para colocar el punto final
    de la linea segun sea la orientación de la página*/
const lineWidth = (orientation.toString() == 'portrait') ? 585 : 830;

PdfMakeWrapper.setFonts(pdfFonts, {
  Arial: {
    normal: 'Arial.ttf',
    bold: 'Arial.ttf',
    italics: 'Arial.ttf',
    bolditalics: 'Arial.ttf'
  },
}
);

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private uploadService: UploadService) { }

  public async generatePDF(data: any, embedHTML: HTMLElement, FileName: string, widths:any,tituloEncabezado:any) {
    PdfMakeWrapper.useFont('Arial');

    const pdf = new PdfMakeWrapper();

    this.configurationPDF(pdf, FileName);

    const logo = await new Img('../../../../assets/images/logoColor.png').fit([80, 100]).margin([15, 15, 0, 5]).build();

    this.Header(pdf, tituloEncabezado, logo);

    //Contenido del PDF

    //Se llama a la creación de la tabla en la sección del contenido del PDF
    pdf.add(this.buildTable(widths,data));
    //Fin del contenido del PDF

    this.Footer(pdf);

    // this.showPDF(pdf, embedHTML);
    pdf.create().download(FileName)
  }

  public async generatePDFWhitCaptureHTML(data: any, embedHTML: HTMLElement, FileName: string, captureHTML: any) {
    PdfMakeWrapper.useFont('Arial');

    const pdf = new PdfMakeWrapper();

    this.configurationPDF(pdf, FileName);

    const logo = await new Img('../../../../assets/images/logoColor.png').fit([80, 100]).margin([15, 15, 0, 5]).build();

    this.Header(pdf, FileName, logo);

    //Captura de HTML
    html2canvas(captureHTML, {
      scale: 2 // resolución de imagen
    }).then(async (canvas) => {
    //Contenido del PDF

    const capturaCanvas = canvas.toDataURL('image/png');
    pdf.add(await new Img(capturaCanvas).fit([100, 200]).build())

    //Se llama a la creación de la tabla en la sección del contenido del PDF
    pdf.add(this.buildTable('*',data));
    //Fin del contenido del PDF

    this.Footer(pdf);

    this.showPDF(pdf, embedHTML);
  });
  }

  public async generatePDFAsignacion(data: any, embedHTML: HTMLElement, FileName: string, motivo: string,numeroEmpleado:any,area:string,folio:string,empleado:string,tipoMov:string,fecha:string, widths:any) {
    PdfMakeWrapper.useFont('Arial');

    const pdf = new PdfMakeWrapper();
    const pipe = new DatePipe('en-US');
    //Se formatea la fecha con el pipe
    fecha = pipe.transform(fecha, 'dd/MM/yyyy h:mm:ss a', 'medium')
    this.configurationPDFAsignacion(pdf, FileName);

    const logo = await new Img('../../../../assets/images/logoColor.png').fit([120, 100]).margin([50, 15, 50, 5]).build();

    this.HeaderAltaAsignacion(pdf, logo);

    pdf.add(new Columns([
      new Txt('Número de empleado: '+numeroEmpleado).fontSize(8).alignment('left').end,
      new Txt('Area: '+area).fontSize(8).alignment('center').end,
      new Txt('Folio: '+folio).fontSize(8).margin([0, 0,0,16]).alignment('right').end,
    // new Canvas([new Line([10, 5], [lineWidth, 5]).lineWidth(.1).end]).width('*').end,
    ]).end,)
    pdf.add(new Columns([
      new Txt('Empleado: '+empleado).fontSize(8).alignment('left').end,
      new Txt('Tipo Movimiento: '+tipoMov).fontSize(8).alignment('center').end,
      new Txt('Fecha: '+fecha).fontSize(8).margin([0, 0, 0, 30]).alignment('right').end,// Left, Top, Right, Bottom
    // new Canvas([new Line([10, 5], [lineWidth, 5]).lineWidth(.1).end]).width('*').end,
    ]).end,)

    // new Canvas([new Line([10, 5], [lineWidth, 5]).lineWidth(.1).end]).width('*').end,
    //Captura de HTML
    // html2canvas(captureHTML, {
    //   scale: 2 // resolución de imagen
    // }).then(async (canvas) => {
    //Contenido del PDF

    // const capturaCanvas = canvas.toDataURL('image/png');
    // pdf.add(await new Img(capturaCanvas).fit([100, 200]).build())

    //Se llama a la creación de la tabla en la sección del contenido del PDF
    pdf.add(this.buildTable(widths,data));
    //Fin del contenido del PDF

    this.FooterAsignacion(pdf,motivo);
    

    // this.showPDF(pdf, embedHTML);
    pdf.create().download(FileName)
    // pdf.create().download(FileName)
    // let fileee = pdf.create()
    // pdf.create().uploadService.fileUpload
    // fileee.print()
    // console.log(fileee);
    // this.uploadService.fileUpload(pdf.create());
    pdf.create().getBlob((buffer) => {
      this.uploadService.fileUploadCreado(buffer,FileName);
    })
  // });
  }

  private configurationPDF(pdf: PdfMakeWrapper, FileName: string) {
    //La fuente se definió en el app.module general
    PdfMakeWrapper.useFont('Arial');

    //Información básica
    pdf.info({
      title: FileName,
      author: 'pdfmake-wrapper',
      subject: FileName,
    });
    pdf.compress(true);

    //Tamaño
    pdf.pageSize('Letter');

    pdf.pageOrientation(orientation);

    //Margenes de página
    // pdf.pageMargins([20, 80, 20, 60]); // Left, Top, Right, Bottom
    pdf.pageMargins([50, 80, 50, 60]); // Left, Top, Right, Bottom
  }

  private configurationPDFAsignacion(pdf: PdfMakeWrapper, FileName: string) {
    //La fuente se definió en el app.module general
    PdfMakeWrapper.useFont('Arial');

    //Información básica
    pdf.info({
      title: FileName,
      author: 'pdfmake-wrapper',
      subject: FileName,
    });
    pdf.compress(true);

    //Tamaño
    pdf.pageSize('Letter');

    pdf.pageOrientation('portrait');

    //Margenes de página
    // pdf.pageMargins([20, 80, 20, 60]); // Left, Top, Right, Bottom
    pdf.pageMargins([50, 80, 50, 60]); // Left, Top, Right, Bottom
  }

  private HeaderAltaAsignacion(pdf: PdfMakeWrapper, logo :IImg) {
    // debugger
    //Encabezado de PDF
    pdf.header(
      [
        logo,
        new Txt('Instituto Electoral del Estado de Guanajuato').fontSize(10).margin([120, -50,0,0]).alignment('center').end,
        new Txt('Coordinación Administrativa').fontSize(10).margin([120, 0,0,0]).alignment('center').end,
        new Txt('Movimiento').fontSize(10).margin([120, 0,0,16]).alignment('center').end,
        new Canvas([new Line([10, 5], [lineWidth, 5]).lineWidth(.1).end]).width('50%').end,
      ]
    );
  }

  private Header(pdf: PdfMakeWrapper, tituloEncabezado: string,  logo :IImg) {
    // debugger
    //Encabezado de PDF
    pdf.header(
      [
        new Columns([
          logo,
          new Txt(tituloEncabezado).bold().fontSize(15).margin([-130, 15, 15, 0]).end,
        ]).end,
        // new Txt(AreaName).fontSize(10).margin([15, 0]).alignment('left').end,
        new Canvas([new Line([10, 5], [lineWidth, 5]).lineWidth(.1).end]).width('*').end,
      ]
    );
  }

  private FooterAsignacion(pdf: PdfMakeWrapper,motivo:string) {
    //Se llama al pipe de fecha
    const pipe = new DatePipe('en-US');
    //Se formatea la fecha con el pipe
    const fechaActual = pipe.transform(Date.now(), 'dd/MM/yyyy h:mm:ss a', 'medium');

    //Footer PDF
    pdf.footer(
      function (currentPage, pageCount) {
        return new Stack([
          new Canvas([new Line([10, 0], [lineWidth, 0]).lineWidth(.1).end]).alignment('left').margin([0, -50,0,0]).end,
          new Txt('Motivo: '+motivo).fontSize(10).margin([50, 0,0,40]).alignment('left').end,

          new Canvas([new Line([10, 0], [lineWidth, 0]).lineWidth(.1).end]).alignment('left').margin([0, 5,0,0]).end,
          new Columns([
            // new Txt('Emisión: ' + fechaActual).margin([20, 0]).bold().fontSize(8).alignment('left').end,// Left, Top, Right, Bottom
            new Txt('página ' + currentPage.toString() + ' de ' + pageCount).bold().fontSize(8).margin([-50, 5,0,0]).alignment('center').end,
            // new Txt('Reporte v1.0').margin([-50, 5, 0, 0]).bold().fontSize(8).alignment('right').end,
          ]).end,
        ]).end
      }
    );
  }
  private Footer(pdf: PdfMakeWrapper) {
    //Se llama al pipe de fecha
    const pipe = new DatePipe('en-US');
    //Se formatea la fecha con el pipe
    const fechaActual = pipe.transform(Date.now(), 'dd/MM/yyyy h:mm:ss a', 'medium');

    //Footer PDF
    pdf.footer(
      function (currentPage, pageCount) {
        return new Stack([
          new Canvas([new Line([10, 0], [lineWidth, 0]).lineWidth(.1).end]).alignment('left').margin([0, 5]).end,
          new Columns([
            new Txt('Emisión: ' + fechaActual).margin([20, 0]).bold().fontSize(8).alignment('left').end,
            new Txt('página ' + currentPage.toString() + ' de ' + pageCount).bold().fontSize(8).alignment('center').end,
            new Txt('Reporte v1.0').margin([0, 0, 20, 0]).bold().fontSize(8).alignment('right').end,
          ]).end,
        ]).end
      }
    );
  }

  private showPDF(pdf: PdfMakeWrapper, embedHTML: any) {
    //Se hace el llamado para embeber el pdf dentro de una etiqueta
    var doc = pdf.create();
    var f = document.getElementById('foo');
    var callback = function (url) { embedHTML.setAttribute('src', url); }
    doc.getDataUrl(callback, doc);
  }

    //La tabla se genera y tiene funciones que van creando el estilo general
    private buildTable(widths:any, data: Array<string[]>): ITable {
      return new Table(this.toRows(data))
        .widths(widths) //*-Expande las columnas en todo el ancho disponible
        .color('white')
        .fontSize(8)
        .headerRows(1)
        .alignment('justify')
        .margin([0, 0, 30, 0])//Left, Top, Right, Bottom
        //.keepWithHeaderRows(1)
        //.dontBreakRows(false)
        .layout({
  
          fillColor: (rowIndex, node, columnIndex) => {
            if (rowIndex === 0) {
              //Color en cabecera de tabla
              return '#adb5bd';
            }
  
            //Color en filas
            return rowIndex! % 2 === 0 ? 'white' : 'white';
          },
          //Color de bordes de la tabla
          hLineColor: () => '#ced4da',
          vLineColor: () => 'white',
        }).end;
    }
    //Color de texto en filas, segun sea el color proporcionado
    private toRows(data: Array<string[]>): Array<IText[]> {
      return data.map((columns, index) => {
        const color = index % 2 === 0 ? 'black' : 'black';
        return this.styleRows(columns, color);
      });
    }
  
    private styleRows(columns: string[], color: string): IText[] {
      return columns.map((text) => new Txt(text).color(color).end);
    }
}
