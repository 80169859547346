<div style="text-align: center;">
  <span>
    <h3>Reporte de inventario de hardware</h3>
  </span>
</div>
<div class="row">
  <div class="col-md-12" style="overflow: auto;" *ngIf="!cargarLoader">
    <div class="row">
      <div class="col-8"></div>
      <div class="col-2" style="text-align:right ;" *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
        <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
      </div>
      <div class="col-2" style="text-align:right ;" *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
        <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
      </div>
    </div>
    <div class="table-container-scroll">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="Padding-Table" scope="col">Tipo</th>
            <th class="Padding-Table" scope="col">Marca</th>
            <th class="Padding-Table" scope="col">Modelo</th>
            <th class="Padding-Table" scope="col"># Inventario</th>
            <th class="Padding-Table" scope="col"># Serie</th>
            <th class="Padding-Table" scope="col">TAG</th>
            <th class="Padding-Table" scope="col">Lote</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let per of invHardware">
            <td class="Padding-Table">
              {{per.tipoHW}}
            </td>
            <td class="Padding-Table">
              {{per.marca}}
            </td>
            <td class="Padding-Table">
              {{per.modelo}}
            </td>
            <td class="Padding-Table">
              {{per.numeroInvAlm}}
            </td>
            <td class="Padding-Table">
              {{per.numeroSerie}}
            </td>
            <td class="Padding-Table">
              {{per.numeroTag}}
            </td>
            <td class="Padding-Table">
              {{per.lote}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-start align-items-center p-2">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
        (pageChange)="loadPage($event)" [disabled]="invHardware == null || invHardware.length == 0">
        <ng-template ngbPaginationPrevious>Anterior</ng-template>
        <ng-template ngbPaginationNext>Siguiente</ng-template>
      </ngb-pagination>
      <div class="d-flex align-items-center ml-3">
        <label for="pageInput" class="mr-2">Buscar página específica:</label>
        <input id="pageInput" type="number" class="form-control w-auto" #pageInput (change)="loadPage(pageInput.value)"
          (keyup.enter)="loadPage(pageInput.value)" onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
          placeholder="" aria-label="Buscar página específica" />
      </div>
    </div>
  </div>
</div>