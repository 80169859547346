import {  AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {  FormBuilder                                 } from '@angular/forms';
import {  IOption                                     } from 'ng-select';
import {  InventarioService                           } from 'src/app/data/service/inventario.service';
import {  PdfService                                  } from 'src/app/data/service/pdf-excel/pdf.service';
import {  Roles                                       } from 'src/app/model';
import { CantinventarioHW, CantInvHwResponse          } from 'src/app/model/cantInventHw';
import {  HardwareResponse                            } from 'src/app/model/Hardware';
import {  MarcasResponse                              } from 'src/app/model/marcas';
import {  TipoHWResponse                              } from 'src/app/model/tipoHW';
import    Swal                                          from 'sweetalert2';
import    * as XLSX                                     from 'xlsx';

@Component({
  selector: 'app-reporte-cant-invent-hw',
  templateUrl: './reporteCantInventHW.component.html',
  styleUrls: ['./reporteCantInventHW.component.scss']
})
export class ReporteCantInventHWComponent implements OnInit, AfterViewInit {
  @ViewChild('opcBuscarTipoHW',       {static: false}) opcBuscarTipoHW!:      any;
  @ViewChild('opcBuscarTipoMarcas',   {static: false}) opcBuscarTipoMarcas!:  any;
  @ViewChild('opcBuscarTipoModelos',  {static: false}) opcBuscarTipoModelos!: any;

  public rolesAzure = Roles;
  public date                     : Date                = new Date();

  public InventaHw?               : CantinventarioHW[]  = [];

  public opcTipoHW                : Array<IOption>      = [];
  public opcTipoMarcas            : Array<IOption>      = [];
  public opcTipoModelos           : Array<IOption>      = [];

  public flagTipoHW               : boolean             = false;
  public flagTipoMarca            : boolean             = false;
  public flagTipoModelo           : boolean             = false;

  // paginado y obtencion de datos. Y recuperacion del filtro.
  public flagRecuperaFiltroAnt    : boolean             = false;
  public pageAnterior             : number              = 1;
  public selectBuscarTipoHW       : string[]            = [];
  public selectBuscarTipoMarcas   : string[]            = [];
  public selectBuscarTipoModelos  : string[]            = [];
  public page                     : number              = 1;
  public pageSize                 : number              = 1;
  public currentPage              : number              = 0;
  public cargarLoader             : boolean             = true;

  buscarTipoHW() {
    this.inventarioService.getTipoHardware().subscribe((response:TipoHWResponse) =>{
      response.data.forEach(tipo => { this.opcTipoHW.push({ value: tipo.idTipoHw.toString(), label: tipo.nombre }) });
      this.flagTipoHW = true;
    });
  }

  buscarTipoMarcas() {
    this.inventarioService.getMarcas().subscribe((response: MarcasResponse) => {
      response.data.forEach(tipo => { this.opcTipoMarcas.push({ value: tipo.idMarca.toString(), label: tipo.nombreMarca }) });
      this.flagTipoMarca = true;
    });
  }

  buscarTipoModelos() {
    this.inventarioService.getHardware().subscribe((response: HardwareResponse) => {
      response.data.forEach(tipo => { this.opcTipoModelos.push({ value: tipo.idHardware.toString(), label: tipo.modelo }) });
      this.flagTipoModelo = true;
    });
  }

  //#region
  LimpiarStorageFiltro() : void {
    // Limpia Filtro en LocalStorage
    localStorage.setItem('opcBuscarTipoHW',       "");
    localStorage.setItem('opcBuscarTipoMarcas',   "");
    localStorage.setItem('opcBuscarTipoModelos',  "");
    localStorage.setItem('dataAnterior',          "");
  }

  GuardarStorageFiltro() : void {
    // Guarda Filtro en LocalStorage
    localStorage.setItem('opcBuscarTipoHW',       this.opcBuscarTipoHW?._value[0]       ??  ""  );
    localStorage.setItem('opcBuscarTipoMarcas',   this.opcBuscarTipoMarcas?._value[0]   ??  ""  );
    localStorage.setItem('opcBuscarTipoModelos',  this.opcBuscarTipoModelos?._value[0]  ??  ""  );
    localStorage.setItem('dataAnterior',          this.InventaHw.length.toString()              );
    this.flagRecuperaFiltroAnt = false;
  }

  RecuperarValoresStorage() : void {
    // Valida si hay datos guardados en el LocalStorage
    if (  localStorage.getItem('opcBuscarTipoHW')       != "" ) {
      this.selectBuscarTipoHW             = [ localStorage.getItem('opcBuscarTipoHW')         ];
      this.opcBuscarTipoHW?._value.push(      localStorage.getItem('opcBuscarTipoHW')         );
    }
    if (  localStorage.getItem('opcBuscarTipoMarcas')   != "" ) {
      this.selectBuscarTipoMarcas         = [ localStorage.getItem('opcBuscarTipoMarcas')     ];
      this.opcBuscarTipoMarcas?._value.push(  localStorage.getItem('opcBuscarTipoMarcas')     );
    }
    if (  localStorage.getItem('opcBuscarTipoModelos')  != "" ) {
      this.selectBuscarTipoModelos     = [ localStorage.getItem('opcBuscarTipoModelos') ];
      this.opcBuscarTipoModelos?._value.push( localStorage.getItem('opcBuscarTipoModelos') );
    }

    if ( this.flagRecuperaFiltroAnt ) this.page = this.pageAnterior;
  }

  ValidaStorageVacioFiltro() : boolean {
    if (  localStorage.getItem('opcBuscarTipoHW')       != "") return false;
    if (  localStorage.getItem('opcBuscarTipoMarcas')   != "") return false;
    if (  localStorage.getItem('opcBuscarTipoModelos')  != "") return false;

    return true;
  }
  //#endregion

  ngOnInit(): void {
    this.buscarTipoHW();
    this.buscarTipoMarcas();
    this.buscarTipoModelos();
  }
  
  ngAfterViewInit(): void {
    // Limpia Filtro en LocalStorage
    this.LimpiarStorageFiltro();
    this.getInventarioReporte();
  }

  constructor( private formBuilder:FormBuilder, private inventarioService:InventarioService, private _pdfService: PdfService ) {}

  buscaReporte() { this.page = 1; this.getInventarioReporte(); }

  isBusquedaLimpia(): boolean {
    return this.opcBuscarTipoHW?._value[0]      === undefined
        && this.opcBuscarTipoMarcas?._value[0]  === undefined
        && this.opcBuscarTipoModelos?._value[0] === undefined
  }

  getInventarioReporte() {
    this.InventaHw    = [];
    this.currentPage  = 0;
    this.cargarLoader = true;
    // Valida si hay datos guardados en el LocalStorage
    if ( this.flagRecuperaFiltroAnt ) this.RecuperarValoresStorage();

    let datoPostInvHard = {
      "nombreTabla": "",
      "valorBusqueda": "",
      "idTipoHW": this.opcBuscarTipoHW?._value[0] != undefined ? parseInt(this.opcBuscarTipoHW?._value[0]) : null,
      "idTipoMarca": this.opcBuscarTipoMarcas?._value[0] != undefined ? parseInt(this.opcBuscarTipoMarcas?._value[0]) : null,
      "idTipoModelo": this.opcBuscarTipoModelos?._value[0] != undefined ? parseInt(this.opcBuscarTipoModelos?._value[0]) : null,
      "Page": this.page
    }
    this.inventarioService.getReporteCantidadInventarioHw(datoPostInvHard).subscribe((respInvSoft: CantInvHwResponse) => {
      if (respInvSoft['result'] === 0) this.funcionErrorPaginacion();
      else {
        this.InventaHw = respInvSoft.data;
        this.currentPage = respInvSoft.pages;
        this.pageAnterior = this.page;
        this.cargarLoader = false;

        // Guarda Filtro en LocalStorage
        this.GuardarStorageFiltro();
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      });
    });
  }

  limpiarBusqueda( noLimpiarStorage?: boolean ) {
    this.opcBuscarTipoHW.clear();
    this.opcBuscarTipoMarcas.clear();
    this.opcBuscarTipoModelos.clear();
    this.page                     = 1;
    this.pageSize                 = 1;
    this.currentPage              = 0;
    this.selectBuscarTipoHW       = [];
    this.selectBuscarTipoMarcas   = [];
    this.selectBuscarTipoModelos  = [];
    this.InventaHw                = [];
    this.cargarLoader             = true;
    if(!noLimpiarStorage) {
      this.LimpiarStorageFiltro();
      this.flagRecuperaFiltroAnt  = false;
    }
    this.getInventarioReporte();
  }

  loadPage(page: number) {
    this.page = page;
    this.getInventarioReporte();
  }

  funcionErrorPaginacion(){
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        this.flagRecuperaFiltroAnt = true;
        if ( this.page != 1 ) this.loadPage(this.pageAnterior);
        else{
          if( !this.ValidaStorageVacioFiltro() || parseInt( localStorage.getItem('dataAnterior') ) > 0 ) this.limpiarBusqueda(true);
        }
      })
    }
  }

  exportExcel() {
    if(!this.cargarLoader) {
      // if (this.opcBuscarTipoHW?._value[0]       !== undefined
      //   || this.opcBuscarTipoMarcas?._value[0]  !== undefined
      //   || this.opcBuscarTipoModelos?._value[0] !== undefined
      // ) {
        let datoPostInvHard = {
          "nombreTabla"   : "",
          "valorBusqueda" : "",
          "idTipoHW"      : this.opcBuscarTipoHW?._value[0]       != undefined ? parseInt(this.opcBuscarTipoHW?._value[0])      : null,
          "idTipoMarca"   : this.opcBuscarTipoMarcas?._value[0]   != undefined ? parseInt(this.opcBuscarTipoMarcas?._value[0])  : null,
          "idTipoModelo"  : this.opcBuscarTipoModelos?._value[0]  != undefined ? parseInt(this.opcBuscarTipoModelos?._value[0]) : null,
          "Page"          : -1
        }
        this.inventarioService.getReporteCantidadInventarioHw(datoPostInvHard).subscribe((respuesta: any) => {
          if (respuesta['result'] == 1) {
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, "ReporteCantidadInventarioHardware-" + this.date.toLocaleDateString() + ".xlsx");
          } else {
            Swal.fire({
              title: "Advertencia",
              text: respuesta['message'],
              type: "warning",
              // timer: 2000
            })
          }
        }, error => {
          Swal.fire({
            title: "ERROR",
            text: `Error ${error}`,
            type: "warning"
          })
        });
      // }
    }
  }

  async generatePDF() {
    if(!this.cargarLoader) {
      // if (this.opcBuscarTipoHW?._value[0]       !== undefined
      //   || this.opcBuscarTipoMarcas?._value[0]  !== undefined
      //   || this.opcBuscarTipoModelos?._value[0] !== undefined
      // ) {
        let datoPostInvHard = {
          "nombreTabla"   : "",
          "valorBusqueda" : "",
          "idTipoHW"      : this.opcBuscarTipoHW?._value[0]       != undefined ? parseInt(this.opcBuscarTipoHW?._value[0])      : null,
          "idTipoMarca"   : this.opcBuscarTipoMarcas?._value[0]   != undefined ? parseInt(this.opcBuscarTipoMarcas?._value[0])  : null,
          "idTipoModelo"  : this.opcBuscarTipoModelos?._value[0]  != undefined ? parseInt(this.opcBuscarTipoModelos?._value[0]) : null,
          "Page"          : -1
        }
        this.inventarioService.getReporteCantidadInventarioHw(datoPostInvHard).subscribe((respuesta: any) => {
          if (respuesta['result'] == 1) {
            const tituloEncabezado = 'Reporte de la Cantidad del Inventario de Hardware';
            const FileName = 'CantidadInventarioHardware' + this.date.toLocaleDateString();
            const embedHTML = document.getElementById('foo');
            const widths = '*';
            this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
          } else {
            Swal.fire({
              title: "Advertencia",
              text: respuesta['message'],
              type: "warning",
              // timer: 2000
            })
          }
        }, error => {
          Swal.fire({
            title: "ERROR",
            text: `Error ${error}`,
            type: "warning"
          })
        });
      // }
    }
  }
}