import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { tipoHW } from '../../../model/tipoHW';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-catalogo-tipo-hw',
  templateUrl: './catalogo-tipo-hw.component.html',
  styleUrls: ['./catalogo-tipo-hw.component.scss']
})
export class CatalogoTipoHwComponent implements OnInit, AfterViewInit {
  public formTipoHw: FormGroup;
  public formEditaTipoHw: FormGroup;
  public tiposHw: tipoHW[] = [];

  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  tipoEditar: any = {
    idTipoHw: null,
    nombre: '',
  }

  public rolesAzure = Roles;

  @ViewChild('txtBuscaTipoHw', { static: false }) txtBuscaTipoHw!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formTipoHw = this.formBuilder.group({
      nombre: ['', [Validators.required]],
    })
    this.formEditaTipoHw = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      idTipoHw: ['', [Validators.required]],
    })
  }

  ngOnInit() { }

  ngAfterViewInit(): void { this.buscarTipoHwLimit() }

  buscarTipoHwLimit() {
    this.tiposHw = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaTipoHw.nativeElement.value === '') {
      this.inventarioService.getTipoHardwareLimit(this.page).subscribe((respTiposHw: any) => {
        if (respTiposHw.data != null && respTiposHw.data.length > 0) {
          this.tiposHw = respTiposHw.data;
          this.totalRecords = respTiposHw.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    } else {
      let datoPostTipoHw = {
        "nombreTabla": "thw",
        "valorBusqueda": this.txtBuscaTipoHw.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getTipoHardwareTablaCampo(datoPostTipoHw).subscribe((respTiposHw: any) => {
        if (respTiposHw['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.tiposHw = respTiposHw.data;
          this.totalRecords = respTiposHw.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  abreEditaTipoHw(idTipo) {
    this.inventarioService.getTipoHardwarexId(idTipo).subscribe((respTiposHw: any) => {
      this.tipoEditar = respTiposHw.data;
    })
  }

  buscaTipoHwInput() {
    if (this.txtBuscaTipoHw.nativeElement.value === '') { this.buscarTipoHwLimit(); }
    else {
      let datoPostTipoHw = {
        "nombreTabla": "thw",
        "valorBusqueda": this.txtBuscaTipoHw.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getTipoHardwareTablaCampo(datoPostTipoHw).subscribe((respTiposHw: any) => {
        if (respTiposHw['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.tiposHw = respTiposHw.data;
          this.totalRecords = respTiposHw.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }


  editaTipoHw(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el nombre del tipo de hardware, aunque ya exista conexión con algún hardware",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateTipoHardware(this.formEditaTipoHw.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaTipoHw.reset();
            this.buscarTipoHwLimit();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }
    })


  }

  guardaTipoHw(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se guardará como nuevo registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.createTipoHardware(this.formTipoHw.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formTipoHw.reset();
            this.buscarTipoHwLimit();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }
    })

  }


  cambioBuscaTipoHwInput() {
    let valor = this.txtBuscaTipoHw.nativeElement.value;
    if (valor === '') {
      this.buscarTipoHwLimit();
    }
  }

  noSpecialChar(char) {
    const onlyspecial = /[°¬"_&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.buscarTipoHwLimit();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.buscarTipoHwLimit();
        } else {
          this.txtBuscaTipoHw.nativeElement.value = "";
          this.page = 1;
          this.buscarTipoHwLimit();
        }
      })
    }
  }
}
