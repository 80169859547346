import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Marcas } from '../../../model/marcas';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-catalogo-marcas',
  templateUrl: './catalogo-marcas.component.html',
  styleUrls: ['./catalogo-marcas.component.scss']
})
export class CatalogoMarcasComponent implements OnInit, AfterViewInit {
  public formMarca: FormGroup;
  public formEditaMarca: FormGroup;
  private url: string = environment.api;
  public marcas: Marcas[] = [];

  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  marcaEditar: any = {
    idMarca: null,
    nombreMarca: '',
  }
  
  public rolesAzure = Roles;

  @ViewChild('txtBuscaMarca', { static: false }) txtBuscaMarca!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formMarca = this.formBuilder.group({
      nombreMarca: ['', [Validators.required]],
    })

    this.formEditaMarca = this.formBuilder.group({
      idMarca: ['', [Validators.required]],
      nombreMarca: ['', [Validators.required]],
    })
  }
  ngOnInit() { }

  ngAfterViewInit(): void { this.buscarMarcas() }

  buscarMarcas() {
    this.marcas = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaMarca.nativeElement.value === '') {
      this.inventarioService.getMarcasLimit(this.page).subscribe((respMarcas: any) => {
        if (respMarcas.data != null && respMarcas.data.length > 0) {
          this.marcas = respMarcas.data;
          this.totalRecords = respMarcas.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
    else {
      let datoPostMarca = {
        "nombreTabla": "marca",
        "valorBusqueda": this.txtBuscaMarca.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getMarcasTablaCampo(datoPostMarca).subscribe((respMarcas: any) => {
        if (respMarcas['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.marcas = respMarcas.data;
          this.totalRecords = respMarcas.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  buscaMarcasInput() {
    let valor = this.txtBuscaMarca.nativeElement.value;
    if (valor === '') {
      this.buscarMarcas();
    } else {
      let datoPostMarca = {
        "nombreTabla": "marca",
        "valorBusqueda": valor,
        "Page": this.page
      }
      this.inventarioService.getMarcasTablaCampo(datoPostMarca).subscribe((respMarcas: any) => {
        if (respMarcas['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.marcas = respMarcas.data;
          this.totalRecords = respMarcas.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  };

  guardaMarca(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se guardará como nuevo registro",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.createMarca(this.formMarca.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formMarca.reset();
            this.buscarMarcas();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }
    })

  }

  editaMarca(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el nombre de la marca, aunque ya exista conexión con algun equipo",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateMarca(this.formEditaMarca.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaMarca.reset();
            this.buscarMarcas();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }
    })

  }

  cambioBuscaMarcaInput() {
    let valor = this.txtBuscaMarca.nativeElement.value;
    if (valor === '') {
      this.buscarMarcas();
    }
  }

  abreEditaMarca(idMarca) {
    this.inventarioService.getMarcasxId(idMarca).subscribe((respMarcas: any) => {
      this.marcaEditar = respMarcas.data;

    })
  }

  noSpecialChar(char) {
    const onlyspecial = /[°¬_"@=¿¨´~`',;:*+?^${}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.buscarMarcas();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.buscarMarcas();
        } else {
          this.txtBuscaMarca.nativeElement.value = "";
          this.page = 1;
          this.buscarMarcas();
        }
      })
    }
  }
}
