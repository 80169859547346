import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appHasAnyRole]',
})
export class HasAnyRoleDirective {
  private rolesLocalStorage: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.loadRolesFromLocalStorage(); // Cargar los roles al inicializar la directiva
  }

  @Input() set appHasAnyRole(rolesAzure: string[]) {    // Verifica si el usuario tiene al menos uno de los roles del arreglo
    const hasRole = rolesAzure.some(role => this.hasRole(role));

    if (hasRole) {
      this.viewContainer.createEmbeddedView(this.templateRef); // Muestra el contenido
    } else {
      this.viewContainer.clear(); // Oculta el contenido si el usuario no tiene ninguno de los roles
    }
  }

  // Cargar los roles desde el localStorage
  private loadRolesFromLocalStorage() {
    const storedRoles = localStorage.getItem('roles');
    if (storedRoles) {
      this.rolesLocalStorage = JSON.parse(storedRoles); // Parsear el JSON a un array de roles
    }
  }

  // Verificar si el rol existe en los roles del localStorage
  private hasRole(role: string): boolean {
    return this.rolesLocalStorage.includes(role);
  }
}
