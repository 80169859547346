import { AfterViewInit, Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

import { invHardware } from 'src/app/model/invHArdware';
import { invSoftware } from 'src/app/model/invSoftware';
import { environment } from 'src/environments/environment';
import { Usuario } from 'src/app/model/usuario';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-re-asignacion-software',
  templateUrl: './re-asignacion-software.component.html',
  styleUrls: ['./re-asignacion-software.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})
export class ReAsignacionSoftwareComponent implements AfterViewInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  private url: string = environment.api;
  private idResponsable = localStorage.getItem('employeeId');
  public formAsignacionSw: FormGroup;
  public invSoftware: invSoftware[] = [];
  public invHardware: invHardware[] = [];
  public usuarios: Usuario[] = [];
  public asignacionesSw: any = [];
  public softwareReasignar: any[] = [];
  public hayInvSw = false
  public buscaInv = false
  public buscaInvHw = false
  public buscaUsu = false
  public ubicacion: any[] = [];
  public btnAsignarSw = true
  public idUsuarioAsignacionExistente

  public rolesAzure = Roles;

  softwareElegido: any = []

  usuarioElegido: any = {
    idUsuario: null,
    usuario: '',
  }
  indexactual: number = null;

  @ViewChild('txtBuscaUsuario', { static: false }) txtBuscaUsuario!: ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaInventario', { static: false }) txtBuscaInventario!: ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaInventarioHw', { static: false }) txtBuscaInventarioHw!: ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaAsignacion', { static: false }) txtBuscaAsignacion!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formAsignacionSw = this.formBuilder.group({
      usuario: ['', [Validators.required]],
      idUsuario: ['', [Validators.required]],
      idUbicacion: ['', [Validators.required]],
      // motivo: ['',[Validators.required]],
      // idInventarioHw: ['',[Validators.required]],
    })
  }

  ngAfterViewInit(): void { this.muestraAsignaciones(); }

  muestraAsignaciones() {
    this.asignacionesSw = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    let rep = {
      "page": this.page
    }

    if (this.txtBuscaAsignacion.nativeElement.value === '') {
      this.inventarioService.getAsignacionesSw(rep).subscribe((respAsignaciones: any) => {
        if (respAsignaciones.data.length > 0) {
          this.asignacionesSw = respAsignaciones.data;
          this.totalRecords = respAsignaciones.totalRecords;
          this.cargarLoader = false;
        }
        else {
          this.funcionErrorPaginacion()
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    }
    else {
      let datoPost = {
        "nombreTabla": "asigsw",
        "valorBusqueda": this.txtBuscaAsignacion.nativeElement.value,
        "Page": this.page
      }

      this.inventarioService.asignacionSwTablaCampo(datoPost).subscribe((respuesta: any) => {

        if (respuesta['result'] === 0) {
          this.funcionErrorPaginacion()
        } else {
          this.asignacionesSw = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }
      })
    }
  }

  buscaReAsignacionInput() {
    if (this.txtBuscaAsignacion.nativeElement.value !== '') { this.page = 1; this.muestraAsignaciones(); }
  }

  cambioReAsignacionInput() {
    if (this.txtBuscaAsignacion.nativeElement.value === '') { this.page = 1; this.muestraAsignaciones(); }
  }

  guardaReAsignacionSw() {
    if (this.formAsignacionSw.value['idUsuario'] == this.idUsuarioAsignacionExistente) {
      Swal.fire({
        title: "ERROR",
        text: 'No se puede reasignar al usuario que ya esta asignado',
        type: "warning",
      }).then(() => {
        let element: HTMLElement = document.getElementsByClassName('btnReasignar')[0] as HTMLElement;
        element.click()
      })
    } else {
      let datoPost = {
        "idUsuario": this.formAsignacionSw.value['idUsuario'],
        "idUbicacion": this.formAsignacionSw.value['idUbicacion'],
        "idResponsable": this.idResponsable,
        "detalleAsignaciones": this.softwareElegido,
      }

      this.inventarioService.createAsignacionSw(datoPost).subscribe(data => {
        if (data['result'] === 1) {
          this.softwareElegido.length = 0
          // this.usuarioElegido = 0
          this.page = 1
          this.formAsignacionSw.reset();
          this.muestraAsignaciones();
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
          })
        } else {
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
          }).then(() => {
            let element: HTMLElement = document.getElementsByClassName('btnReasignar')[0] as HTMLElement;
            element.click()
          })
        }
      })
    }
  }

  clicReasignar(idInventarioSw, idAsignacion, idUsuario) {
    this.idUsuarioAsignacionExistente = idUsuario
    this.softwareElegido = []
    this.buscarUbicaciones()
    this.inventarioService.getInvSwXId(idInventarioSw).subscribe((respInventarioE: any) => {

      let inve = {
        "idAsignacion": idAsignacion,
        "idInventarioSw": respInventarioE.data[0]['idInventarioSw'],
        "idInventarioHw": null,
        "nombreSw": respInventarioE.data[0]['nombreSw'],
        "software": respInventarioE.data[0]['software'],
        "temporalidad": respInventarioE.data[0]['temporalidad'],
        "auxiliar": respInventarioE.data[0]['auxiliar'],
      }

      this.softwareElegido.push(inve)
      this.hayInvSw = true
      this.validaAsignacion()
    })
  }


  clicAgregarAsignacion() {
    this.buscarUbicaciones()
  }

  buscarUbicaciones() {
    this.inventarioService.getUbicaciones().subscribe((respUbicaciones: any) => {
      this.ubicacion = respUbicaciones.data;
    })
  }

  buscaUsuario() {
    let valor = this.txtBuscaUsuario.nativeElement.value;
    if (valor === '') {
      this.usuarios.length = 0
      this.buscaUsu = true
    } else {
      let datoPostUsuario = {
        "nombreTabla": "usr",
        "valorBusqueda": valor,
      }
      this.http.get(`${this.url}Usuarios/nombre/` + valor).subscribe((respUsuario: any) => {
        if (respUsuario['result'] === 0) {
          this.usuarios.length = 0
          this.buscaUsu = true
        } else {
          this.buscaUsu = true
          this.usuarios = respUsuario.data;
        }
      })
    }
  }

  eligeHardware(idInventarioHw) {
    this.softwareElegido[0].idInventarioHw = idInventarioHw
    this.validaAsignacion()
  }

  eligeUsuario(idUsuario) {
    this.http.get(`${this.url}Usuarios/` + idUsuario).subscribe((respUsuario: any) => {
      this.usuarioElegido = respUsuario.data;
      this.validaAsignacion()
    })
  }

  buscaInventario() {
    let valor = this.txtBuscaInventario.nativeElement.value;
    if (valor === '') {
      this.invSoftware.length = 0
      this.buscaInv = true
    } else {
      let datoPostInventario = {
        "nombreTabla": "invsw",
        "valorBusqueda": valor,
      }
      this.inventarioService.getInvSwTablaCampo(datoPostInventario).subscribe((respInventario: any) => {

        if (respInventario['result'] === 0) {
          this.invSoftware.length = 0
          this.buscaInv = true
        } else {
          this.buscaInv = true
          this.invSoftware = respInventario.data;
        }
      })
    }

  }

  buscaHardware() {
    let valor = this.txtBuscaInventarioHw.nativeElement.value;
    if (valor === '') {
      // this.invHardware.length = 0
      this.buscaInvHw = true
    } else {
      let datoPostInventario = {
        "nombreTabla": "invhw",
        "valorBusqueda": valor,
      }
      this.inventarioService.getInvHwTablaCampo(datoPostInventario).subscribe((respInventario: any) => {
        if (respInventario['result'] === 0) {
          this.invHardware.length = 0
          this.buscaInvHw = true
        } else {
          this.buscaInvHw = true
          this.invHardware = respInventario.data;
        }
      })
    }
  }

  abreAgregarHardware(index) {
    this.indexactual = index
  }

  eliminarSoft(i) {
    this.softwareElegido.splice(i, 1)
    this.validaAsignacion()
  }

  validaAsignacion() {
    if (this.softwareElegido.length > 0 && this.usuarioElegido != '' && this.formAsignacionSw.valid) {
      this.btnAsignarSw = false
    }

  }

  loadPage(page: number) {
    this.page = page;
    this.muestraAsignaciones();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.muestraAsignaciones();
        } else {
          this.txtBuscaAsignacion.nativeElement.value = "";
          this.page = 1;
          this.muestraAsignaciones();
        }
      })
    }
  }

}
