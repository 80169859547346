<!-- <div class="container"> -->
    <div style="text-align: center;">
        <h1>Asignación de grupo de permisos a usuarios</h1>
    </div>



<form [formGroup]="formGrupoUsuario" (submit)="guardaGrupoUsuario()">
    <div class="row">
            <div class="col-6">
                <div class="form-group"> 
                    <label for="idUsuario" class="control-label">Usuario</label>
                    <select class="form-control" name="idUsuario" id="idUsuario" formControlName="idUsuario">
                        <option value="" disabled selected>Elige el usuario</option>
                        <option [value]=usu.idUsuario *ngFor="let usu of usuarios">{{usu.usuario}}</option> 
                    </select>                    
                </div>
            </div>
            <div class="col-6">
                <div class="form-group"> 
                     <label for="idGrupoPermiso" class="control-label">Grupo de permisos</label>
                    <select class="form-control" name="idGrupoPermiso" id="idGrupoPermiso" formControlName="idGrupoPermiso">
                        <option value="" disabled selected>Elige el grupo de permisos</option>
                         <option [value]=gp.idGrupoPermiso *ngFor="let gp of grupoPermisos">{{gp.alias}}</option>
                    </select>                  
                </div> 
            </div>
    </div>
    <div class="row">
        <div class="col-4"></div>
        <div class="col-4" style="text-align: center;">
            <button class="btn-lg btn-success" style="margin-left: 5px;">Asignar</button>
        </div>
        <div class="col-4"></div>
    </div>
</form>
<!-- </div> -->


<table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Usuario</th>
        <th scope="col">Grupo Permisos</th>
        <th scope="col">Permisos</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let usu of UsuarioGrupoPermisos | paginate: { itemsPerPage: 10, currentPage: page } ">
        <td>
                {{usu.usuario}}
        </td>
        <td>
                {{usu.alias}}
        </td>
        <td style="text-align: center;">
            <button class="btn btn-primary" (click)="verPermisos(usu.idGrupoPermiso)" data-bs-toggle="modal" data-bs-target="#modalPermisos">Ver   <i class="feather icon-eye"></i></button>
         </td>
    </tbody>
  </table>
  <pagination-controls (pageChange)="page = $event" previousLabel="Anterior"S nextLabel="Siguiente"></pagination-controls>

    <!-- Modal -->
    <div class="modal fade" id="modalPermisos" tabindex="-1" aria-labelledby="modalPermisos" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalNuevaCaracteristica">Permisos del grupo</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body"> 
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <!-- <th scope="col">ID</th> -->
                    <th scope="col">Permiso</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of permisosLista">
                      <!-- <td>
                              {{p.idPermiso}}
                      </td> -->
                      <td>
                              {{p.nombrePermiso}}
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    