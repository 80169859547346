import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { Usuario } from 'src/app/model/usuario';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Asignacion } from '../../../model/asignacion';
import { Roles } from 'src/app/model';

@Component({
  selector:     'app-desasignacion-sw',
  templateUrl:  './desasignacion-sw.component.html',
  styleUrls:   ['./desasignacion-sw.component.scss']
})
export class DesasignacionSwComponent implements OnInit {
   //#region PAGINATION
   public page : number = 1;
   public pageSize: number = 10;
   public totalRecords: number = 0;
   public cargarLoader:boolean = true;
   //#endregion

  private idResponsable = localStorage.getItem('employeeId');
  private url: string = environment.api;
  public buscaUsu = false
  public usuarios: Usuario[] = [];
  usuarioElegido: any = []
  public formUsu: FormGroup;
  public asignaciones: Asignacion[] = [];
  public checks=[];
  public bandVerAsig = false
  public formBaja : FormGroup;
  public date: Date = new Date();
  public dataa: any = [ ['Equipo','# Serie', '# TAG','# Inventario','Caracteristicas'],];

  public rolesAzure = Roles;
  
  @ViewChild('txtBuscaUsuario', {static: false}) txtBuscaUsuario!:ElementRef<HTMLInputElement>;

  constructor(private _pdfService:PdfService, private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) { 
    this.formUsu = this.formBuilder.group({
      fini: ['',[Validators.required]],
      ffin: ['',[Validators.required]],
      usuario: ['',[Validators.required]],
      idUsuario: ['',[Validators.required]],
      ubicacion: ['',[Validators.required]],
    });
    
    this.formBaja = this.formBuilder.group({
      idInventarioSw: ['',[Validators.required]],
      motivoBaja: ['',[Validators.required]],
    })
  }

  ngOnInit() {
  }

  buscaUsuario(){
      let valor = this.txtBuscaUsuario.nativeElement.value;
      if (valor === '') {
        this.usuarios.length = 0
        this.buscaUsu = true
      } else {
        let datoPostUsuario={
            "nombreTabla": "usr",
            "valorBusqueda": valor,
          }
          this.http.get(`${this.url}Usuarios/nombre/`+valor).subscribe((respUsuario:any) =>{
            if (respUsuario['result'] === 0) {
              this.usuarios.length = 0
              this.buscaUsu = true
             } else {
              this.buscaUsu = true
              this.usuarios = respUsuario.data;
             }
        }) 
      }
  }

  eligeUsuario(idUsuario){
    this.http.get(`${this.url}Usuarios/`+idUsuario).subscribe((respUsuario:any) =>{
      this.usuarioElegido = respUsuario.data;      
   })
  }

  quitarUsuario(){
    // this.usuarioElegido.splice(0, this.usuarioElegido.length);
    this.usuarioElegido = []
    // this.usuarioElegido.length = 0
  }
  
  cambioCheck(idAsignacion,check){
    if (check == true) {
      this.checks.push(idAsignacion)
    } else {
      const filteredLibraries = this.checks.filter((item) => item !== idAsignacion)
      this.checks = filteredLibraries
    }    
  }

  verInventario(){

    this.asignaciones = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    // if (this.usuarioElegido['idUsuario'] == '') {
    if (this.usuarioElegido.length == 0) {
      Swal.fire({
          title: "ERROR",
          text: "Debe elegir un usuario",
          type: "warning",
              // timer: 2000
      })
    } else {
      this.bandVerAsig = true
      let reporte={
        "fini": '',
        "ffin": '',
        "idUsuario": this.usuarioElegido['numeroEmpleado'],
        "idUbicacion": 0,
        "page": this.page
      }
      // console.log(reporte)
      this.inventarioService.getAsignacionesSwComp(reporte).subscribe((respuesta:any) =>{
        if (respuesta['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.verInventario();
              } else {
                this.txtBuscaUsuario.nativeElement.value = "";
                this.usuarioElegido = [];
                this.page = 1;
                this.verInventario();
              }
            })
          }
        } else {
          this.asignaciones = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }
      })
    }
    
  }

  bajaAsignacion(){
    const FileName = 'BajaAsignacion'+this.date.toLocaleDateString();
    if (this.formBaja.value['motivoBaja'] == '' || this.formBaja.value['motivoBaja'] == undefined) {
      Swal.fire({
        title: "ERROR",
        text: 'Escriba el motivo de la baja',
        type: "warning",
        // timer: 2000
      }).then(()=>{
        let element: HTMLElement = document.getElementsByClassName('btnBaja')[0] as HTMLElement;
        element.click()
      })
    } else {
      let asignacionBaja = {
        "idUsuario": 0,
        "idUbicacion": 0,
        "motivo": this.formBaja.value['motivoBaja'],
        "idResponsable": this.idResponsable,
        "detalleAsignaciones": [
          {
            "idAsignaciones": this.checks ,
          }
        ],
        "tipoDocumento":'A',
        "ruta": 'https://inventario-rfid.s3.amazonaws.com/'+FileName+'.pdf'
      }
      // console.log(asignacionBaja)
        this.inventarioService.bajaAsignacionSw(asignacionBaja).subscribe(data => {
          if (data['result'] === 0) {
            if (!Swal.isVisible()) {
              Swal.fire({
                title: "ERROR",
                text: data['message'],
                type: "warning"
              }).then(() => {
                if (this.page != 1) {
                  this.page = 1;
                  this.verInventario();
                } else {
                  this.txtBuscaUsuario.nativeElement.value = "";
                  this.usuarioElegido = [];
                  this.page = 1;
                  this.verInventario();
                }
              })
            }
          } else {
            if (!Swal.isVisible()) {
              Swal.fire({
                title: "Guardado",
                text: "Se ha registrado exitosamente",
                type: "success",
              }).then(() => {
                this.verInventario();
              })
            }
          }
        }, error => {
        })   
    }
    
  } 

  loadPage(page: number) {
    this.page = page;
    this.verInventario();
  }
}
