<div style="text-align: center;">
  <span>
    <h3>Grupo Permiso</h3>
  </span>
</div>
<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-6">
    <div class="input-group mb-3">
      <input type="text" class="form-control" placeholder="Buscar Grupo de Permisos" #txtBuscaGrupoPermiso
        aria-label="Buscar Tipo Sw" aria-describedby="basic-addon2" (keyup.enter)="buscaGrupoInput()"
        (change)="cambioBuscaGrupoInput()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="buscaGrupoInput()"><i
            class="feather icon-search"></i></button>
      </div>
    </div>
  </div>
  <div class="col-md-2">
    <div class="div-btn-nuevo-hardware">
      <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNuevoGrupo">Agregar</button>
    </div>
  </div>
  <div class="col-md-2"></div>
</div>


<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-8">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Alias</th>
          <th scope="col">Descripcion</th>
          <th scope="col">Permisos</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let grupo of grupoPermisos | paginate: { itemsPerPage: 10, currentPage: page } ">
          <td>
            {{grupo.alias}}
          </td>
          <td>
            {{grupo.descripcion}}
          </td>
          <td style="text-align: center;">
            <button class="btn btn-primary" (click)="verPermisos(grupo.idGrupoPermiso)" data-bs-toggle="modal"
              data-bs-target="#modalPermisos">Ver <i class="feather icon-eye"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" S
      nextLabel="Siguiente"></pagination-controls>
  </div>
  <div class="col-md-2"></div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalPermisos" tabindex="-1" aria-labelledby="modalPermisos" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Permisos del grupo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="table-container-scroll">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="Padding-Table" scope="col">Permiso</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of permisosLista">
              <td class="Padding-Table">
                {{p.nombrePermiso}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalNuevoGrupo" tabindex="-1" aria-labelledby="modalNuevoGrupo" aria-hidden="true"
  style="z-index: 1072;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar nuevo grupo de permisos</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formGrupo" (submit)="guardaGrupo()">

          <div class="form-group">
            <label class="control-label is-required" for="alias">Nombre (Alias)</label>
            <input type="text" class="form-control" id="alias" (keypress)="noSpecialChar($event)" maxlength="50"
              placeholder="Nombre (Alias)" formControlName="alias">
          </div>

          <div class="form-group">
            <label class="control-label is-required" for="descCar">Descripción</label>
            <textarea class="form-control" id="descCar" placeholder="Descripción" (keypress)="noSpecialChar($event)"
              maxlength="200" formControlName="descripcion"></textarea>
          </div>

          <div class="form-group" style="z-index: 1073;">
            <label class="control-label is-required" for="idPermiso">Permiso</label>
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Elegir Permisos</mat-label>
              <mat-select multiple formControlName="idPermiso" id="idPermiso" required
                class="cdk-overlay-connected-position-bounding-box" style="z-index: 1073;">
                <mat-option *ngFor="let per of permisos" [value]="per.idPermiso">{{per.nombrePermiso}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button type="submit" [disabled]="formGrupo.invalid" data-bs-dismiss="modal" class="btn btn-primary"
          (click)="guardaGrupo()">Guardar</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
      </div>
    </div>
  </div>
</div>