import { Injectable } from '@angular/core';
import { Roles } from '../../../../model/variables';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  permisos?: Array<string>;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
    children: [
      // {
      //   id: 'inicio',
      //   title: 'Inicio',
      //   type: 'item',
      //   url: '/inicio',
      //   classes: 'nav-item',
      //   icon: 'feather icon-sidebar',
      //   permisos: [ Roles.SUPERADMIN ],
      // },
      {
        id: 'hardware',
        title: 'Hardware',
        type: 'item',
        url: '/hardware',
        classes: 'nav-item',
        icon: 'feather icon-cpu',
        permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
      }, 
      {
        id: 'software',
        title: 'Software',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-server',
        permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
          children: [
            {
              id: 'vencimientoSoftware',
              title: 'Vencimientos',
              type: 'item',
              url: '/controlVencimientos',
              permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            },
            {
              id: 'adminSoftware',
              title: 'Administración Software',
              type: 'item',
              url: '/software',
              permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            },
          ]
      },
      {
        id: 'asignaciones',
        title: 'Asignaciones',
        type: 'collapse',
        icon: 'feather icon-menu',
        permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
        children: [
          {
            id: 'asignacionHardware',
            title: 'Hardware',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'asignacionHardware',
                title: 'Asignacion',
                type: 'item',
                url: '/asignacionHardware',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'desasignarHardware',
                title: 'Desasignar Hardware',
                type: 'item',
                url: '/desasignarHw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
            ]
          },
          {
            id: 'asignacionesSoftware',
            title: 'Software',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'asignacionSoftware',
                title: 'Asignacion',
                type: 'item',
                url: '/asignacionSoftware',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'asignacionHardware',
                title: 'Reasignacion',
                type: 'item',
                url: '/reAsignacionSoftware',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'desasignarSoftware',
                title: 'Desasignar Software',
                type: 'item',
                url: '/desasignarSw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
            ]
          },
        ]
      },
      {
        id: 'reportes',
        title: 'Reportes',
        url: '/reportes',
        classes: 'nav-item',
        icon: 'feather icon-pie-chart',
        type: 'collapse',
        permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
        children: [
          {
            id: 'AsignacionUbicacion',
            title: 'Asignacion y Ubicación',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'AsignacionUbicacionSw',
                title: 'Software',
                type: 'item',
                url: '/repAsignacionUbicacionSw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'AsignacionUbicacionHw',
                title: 'Hardware',
                type: 'item',
                url: '/repAsignacionUbicacionHw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              }
            ]
          },
          {
            id: 'DisponibleAsignar',
            title: 'Disponible Para Asignar',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'disponibleAsignarSw',
                title: 'Software',
                type: 'item',
                url: '/disponibleAsignarSw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'disponibleAsignarHw',
                title: 'Hardware',
                type: 'item',
                url: '/disponibleAsignarHw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              }
            ]
          },
          {
            id: 'HistorialAsignación',
            title: 'Historial de Asignación',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'historialAsignacionSw',
                title: 'Software',
                type: 'item',
                url: '/historialAsignacionSw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'historialAsignacionHw',
                title: 'Hardware',
                type: 'item',
                url: '/historialAsignacionHw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              }
            ]
          },
          {
            id: 'reporteInventario',
            title: 'Inventario',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'reporteInventarioSw',
                title: 'Software',
                type: 'item',
                url: '/reporteInventarioSw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'reporteInventarioHw',
                title: 'Hardware',
                type: 'item',
                url: '/reporteInventarioHw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'reporteCantInventarioHw',
                title: 'Cantidad del Inventario',
                type: 'item',
                url: '/reporteCantInventarioHw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
              }
            ],
          }, 
          {
            id: 'reporteBajaHw',
            title: 'Baja de Hardware',
            type: 'item',
            url: '/reporteBajaHw',
            classes: 'nav-item',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
          }, 
          {
            id: 'reporteCaracteristicas',
            title: 'Caracteristicas Hardware',
            type: 'item',
            url: '/reporteCaracteristicas',
            classes: 'nav-item',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
          }, 
          {
            id: 'reporteVencimientoLicencias',
            title: 'Vencimiento de Licencias',
            type: 'item',
            url: '/reporteVencimientoLicencias',
            classes: 'nav-item',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
          }, 
          {
            id: 'ValorInventario',
            title: 'Valor de Inventario',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'reporteValorInventarioSw',
                title: 'Software',
                type: 'item',
                url: '/reporteValorInventarioSw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'reporteValorInventarioHw',
                title: 'Hardware',
                type: 'item',
                url: '/reporteValorInventarioHw',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              }
            ]
          },
        ]
      },
      {
        id: 'admin',
        title: 'Administración',
        type: 'collapse',
        icon: 'feather icon-user',
        permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
        children: [
          {
            id: 'catalogosHardware',
            title: 'Catálogos de Hardware',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'catalogoHardware',
                title: 'Catálogo de Hardware',
                type: 'item',
                url: '/catalogoHardware',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'subCatalogoHardware',
                title: 'SubCatálogos Hardware',
                type: 'collapse',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
                children: [
                  {
                    id: 'catalogoCaracteristicas',
                    title: 'Catálogo de Características',
                    type: 'item',
                    url: '/catalogoCaracteristicas',
                    permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
                  },
                  {
                    id: 'CatalogoMarca',
                    title: 'Catálogo de Marcas',
                    type: 'item',
                    url: '/catalogoMarca',
                    permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
                  },
                  {
                    id: 'CatalogoTipoHw',
                    title: 'Catálogo de Tipo de Hardware',
                    type: 'item',
                    url: '/catalogoTipoHw',
                    permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
                  },
                ]
              },
            ],
          },
          {
            id: 'catalogosSoftware',
            title: 'Catálogos de Software',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              {
                id: 'catalogoSoftware',
                title: 'Catálogo de Software',
                type: 'item',
                url: '/catalogoSoftware',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'subCatalogoSoftware',
                title: 'SubCatálogos Software',
                type: 'collapse',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
                children: [
                  {
                    id: 'CatalogoTipoSw',
                    title: 'Catálogo de Tipo de Software',
                    type: 'item',
                    url: '/catalogoTipoSw',
                    permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
                  },
                  {
                    id: 'CatalogoMarca',
                    title: 'Catálogo de Marcas',
                    type: 'item',
                    url: '/catalogoMarca',
                    permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
                  },
                ]
              },
            ]
          },
          {
            id: 'facturas',
            title: 'Facturas',
            type: 'item',
            url: '/facturasSoftware',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
          },
          {
            id: 'catalogosGenerales',
            title: 'Catálogos Generales',
            type: 'collapse',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            children: [
              // {
              //   id: 'catalogoAntenas',
              //   title: 'Catálogo de Antenas',
              //   type: 'item',
              //   url: '/catalogoAntenas',
              //   permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              // },
              {
                id: 'catalogoUbicaciones',
                title: 'Catálogo de Ubicaciones',
                type: 'item',
                url: '/catalogoUbicaciones',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'catalogoSituaciones',
                title: 'Catálogo de Situaciones',
                type: 'item',
                url: '/catalogoSituaciones',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
              {
                id: 'catalogoDestinoNotificaciones',
                title: 'Catálogo Destinatarios de Notificaciones',
                type: 'item',
                url: '/catalogoDestinoNotificaciones',
                permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
              },
            ]
          },
          { 
            id: 'ccatalogosDocumentos',
            title: 'Catálogo Documentos',
            type: 'item',
            url: '/catalogoDocumentos',
            permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
          },
          // {
          //   id: 'adminUsuarios',
          //   title: 'Control de Usuarios',
          //   type: 'collapse',
          //   permisos: [ Roles.SUPERADMIN ],
          //   children: [
          //     {
          //       id: 'catalogoPermisos',
          //       title: 'Catálogo de permisos',
          //       type: 'item',
          //       url: '/catalogoPermisos',
          //       permisos: [ Roles.SUPERADMIN ],
          //     },
          //     {
          //       id: 'grupoPermisos',
          //       title: 'Grupo-Permisos',
          //       type: 'item',
          //       url: '/grupoPermisos',
          //       permisos: [ Roles.SUPERADMIN ],
          //     },
          //     {
          //       id: 'grupoUsuarios',
          //       title: 'Grupo-Usuarios',
          //       type: 'item',
          //       url: '/controlUsuarios',
          //       permisos: [ Roles.SUPERADMIN ],
          //     },
          //   ]
          // },
        ]
      },
      {
        id: 'antenas',
        title: 'Antenas',
        type: 'collapse',
        classes: 'nav-item',
        icon: 'feather icon-server',
        permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
          children: [
            {
              id: 'catologoAntenas',
              title: 'Catálogo de Antenas',
              type: 'item',
              url: '/catalogoAntenas',
              permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            },
            {
              id: 'historialAntenas',
              title: 'Historial',
              type: 'item',
              url: '/historialAntenas',
              permisos: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE],
            },
          ]
      },
    ]
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
