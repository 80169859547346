<!-- Buscador Texto y botones -->
<div class="row mb-2">
  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-0 padd-perso-filter"></div>
  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 padd-perso-filter">
    <input type="text" class="form-control" #txtBuscaInvHard placeholder="Buscar Hardware" aria-label="Buscar Hardware"
      aria-describedby="basic-addon2" style="width: 101%">
    <button *ngIf="!isBusquedaLimpia()" type="button" class="clear-btn" (click)="limpiarBusqueda()">✖</button>
  </div>
  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 padd-perso-filter">
    <button class="btn btn-outline-secondary" (click)="buscaInvHardInput()" type="button"><i
        class="feather icon-search"></i></button>
    <button *ngIf="mostrarFiltros" class="btn btn-outline-secondary" (click)="toggleFiltros()" type="button">
      <img src="../../../../assets/fonts/fa-filter-personal_clouse.svg" alt="icono" width="82%">
    </button>
    <button *ngIf="!mostrarFiltros" class="btn btn-outline-secondary" (click)="toggleFiltros()" type="button">
      <img src="../../../../assets/fonts/fa-filter-personal_despliegue.svg" alt="icono" width="62%">
    </button>
  </div>
</div>
<!-- Fin Buscador Texto y botones -->

<!-- Filtros Adicionales -->
<div class="row" [@toggleAnimation]="mostrarFiltros ? 'mostrar' : 'ocultar'">
  <ng-container class="collapse-container">
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-0 padd-perso-filter"></div>
    <!-- Select Tipo, Marca, Estado, Usuario -->
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2 padd-perso-filter">
      <div class="input-group mb-2">
        <ng-select class="form-control" name="opcBuscarTipoHW" id="opcBuscarTipoHW" #opcBuscarTipoHW *ngIf="flagTipoHW"
          [options]="opcTipoHW" placeholder="Seleccione tipo de Hardware" [(ngModel)]="selectBuscarTipoHW">
        </ng-select>
        <ng-select class="form-control ml-2" name="opcBuscarTipoMarcas" id="opcBuscarTipoMarcas" #opcBuscarTipoMarcas
          *ngIf="flagTipoMarca" [options]="opcTipoMarcas" placeholder="Seleccione tipo de Marca"
          [(ngModel)]="selectBuscarTipoMarcas">
        </ng-select>
      </div>
      <div class="input-group mb-2">
        <ng-select class="form-control" name="opcBuscarAsignaNoAsigna" id="opcBuscarAsignaNoAsigna"
          #opcBuscarAsignaNoAsigna [options]="opcAsignaNoAsigna" placeholder="Seleccione Estado De Asignación"
          [(ngModel)]="selectBuscarAsignaNoAsigna">
        </ng-select>
        <ng-select class="form-control ml-2" name="opcBuscarUsuarios" id="opcBuscarUsuarios" #opcBuscarUsuarios
          *ngIf="flagUsuarios" [options]="opcUsuarios" placeholder="Seleccione usuario"
          [(ngModel)]="selectBuscarUsuarios">
        </ng-select>
      </div>
    </div>
    <!-- Fin Select Tipo, Marca, Estado, Usuario -->
    <!-- Multiselect -->
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2 padd-perso-filter">
      <ng-select class="form-control" name="opcBuscarUbicación" id="opcBuscarUbicación" #opcBuscarUbicación
        *ngIf="flagUbicacion" [options]="opcUbicacion" placeholder="Seleccione ubicación" [multiple]="true"
        [closeOnSelect]="false" style="height: auto;" [(ngModel)]="selectedUbicacion">
      </ng-select>
    </div>
    <!-- Multiselect -->
  </ng-container>
</div>
<!-- Fin Filtros Adicionales -->

<div style="overflow: auto;" *ngIf="!cargarLoader">
  <div class="form-group">
    <div class="m-2">
      <button [disabled]="( someComplete() && hayHardwareAsignadoEnSeleccion  && !allComplete) ||
                          (!someComplete() && !hayHardwareAsignadoEnSeleccion && !allComplete) ||
                          (!someComplete() && hayHardwareAsignadoEnSeleccion  &&  allComplete)" class="btn btn-primary"
        data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion">
        Asignar
      </button>
      <button [disabled]="( someComplete() && hayHardwareDesasignadoEnSeleccion  && !allComplete) ||
                          (!someComplete() && !hayHardwareDesasignadoEnSeleccion && !allComplete) ||
                          (!someComplete() && hayHardwareDesasignadoEnSeleccion  &&  allComplete)"
        class="btn btn-primary ml-3" data-bs-toggle="modal" data-bs-target="#modalBaja">
        Desasignar
      </button>

      <button [disabled]="!someComplete() && !allComplete" class="btn btn-primary ml-3 modalNuevasCaracteristicas"
        data-bs-toggle="modal" data-bs-target="#modalNuevasCaracteristicas" (click)="ObtenerIdsInvHw()">
        Asignar Características
      </button>
      <button [disabled]="( someComplete() && hayHardwareAsignadoEnSeleccion  && !allComplete) ||
                          (!someComplete() && !hayHardwareAsignadoEnSeleccion && !allComplete) ||
                          (!someComplete() && hayHardwareAsignadoEnSeleccion  &&  allComplete)" class="btn btn-primary ml-3"
        data-bs-toggle="modal" data-bs-target="#modalBajaHardware" (click)="this.formBajaInvHard.reset()"
      >
        Baja
      </button>
      <button [disabled]="( someComplete() && hayHardwareSinFacturaEnSeleccion  && !allComplete) ||
                          (!someComplete() && !hayHardwareSinFacturaEnSeleccion && !allComplete) ||
                          (!someComplete() && hayHardwareSinFacturaEnSeleccion  &&  allComplete)"
        class="btn btn-primary ml-3" data-bs-toggle="modal"
        data-bs-target="#modalRelacionarFactura" (click)="setListaIdsInventario()">
        Relacionar a factura
      </button>
    </div>
  </div>
  <!-- <app-card cardTitle="Hardware" blockClass="table-border-style"> -->
  <div class="table table-styling table-hover table-secundary">
    <div class="table-container-scroll">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="Padding-Table left-aling-checkbox"
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
              <mat-checkbox [checked]="allComplete" color="primary" [indeterminate]="someComplete()"
                (change)="setAll($event.checked)"></mat-checkbox>
            </th>
            <th scope="col" class="Padding-Table">Tipo</th>
            <th scope="col" class="Padding-Table">Marca</th>
            <th scope="col" class="Padding-Table">Modelo</th>
            <th scope="col" class="Padding-Table">Asignado</th>
            <th scope="col" class="Padding-Table"># inventario</th>
            <th scope="col" class="Padding-Table"># serie</th>
            <th scope="col" class="Padding-Table"># factura</th>
            <th scope="col" class="Padding-Table" *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let inv of checkSelect.arrayInventaHw">
            <td class="Padding-Table right-aling-checkbox"
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
              <mat-checkbox [(ngModel)]="inv.completed" color="primary"
                (ngModelChange)="updateAllComplete()"></mat-checkbox>
            </td>
            <td class="Padding-Table">
              {{inv.tipoHW}}
            </td>
            <td class="Padding-Table">
              {{inv.marca}}
            </td>
            <td class="Padding-Table cursor-point" (mouseenter)="showTooltipCaracteristicas($event, inv)"
              (mouseleave)="hideTooltipCaracteristicas()"
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
              <em>{{inv.modelo}}</em>
            </td>
            <td class="Padding-Table" *appHasAnyRole="[rolesAzure.ING_SOPORTE]">{{inv.modelo}}</td>
            <ng-container *ngIf="inv.asignado == 1">
              <td class="Padding-Table cursor-point" (mouseenter)="showTooltipAsignado($event, inv)"
                (mouseleave)="hideTooltipAsignado()"
                *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, rolesAzure.ING_SOPORTE]">
                <span class="led-green"></span>
                <span class="ml-1"><em>Asignado</em></span>
              </td>
            </ng-container>
            <td class="Padding-Table" *ngIf="inv.asignado == 0">
              <span class="led-off"></span>
              <span class="ml-1">No Asignado</span>
            </td>
            <td class="Padding-Table cursor-point" (mouseenter)="showTooltipNumInv($event, inv)"
              (mouseleave)="hideTooltipNumInv()" *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
              <em>{{inv.numeroInvAlm}}</em>
            </td>
            <td class="Padding-Table" *appHasAnyRole="[rolesAzure.ING_SOPORTE]">{{inv.numeroInvAlm}}</td>
            <td class="Padding-Table">
              {{inv.numeroSerie}}
            </td>
            <td class="Padding-Table">
              {{inv.numeroFactura}}<p *ngIf="inv.numeroFactura == null"><span class="led-red"></span><span
                  class="ml-1">Sin factura</span></p>
            </td>
            <td class="Padding-Table" style="text-align: center;"
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
              <button class="btn btn-outline-primary" (click)="abreEditaHardware(inv.idInventarioHW)"
                data-bs-toggle="modal" data-bs-target="#editaHardware"><i
                  class="feather icon-edit iconos-acciones"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- </app-card> -->
  <div class="d-flex justify-content-start align-items-center p-2">
    <ngb-pagination [collectionSize]="currentPage" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
      (pageChange)="loadPage($event)"
      [disabled]="checkSelect.arrayInventaHw == null || checkSelect.arrayInventaHw.length == 0">
      <ng-template ngbPaginationPrevious>Anterior</ng-template>
      <ng-template ngbPaginationNext>Siguiente</ng-template>
    </ngb-pagination>
    <div class="d-flex align-items-center ml-3">
      <label for="pageInput" class="mr-2">Buscar página específica:</label>
      <input id="pageInput" type="number" class="form-control w-auto" #pageInput (change)="page = pageInput.value"
        (keyup.enter)="page = pageInput.value" onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
        placeholder="" aria-label="Buscar página específica" />
    </div>
  </div>
  <!-- Tooltip personalizado en forma de tarjeta -->
  <div class="custom-tooltip" *ngIf="caracteristicasItem"
    [ngStyle]="{ top: tooltipPosition.top, left: tooltipPosition.left }">
    <div class="tooltip-header">Caracteristicas</div>
    <div class="tooltip-body">
      <div *ngIf="caracteristicasItem.caracteristicas != null">
        <p [innerHTML]="caracteristicasItem.caracteristicas"></p>
      </div>
      <div *ngIf="caracteristicasItem.caracteristicas == null">
        <p>Sin Información</p>
      </div>
    </div>
  </div>
  <div class="custom-tooltip" *ngIf="asignadoItem" [ngStyle]="{ top: tooltipPosition.top, left: tooltipPosition.left }">
    <div class="tooltip-header">Asignado</div>
    <div class="tooltip-body">
      <p><strong>Nombre:</strong> {{ asignadoItem.nombreEmpleado }}</p>
      <p><strong>Ubicación:</strong> {{ asignadoItem.nombreUbicacion }}</p>
      <p><strong>Fecha:</strong> {{ asignadoItem.fechaHoraAsignacion }}</p>
    </div>
  </div>
  <div class="custom-tooltip" *ngIf="numInvItem" [ngStyle]="{ top: tooltipPosition.top, left: tooltipPosition.left }">
    <div class="tooltip-header">Información Tag</div>
    <div class="tooltip-body">
      <div *ngIf="numInvItem.infoTag != null">
        <p [innerHTML]="numInvItem.infoTag"></p>
      </div>
      <div *ngIf="numInvItem.infoTag == null">
        <p>Sin Información</p>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="editaHardware" tabindex="-1" aria-labelledby="editaHardware" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Hardware</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="resetImagenes()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="carousel-container">
          <button (click)="prev()" class="carousel-btn prev-btn"
            *ngIf="!inventarioSinImagen && images.length > 1">&#10094;</button>
          <div class="carousel">
            <div class="carousel-item" *ngFor="let image of images; let i = index" [class.active]="i === currentIndex">
              <a [href]="image" target="_blank" *ngIf="!inventarioSinImagen">
                <img [src]="image" style="width: 410px; height: 250px; object-fit: cover;" />
              </a>
              <img [src]="image" style="width: 410px; height: 250px; object-fit: cover;" *ngIf="inventarioSinImagen" />
              <button class="delete-icon" (click)="removeImage(i, $event)" *ngIf="!inventarioSinImagen">
                &#10006;
              </button>
            </div>
          </div>
          <button (click)="next()" class="carousel-btn next-btn"
            *ngIf="!inventarioSinImagen && images.length > 1">&#10095;</button>
        </div>
        <br>
        <form [formGroup]="formEditaHardware" (submit)="editaInvHard()">
          <input hidden="true" [(ngModel)]="invHwEditar.idInventarioHw" formControlName="idInventarioHw">
          <div class="form-group">
            <label class="control-label" for="numeroSerie">Número de Serie</label>
            <input type="text" class="form-control" id="numeroSerie" placeholder="Número de Serie"
              formControlName="numeroSerie" [(ngModel)]="invHwEditar.numeroSerie">
          </div>
          <div class="form-group">
            <label class="control-label" for="noInvAlm">Número de Inventario</label>
            <input type="text" class="form-control" id="noInvAlm" placeholder="Número de Inventario"
              formControlName="numeroInvAlm" [(ngModel)]="invHwEditar.numeroInvAlm">
          </div>
          <div class="form-group">
            <label for="state_id" class="control-label">Situación</label>
            <select class="form-control" name="tipoHw" id="situacion" formControlName="idSituacion"
              [(ngModel)]="invHwEditar.idSituacion">
              <option value="" disabled selected>Elige Situacion</option>
              <option [value]=sit.idSituacion *ngFor="let sit of situaciones"
                [selected]="sit.idSituacion == invHwEditar.idSituacion">{{sit.nombre}}</option>
            </select>
          </div>
          <div style="text-align: center;">
            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Guardar información</button>
          </div>
        </form>
        <br>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Agregar fotografías</h4>
            <div class="row">
              <div class="col-md-8">
                <input type="file" (change)="onFilesSelected($event)" multiple accept="image/jpeg" />
                <ul>
                  <li *ngFor="let file of selectedFiles">{{ file.name }}</li>
                </ul>
              </div>
              <div class="col-md-4">
                <button class="btn btn-primary" [disabled]="!selectedFiles.length" (click)="subirArchivos()">
                  <i class="fas fa-upload d-inline-block mr-2"></i> Subir
                </button>
              </div>
            </div>
            <div *ngIf="showError" class="alert alert-danger" role="alert" [@fadeInOut]>
              Algunos archivos que no son imágenes o pesan más de 1.5 MB se han descartado.
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalBajaHardware" tabindex="-1" role="dialog" aria-labelledby="modalBajaHardware"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Baja de hardware</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div id="divFactura">
            <form [formGroup]="formBajaInvHard">
              <div class="form-group">
                <label class="control-label is-required" for="motivo">Motivo</label>
                <textarea type="text" min="1" class="form-control" id="motivoBaja" placeholder="Motivo de la baja"
                  formControlName="motivoBaja" maxlength="200"></textarea>
              </div>
              <div style="text-align: center;">
                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal"
                  [disabled]="formBajaInvHard.invalid" (click)="bajaInvHardware()">Dar de baja</button>
              </div>
              <div class="modal-footer">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Asignación-->
<div class="modal fade" id="modalNuevaAsignacion" tabindex="-1" role="dialog" aria-labelledby="modalNuevoCatSoftware"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Asignación de inventario de hardware</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formAsignacionHw" (submit)="guardaAsignacionHw()">
          <div class="row">
            <div class="col-6">
              <label for="divIdUsuario" class="control-label is-required">Usuario</label>
              <div class="input-group mb-3" id="divIdUsuario">
                <input type="text" class="form-control" id="idUsuario" placeholder="Usuario" formControlName="usuario"
                  [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario"
                    data-bs-toggle="modal"><i class="feather icon-search"></i></button>
                </div>
                <input type="hidden" formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="idGrupoPermiso" class="control-label is-required">Ubicación</label>
                <select class="form-control" name="Ubicacion" id="Ubicacion" formControlName="idUbicacion"
                  (change)="validaBtnAsignarHw()">
                  <option value="" disabled selected>Elige la ubicación</option>
                  <option [value]=gp.idUbicacion *ngFor="let gp of ubicacion">{{gp.nombreUbicacion}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3"></div>
            <div class="col-7">
              <div class="form-group">
                <label class="control-label is-required" for="total">Motivo</label>
                <textarea type="number" min="1" class="form-control" (keypress)="noSpecialChar($event)" id="motivo"
                  (change)="validaBtnAsignarHw()" placeholder="Motivo" formControlName="motivo"></textarea>
              </div>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4" style="text-align: center;">
              <button class="btn btn-primary" [disabled]="btnAsignarHw" data-bs-dismiss="modal"
                style="margin-left: 5px;">Asignar</button>
            </div>
            <div class="col-4"></div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<!-- Modal Usuario-->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal"
          data-bs-target="#modalNuevaAsignacion" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario"
                aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="buscaUsu" style="overflow: auto;">
        <table class="table table-bordered" *ngIf="usuarios.length > 0">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Número</th>
              <th scope="col">Correo</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of usuarios">
              <td>
                {{per.nombreEmpleado}}
              </td>
              <td>
                {{per.numeroEmpleado}}
              </td>
              <td>
                {{per.correo}}
              </td>
              <td style="text-align: center;">
                <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion"
                  (click)="eligeUsuario(per.numeroEmpleado)">Elegir <i
                    class="feather icon-edit iconos-acciones"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered" *ngIf="usuarios.length == 0">
          <thead>
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center" colspan="2">
                No hay resultados
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal Asignación de Caracteristicas multiple -->
<div class="modal fade" id="modalNuevasCaracteristicas" tabindex="-1" role="dialog" aria-labelledby="modalNuevasCaract"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modalNuevaCaracteristica">Asignación de Características</h4>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>
          <small style="color: red;">
            En un inicio se muestra en blanco, pero puedes clonar características y personalizarlas
          </small>
        </h5>
        <div class="row">
          <div class="col-3">
            <span class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalClonarCars" (click)="LimpiaraClonacion()">
              Clonar Características
            </span>
          </div>
          <div class="col-3">
            <span class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAgregaCars" (click)="LimpiaraClonacion()">
              Agregar
            </span>
          </div>
          <div class="col-6"></div>
        </div>
        <br>
        <div class="table table-styling table-hover table-secundary" *ngIf="carsEditar.length > 0">
          <div class="table-container-scroll">
            <table class="table m-0">
              <thead>
                <tr>
                  <th scope="col" class="Padding-Table">Característica</th>
                  <th scope="col" class="Padding-Table">Descripción</th>
                  <th scope="col" class="Padding-Table">Eliminar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of carsEditar; let i = index">
                  <td class="Padding-Table"> {{ item.caracteristica }} </td>
                  <td class="Padding-Table"> {{ item.descripcion }} </td>
                  <td class="Padding-Table">
                    <button class="btn btn-danger" (click)="eliminaCaracteristica(i)" style="margin-left: 5px;">
                      <i class="feather icon-trash-2 iconos-acciones"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="text-align: center;">
        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal"
          (click)="guardaAsignacionCategoria()">Guardar</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Agregar Categorias para la asignación multiple -->
<div class="modal fade" id="modalAgregaCars" tabindex="-1" role="dialog" aria-labelledby="modalAgregaCars"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Elegir Caracteristicas</h5>
        <button type="button" class="close" data-bs-toggle="modal" data-bs-target="#modalNuevasCaracteristicas"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaCars placeholder="Buscar Característica"
                aria-label="Buscar Característica" aria-describedby="basic-addon2"
                (keyup.enter)="buscaCaracteristicas()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaCaracteristicas()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="col-md-12">
          <div class="table table-styling table-hover table-secundary" *ngIf="carsTipoHw.length > 0">
            <div class="table-container-scroll">
              <table class="table m-0">
                <thead>
                  <tr>
                    <!-- <th scope="col" class="Padding-Table">ID</th> -->
                    <th scope="col" class="Padding-Table">Característica</th>
                    <th scope="col" class="Padding-Table">Descripción</th>
                    <th scope="col" class="Padding-Table">Elegir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let h of carsTipoHw; let i=index">
                    <!-- <td class="Padding-Table"> {{h.idCaracteristica}} </td> -->
                    <td class="Padding-Table"> {{h.caracteristica}} </td>
                    <td class="Padding-Table"> {{h.descripcion}} </td>
                    <td class="Padding-Table">
                      <button class="btn btn-success" (click)="eligeCar(i)" data-bs-toggle="modal"
                        data-bs-target="#modalNuevasCaracteristicas" style="margin-left: 5px;">
                        Elegir
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Clonar Caracteristicas de Inventario HW para la asignación multiple -->
<div class="modal fade" id="modalClonarCars" tabindex="-1" role="dialog" aria-labelledby="modalClonarCars"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Elegir Caracteristicas de algun inventario</h5>
        <button type="button" class="close" data-bs-toggle="modal" data-bs-target="#modalNuevasCaracteristicas"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <h5>
            <small style="color: red;">
              El valor de busqueda debe por lo menos tener 3 caracteres
            </small>
          </h5>
        </div>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtFindCarsInve placeholder="Buscar Inventario"
                aria-label="Buscar Inventario" aria-describedby="basic-addon2" (keyup.enter)="buscarCaractInventario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscarCaractInventario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2">
          </div>
        </div>
        <div>
          <div class="table table-styling table-hover table-secundary" *ngIf="findCarInvent.length > 0">
            <div class="table-container-scroll">
              <table class="table m-0">
                <thead>
                  <tr>
                    <th scope="col" class="Padding-Table">Información</th>
                    <th scope="col" class="Padding-Table">Caracteristicas</th>
                    <th scope="col" class="Padding-Table"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of findCarInvent">
                    <td class="Padding-Table">
                      <b>Tipo HW:</b> {{ item.tipoHW }}
                      <br><b>Marca:</b> {{ item.marca }}
                      <br><b>Modelo:</b> {{ item.modelo }}
                      <br><b># Inventario:</b> {{ item.numeroInvAlm }}
                      <br><b># Serie:</b> {{ item.numeroSerie }}
                    </td>
                    <td class="Padding-Table">
                      <table class="table table-bordered" style="border: 1px none black;">
                        <tbody>
                          <tr *ngFor="let car of item.caracteristicaDTO">
                            <td class="Padding-Table" style="text-align: center; border: 1px none black;">
                              {{ car.caracteristica }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td class="Padding-Table">
                      <button class="btn btn-success" (click)="EligeCaracteristicas(item.caracteristicaDTO)" data-bs-toggle="modal"
                        data-bs-target="#modalNuevasCaracteristicas" style="margin-left: 5px;">
                        Elegir
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalBaja" tabindex="-1" role="dialog" aria-labelledby="modalBajaFactura"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Desasignacion de hardware</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div id="divFactura">
            <form [formGroup]="formBaja">
              <div class="form-group">
                <label class="control-label is-required" for="motivo">Motivo</label>
                <textarea type="text" min="1" class="form-control" id="motivoBaja" placeholder="Motivo de la baja"
                  formControlName="motivoBaja" maxlength="200"></textarea>
              </div>
              <div style="text-align: center;">
                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" (click)="bajaAsignacion()">Quitar
                  Asignación</button>
              </div>
              <div class="modal-footer">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal relacionar factura -->
<div class="modal fade" id="modalRelacionarFactura" tabindex="-1" role="dialog" aria-labelledby="modalRelacionarFactura"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modalNuevaCaracteristica">Relacionar factura</h4>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-9">
              <form [formGroup]="formBusqueda">
                  <div class="form-group">
                      <input type="text" class="form-control" (change)="buscaFacSoftInput()" id="buscarFactura"
                      placeholder="Buscar Factura" aria-label="Buscar Factura" aria-describedby="basic-addon2" formControlName="buscarFactura"
                      (keyup.enter)="buscaFacSoftInput()">
                  </div>
              </form>
          </div>
          <div class="col-md-1">
              <button type="submit" class="btn btn-primary" (click)="buscaFacSoftInput()">Buscar</button>
          </div>
      </div>
          <div class="table-responsive">
              <table datatable class="table table-striped table-bordered nowrap table-hover">
                  <thead>
                      <tr>
                          <th class="Padding-Table" scope="col"># Factura</th>
                          <th class="Padding-Table" scope="col">Proveedor</th>
                          <th class="Padding-Table" scope="col">Subtotal</th>
                          <th class="Padding-Table" scope="col">Total</th>
                          <th class="Padding-Table" scope="col"
                              *appHasAnyRole="[ rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, ]">Acciones</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let fac of facturas">
                          <td class="Padding-Table">
                              {{fac.numeroFactura}}
                          </td>
                          <td class="Padding-Table">
                              {{fac.proveedor}}
                          </td>
                          <td class="Padding-Table" style="text-align: right;">
                              {{fac.subtotal | currency:"MXN":"symbol"}}
                          </td>
                          <td class="Padding-Table" style="text-align: right;">
                              {{fac.total | currency:"MXN":"symbol"}}
                          </td>
                          <td class="Padding-Table" style="text-align: center;"
                              *appHasAnyRole="[ rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, ]">
                              <button class="btn btn-primary" (click)="relacionarFacturas(fac.idFactura)" data-bs-dismiss="modal">
                                  Relacionar <i class="feather icon-upload iconos-acciones"></i>
                              </button>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <div class="modal-footer" style="text-align: center;">
      </div>
    </div>
  </div>
</div>