<div style="text-align: center;">
    <span>
        <h3>Reporte valor de inventario de software</h3>
    </span>
</div>
<form [formGroup]="formFechas">
    <div class="row">
        <div class="form-group" style="margin-right: 25px;">
            <div class="form-group">
                <label for="state_id" class="control-label">Tipo Software</label>
                <select class="form-control" id="tipoHw" formControlName="tiposw">
                    <option value="" disabled selected>Elige el tipo de software</option>
                    <option value="0">Ninguno</option>
                    <option [value]=cat.idTipoSw *ngFor="let cat of tiposw">{{cat.nombre}}</option>
                </select>
            </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
            <div class="form-group">
                <label for="ubicacion" class="control-label">Ubicación</label>
                <select class="form-control" id="ubicacion" formControlName="ubicacion">
                    <option value="" disabled selected>Elige la ubicación</option>
                    <option value="0">Ninguno</option>
                    <option [value]=cat.idUbicacion *ngFor="let cat of ubicacion">{{cat.nombreUbicacion}}</option>
                </select>
            </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
            <div class="form-group">
                <label class="control-label" for="fechaInicio">Fecha Inicio</label>
                <input type="date" class="form-control" id="fechaInicio" placeholder="Fecha Inicio"
                    formControlName="fini">
            </div>
        </div>
        <div class="form-group" style="margin-right: 25px;">
            <div class="form-group">
                <label class="control-label" for="vencimiento">Fecha Fin</label>
                <input type="date" class="form-control" id="vencimiento" placeholder="Vencimiento"
                    formControlName="ffin">
            </div>
        </div>
    </div>
</form>
<div class="row">
    <div class="col-4"></div>
    <div class="col-4" style="text-align: center; margin-bottom: 20px;" *appHasAnyRole="[
                rolesAzure.SUPERADMIN, 
                rolesAzure.SUPERVISOR, 
                rolesAzure.ING_SOPORTE
                ]">
        <button class="btn btn-lg btn-primary" (click)="mostrarReporte()">Mostrar Reporte</button>
    </div>
    <div class="col-4"></div>
</div>
<div class="row">
    <div class="col-md-12" style="overflow: auto;" *ngIf="invSoftware.length > 0">
        <div class="row">
            <div class="col-8"></div>
            <div class="col-2" style="text-align:right ;" *appHasAnyRole="[
                rolesAzure.SUPERADMIN, 
                rolesAzure.SUPERVISOR, 
                rolesAzure.ING_SOPORTE
                ]">
                <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
            </div>
            <div class="col-2" style="text-align:right ;" *appHasAnyRole="[
                rolesAzure.SUPERADMIN, 
                rolesAzure.SUPERVISOR, 
                rolesAzure.ING_SOPORTE
                ]">
                <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
            </div>
        </div>
        <div style="overflow: auto;" *ngIf="!cargarLoader">
            <div class="table-container-scroll">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="Padding-Table" scope="col">Marca</th>
                            <th class="Padding-Table" scope="col">Tipo</th>
                            <th class="Padding-Table" scope="col">Software</th>
                            <th class="Padding-Table" scope="col">Nombre</th>
                            <th class="Padding-Table" scope="col">Costo Unitario</th>
                            <th class="Padding-Table" scope="col">Fecha Compra</th>
                            <th class="Padding-Table" scope="col">Vencimiento</th>
                            <th class="Padding-Table" scope="col">Stock</th>
                            <th class="Padding-Table" scope="col">Ubicación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let per of invSoftware">
                            <td class="Padding-Table">
                                {{per.marca}}
                            </td>
                            <td class="Padding-Table">
                                {{per.tipoSW}}
                            </td>
                            <td class="Padding-Table">
                                {{per.software}}
                            </td>
                            <td class="Padding-Table">
                                {{per.nombreSw}}
                            </td>
                            <td class="Padding-Table">
                                {{per.costoUnitario | currency:"MXN":"symbol"}}
                            </td>
                            <td class="Padding-Table">
                                {{per.fecha | date:'longDate'}}
                            </td>
                            <td class="Padding-Table">
                                {{per.vencimiento | date:'longDate'}}
                            </td>
                            <td class="Padding-Table">
                                {{per.stock}} de {{per.cantidad}}
                            </td>
                            <td class="Padding-Table">
                                {{per.nombreUbicacion}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-start align-items-center p-2">
                <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                    (pageChange)="loadPage($event)" [disabled]="invSoftware == null || invSoftware.length == 0">
                    <ng-template ngbPaginationPrevious>Anterior</ng-template>
                    <ng-template ngbPaginationNext>Siguiente</ng-template>
                </ngb-pagination>
                <div class="d-flex align-items-center ml-3">
                    <label for="pageInput" class="mr-2">Buscar página específica:</label>
                    <input id="pageInput" type="number" class="form-control w-auto" #pageInput
                        (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
                        onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
                        aria-label="Buscar página específica" />
                </div>
            </div>
        </div>
    </div>
</div>